<main>
  <side-navigation>
    <div id="contenido" class="content">
      <router-outlet></router-outlet>
    </div>
  </side-navigation>
  <!-- <app-navbar></app-navbar> -->
</main>
<swal
  #sesionSwal
  title="Sesión expirada."
  icon="warning"
  confirmButtonText="Aceptar"
  confirmButtonColor="#53BAAB"
></swal>
