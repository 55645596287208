import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/core/base-http.service';
import {
  ClientCredentials,
  IClientesExpanded,
  IClientesResponse,
  ideable,
} from '../types/clientes.type';
import { ListPageSize } from 'src/app/material/types/SortEmitter.type';
import { IComisionesClientePost } from '../types/comisiones-cliente.type';
import { ComisionesClientePost } from '../models/ComisionesClientePost.model';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ClientesService extends BaseHttpService {
  constructor(private http: HttpClient, private _currencyPipe: CurrencyPipe) {
    super();
  }

  getClientes(
    pageNumber: number = 0,
    pageSize: ListPageSize = 10,
    enable_all: boolean = false
  ) {
    const queryParams = { pageNumber, pageSize, enabled: true };
    if (enable_all) {
      delete queryParams.enabled;
    }
    return this.http.get<IClientesResponse>(
      this.getUri({ ...queryParams }, 'cliente', 'proceso', 'get_clientes')
    );
  }

  getClientesPromise() {
    return this.http
      .post<IClientesResponse>(
        this.getUri({}, 'cliente', 'proceso', 'get_clientes'),
        null
      )
      .toPromise();
  }

  /**
   * Maneja la peticion realizada hacia el backend, obteniendo los datos
   * directos y devolviendo los datos directos
   * @param cliente
   * @returns
   */
  createCliente(cliente: IClientesExpanded) {
    console.log("datos de carga ");
    console.log(cliente);
    return this.http.post<IClientesExpanded & ideable>(
      this.getUri({}, 'cliente', 'proceso', 'guardar_cliente'),
      cliente
    );
  }

  /**
   * Paso numero dos del proceso, una vez creado el cliente (createCliente)
   * se pasaran a generar las credenciales
   * @param clienteId
   * @returns
   */
  setCredenciales(clienteId: number) {
    return this.http.post<ClientCredentials>(
      this.getUri(
        {},
        'cliente',
        'proceso',
        'generar_credencial',
        `${clienteId}`
      ),
      {}
    );
  }

  /**
   * Obtiene las credenciales del cliente para mostrarlas directamente en el detalle del cliente
   * @param id
   */
  getCredenciales(id: number) {
    return this.http.get;
  }

  updateComision(clienteId: number, comisionObj: IComisionesClientePost) {
    const formatedComision = new ComisionesClientePost({
      ...comisionObj,
      gasto: comisionObj.gasto,
      comision: comisionObj.comision,
    });
    return this.http.post(
      this.getUri(
        {},
        'cliente',
        'proceso',
        'configurar_gasto_comision',
        `${clienteId}`
      ),
      [formatedComision]
    );
  }

  getComisiones(clienteId: number) {
    return this.http.get(
      this.getUri({}, 'cliente', 'proceso', 'gasto_comision', `${clienteId}`)
    );
  }

  editCliente(clienteId, cliente: IClientesExpanded) {
    return this.http.post(
      this.getUri(
        { id: clienteId },
        'cliente',
        'proceso',
        'actualizar_cliente'
      ),
      { ...cliente, id: clienteId }
    );
  }

  disableCliente(clienteId, cliente: IClientesExpanded) {
    return this.editCliente(clienteId, { ...cliente, enabled: false });
  }

  deleteCliente(clienteId: number) {
    // var url = `${this.url}/api/cliente/disable_cliente?id=${clienteId}`;
    return this.http.post(
      this.getUri({ id: clienteId }, 'cliente', 'disable_cliente'),
      { id: clienteId }
    );
  }

  generarToken(cliente) {
    return this.http.post(
      this.getUri({}, 'cliente', 'generar_bearer'),
      cliente
    );
  }

  getClienteID(id: number) {
    return this.http.post<IClientesExpanded & ideable>(
      this.getUri({ id }, 'cliente', 'proceso', 'get_cliente_by_id'),
      {
        id,
      }
    );
  }

  currencyHandler(value: number): string {
    return this._currencyPipe.transform(
      value / 100,
      'CAD',
      '',
      '1.2-2',
      'es-ARS'
    );
  }
}
