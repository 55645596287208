<div class="table-body">
    <table class="table">
        <tbody>
            <tr *ngFor="let item of displayedColumns; let i = index">
                <td>{{item}}</td>
                <td *ngIf="item != 'URL ID'">{{ dataSource[i] }}</td>
                <td *ngIf="item == 'URL ID'">
                    <a href="https://checkout.helipagos.com/checkout/{{dataSource[i]}}" target="blank"> Link de pago </a>
                </td>
            </tr>
        </tbody>
    </table>
</div> 