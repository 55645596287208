<!--<div class="material-progress-bar-div mb-5 animated fadeIn" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>-->

<app-spinner *ngIf="loading"></app-spinner>

<app-tabla-resultados
  (emitResetSearch)="emitNewSearch()"
  [configTable]="configTable"
  [totalElements]="totalElements"
  [pageNumber]="pageNumberLiq"
  [data]="dataSource"
  (eventGetPage)="nuevaSolicitud($event)"
  (emitDescargaPDF)="descargarPDF($event)"
  (emitExportExcelLiq)="exportarExcel($event)"
  (emitTranferirMonto)="gestionHandler($event)"
  (verDetallesOutput)="gestionHandler($event)"
></app-tabla-resultados>
