<div class="saludo">
    <h1>{{ header }}</h1>
</div>
<!--Busqueda-->
<form>
    <div class="search mb-3">
        <img class="search-img" src="../../../../assets/img/search.svg">
        <!-- <input placeholder="Buscar" style="font-family:sans-serif, FontAwesome" type="search"> -->
        <input placeholder="Buscar" style="font-family:sans-serif, FontAwesome" (keyup)="buscarDato(buscarTexto.value)" (search)="buscarDato(buscarTexto.value)" type="search" #buscarTexto>
    </div>
</form>