import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from '../services/clientes.service';
import {
  IClientPersonalData,
  IClienteFormDetails,
  IClientesExpanded,
} from '../types/clientes.type';
import Swal from 'sweetalert2';
import { Storage } from 'src/app/core/decorators/storage.decorator';
import { ClientPersonalData } from '../models/ClientPersonalData.model';
import { ClientRegulaciones } from '../models/Regulaciones.model';
import { ManageClientService } from '../services/manage-client.service';
@Component({
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss'],
})
export class DetalleClienteComponent implements OnInit {
  id: number;
  loading = false;
  errorOnLoad = false;

  /**
   * Al estar trabajando con el componente cliente-form-data-review
   * Las propiedades manejadas deberan de estar alojadas en sessionStorage
   *
   *
   */

  constructor(
    private actRoute: ActivatedRoute,
    private _Clientes: ClientesService,
    private _NewClientService: ManageClientService
  ) {
    actRoute.params.subscribe((param) => (this.id = param['id']));
  }

  getClienteIDSuccessHandler(response: IClientesExpanded) {
    this._NewClientService.regulacionesData.next(
      new ClientRegulaciones(response)
    );
    this._NewClientService.clientPersonalData.next(
      new ClientPersonalData(response)
    );

    this.loading = true;
  }

  ngOnInit(): void {
    this._Clientes.getClienteID(this.id).subscribe({
      next: this.getClienteIDSuccessHandler.bind(this),
    });
  }
}
