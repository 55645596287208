import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blacklist-new',
  templateUrl: './blacklist-new.component.html',
  styleUrls: ['./blacklist-new.component.css']
})
export class BlacklistNewComponent implements OnInit {

  @Output() propagarUser = new EventEmitter<string>();
  mensaje: string = 'Ok';
  formNuevo!: FormGroup;
  @Input() accionable!: Function;
  
  constructor(private formBuilder: FormBuilder,private apiService: ApiService) { }

  ngOnInit(): void {
    
    this.formNuevo = this.formBuilder.group({
      nombre: [, Validators.required],
      dni: [, Validators.required],
      direccion:[,Validators.required ],
      email:[, {
        validators: [Validators.required,Validators.email],
      }],
      telefono:[, Validators.required],
      tarjeta:[, Validators.required]
    });
  }

  onPropagarUser() {
    this.propagarUser.emit(this.mensaje);
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
    }

  

  submit(){
  
      this.apiService.crearBlacklist(this.formNuevo.value)
      .subscribe({
        next:(response) => {
          //this.estados = response.map((item) => ({id: item.id, name: item.descripcion}));
          Swal.fire({icon:'success', text:'ha sido guardado correctamente.'});
          this.onPropagarUser();
          this.accionable();
        },
        error: (error) => {
          Swal.fire({icon:'error', text:'Error al guardar.'});
          console.log(error);
          this.accionable();
        }
      })
    // }else{
    //   Swal.fire({icon:'error', text:'campos incompletos.'})
    // }
  }


  
}
