import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blacklist-update',
  templateUrl: './blacklist-update.component.html',
  styleUrls: ['./blacklist-update.component.css']
})
export class BlacklistUpdateComponent implements OnInit {

  @Input() accionable!: Function;
  @Input() listId!: number;
  formNuevo!: FormGroup;
  datosBlacklist:any;
  
  @Output() propagarUser = new EventEmitter<string>();
  mensaje: string = 'Ok';
  constructor(private formBuilder: FormBuilder,private apiService: ApiService) { }

  ngOnInit(): void {
    this.formNuevo = this.formBuilder.group({
      name: [, Validators.required],
      dni: [, Validators.required],
      domicilio:[, {
        validators: [Validators.required,Validators.pattern(/^([0-9])*$/)],
      }],
      email:[, {
        validators: [Validators.required,Validators.email],
      }],
      telefono:[, Validators.required],
      tarjeta:[, Validators.required]
    });
   
    this.cargarDatos();
  }

  cargarDatos(){
    this.apiService.getBalcklistById(this.listId).subscribe((response : any) => {
      console.log(response);
      this.datosBlacklist = response;
      this.formNuevo = this.formBuilder.group({
        name: [this.datosBlacklist.name, Validators.required],
        dni: [this.datosBlacklist.dni, Validators.required],
        domicilio:[this.datosBlacklist.domicilio, {
          validators: [Validators.required,Validators.pattern(/^([0-9])*$/)],
        }],
        email:[this.datosBlacklist.email, {
          validators: [Validators.required,Validators.email],
        }],
        telefono:[this.datosBlacklist.telefono, Validators.required],
        tarjeta:[this.datosBlacklist.tarjeta, Validators.required]
      });
    },
    (error)=>{
     console.log(error);
    });
  }
  submit(){
    this.apiService.modificarBacklist(this.formNuevo.value,this.listId)
    .subscribe({
      next:(response) => {
        //this.estados = response.map((item) => ({id: item.id, name: item.descripcion}));
        Swal.fire({icon:'success', text:'ha sido modificado correctamente.'});
        this.onPropagarUser();
        this.accionable();
      },
      error: (error) => {
        Swal.fire({icon:'error', text:'Error al modificar.'});
        console.log(error);
        this.accionable();
      }
    })
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
    }

  
  onPropagarUser() {
    this.propagarUser.emit(this.mensaje);
  }


}
