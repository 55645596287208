import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../../services/api.service';
import {
  UserInterface,
  UserInterfaceShowed,
} from '../../../../models/interfaces/user.interface';
import Swal from 'sweetalert2';
import ErrorStrings from '../../../../constants/ErrorStrings';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { TerminalService } from '../../terminal.service';
import { TerminalListItemInterface } from '../../interfaces/terminal-list-item.interface';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { User } from '../../../../models/user.models';
import UserTerminalRequestModel from '../../models/user-terminal-request.model';

@Component({
  selector: 'app-dialog-add-terminal-to-user',
  templateUrl: './dialog-add-terminal-to-user.component.html',
  styleUrls: ['./dialog-add-terminal-to-user.component.scss'],
})
export class DialogAddTerminalToUserComponent implements OnInit {
  autoCompleteUsersList: UserInterface[] = [];
  autoCompletePosnetList: TerminalListItemInterface[] = [];
  userControl = new FormControl();
  terminalControl = new FormControl();
  filteredUsers: UserInterface[] = [];
  filteredPosnet: TerminalListItemInterface[] = [];
  selectedTerminal: TerminalListItemInterface | null = null;
  selectedUser: UserInterface | null = null;

  constructor(
    public dialogRef: MatDialogRef<DialogAddTerminalToUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiService: ApiService,
    private _terminalService: TerminalService
  ) {}

  ngOnInit(): void {
    this.getUsersOfList();
    this.getEnabledTerminals();
    this.handleUserValueChanges();
    this.handlePosnetValueChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClicked(): void {
    if (this.selectedUser !== null) {
      const userTerminalRequest = new UserTerminalRequestModel(
        this.selectedUser?.id ?? null,
        this.selectedTerminal?.id ?? null
      );
      this.dialogRef.close(userTerminalRequest);
    } else {
      Swal.fire('Error', 'Faltan seleccionar campos', 'error');
    }
  }

  onTerminalOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTerminal = event.option.value;
  }

  onUserOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedUser = event.option.value;
  }

  /**
   * User handling and filtering section
   * @private
   */
  private handleUserValueChanges(): void {
    this.userControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filterUsers(name) : []))
      )
      .subscribe((users) => (this.filteredUsers = users));
  }

  /**
   * Terminal handling and filtering section
   * @private
   */
  private handlePosnetValueChanges(): void {
    this.terminalControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filterPosnets(name) : []))
      )
      .subscribe((users) => (this.filteredPosnet = users));
  }

  displayFnUsers(user: UserInterface): string {
    return user && user.nombre ? user.nombre : '';
  }

  displayFnPos(pos: TerminalListItemInterface): string {
    return pos && pos.terminal_number ? pos.terminal_number : '';
  }

  private _filterUsers(name: string): UserInterface[] {
    const filterValue = name.toLowerCase();
    return this.autoCompleteUsersList.filter((user) =>
      user.nombre.toLowerCase().includes(filterValue)
    );
  }

  private _filterPosnets(name: string): TerminalListItemInterface[] {
    const filterValue = name.toLowerCase();
    return this.autoCompletePosnetList.filter((posnet) =>
      posnet.terminal_number.toLowerCase().includes(filterValue)
    );
  }

  getUsersOfList(): void {
    this._apiService.getUsersList(0, 1000).subscribe({
      next: (result) => {
        this.autoCompleteUsersList = result.content;
      },
      error: () => {
        Swal.fire(ErrorStrings.ERROR, ErrorStrings.UNEXPECTED_ERROR, 'error');
      },
    });
  }

  getEnabledTerminals(): void {
    this._terminalService.httpGetEnabledPosnetList().subscribe({
      next: (result) => {
        this.autoCompletePosnetList = result.content;
      },
      error: () => {
        Swal.fire(ErrorStrings.ERROR, ErrorStrings.UNEXPECTED_ERROR, 'error');
      },
    });
  }
}
