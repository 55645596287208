<div class="content animated fadeIn">
    <div class="row  justify-content-center">
        <div class="column  mt-3 col-xl-8 col-lg-10">
            <!--Consulta-->
            <div class="card mb-5">
                <div class="card-header">
                    <h4>Consulta</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="consultaForm" (keydown)="EnterSubmit($event, consultaForm.value)">
                        <div class="card">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="medioPago" class="form-label">Medio de pago</label>
                                        <select formControlName="medio_pago" class="form-select" [ngClass]="{ 'is-invalid': submitted && form.medio_pago.errors }">
                                            <!-- <option selected value="" hidden="true">Seleccione un medio de pago</option> -->
                                            <option selected value="" >Todos</option>
                                            <option *ngFor="let pago of mediosPago"  value="{{ pago.id }}">{{ pago.marca }}</option>
                                        </select>
                                        <div *ngIf="submitted && form.medio_pago.errors" class="invalid-feedback">
                                            <div *ngIf="form.medio_pago.errors.required">Este campo es necesario</div>
                                        </div>
                                    </div>
                                </div>
                                <!--Fecha acreditación-->
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Desde la fecha</label>
                                            <div class="col-12 ">
                                                <input (focus)="datepicker()" (blur)="datepicker()" id="from" formControlName="desdeAcreditacion" class="input form-control" type="date" [ngClass]="{ 'is-invalid': submitted && form.desdeAcreditacion.errors }">
                                                <div *ngIf="submitted && form.desdeAcreditacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.desdeAcreditacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Hasta la fecha</label>
                                            <div class="col-12 ">
                                                <input (blur)="datepicker()" id="to" formControlName="hastaAcreditacion" class="input form-control " type="date" [ngClass]="{ 'is-invalid': submitted && form.hastaAcreditacion.errors }">
                                                <div *ngIf="submitted && form.hastaAcreditacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.hastaAcreditacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="buttons ">
                            <button type="button " class="link-green-outline btn" (click)="limpiar()">Limpiar búsqueda</button>
                            <!-- <button type="button" (click)="onSubmit(consultaForm.value) " class="btn btn-green ml-15 ">Buscar</button> -->
                            <button type="button " class="btn btn-green-secondary ml-15" (click)="onSubmit(consultaForm.value)">
                                Exportar 
                                <i class="fas fa-file-excel"  *ngIf="!loading" style="margin-left: 15px"></i>  
                                <span class="mx-1 spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<swal #mensajeSwal title icon confirmButtonText cancelButtonText showCancelButton cancelButtonColor confirmButtonColor confirm></swal>