import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  private busquedaFacturacion: any;

  constructor() { }

  getBusquedaFacturacion(){
    return this.busquedaFacturacion;
  }
  setBusquedaFacturacion(busqueda){  
    //Guarda en el servicio
    this.busquedaFacturacion = busqueda;
    // y en el localstorage
    localStorage.setItem('parametrosConsulta', JSON.stringify(this.busquedaFacturacion));
  }
}
