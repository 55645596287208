import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Facturacion } from 'src/app/models/facturacion/facturacion';
import { DatePipe } from '@angular/common';
import { FacturacionService } from 'src/app/services/facturacion.service';
declare var jQuery: any;

@Component({
  selector: 'app-facturacion-consulta',
  templateUrl: './facturacion-consulta.component.html',
  styleUrls: ['./facturacion-consulta.component.css']
})
export class FacturacionConsultaComponent implements OnInit {

    
  public dataSource:Facturacion[];

  consultaForm: UntypedFormGroup;
  selectable = true;
  addOnBlur = true;
  removable = true;
  submitted:boolean;
  dateIsSelected:boolean;
  formularioConsulta = true;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private facturacionService: FacturacionService,
    private route: Router) { }

  ngOnInit(): void {
     //Arma el formulario
     this.makeForm();
  }
  makeForm(){
    //Form Builder
    this.consultaForm = this.formBuilder.group({
        numero: [''],
        desdeCreacion: new UntypedFormControl(''),
        hastaCreacion: new UntypedFormControl('')
    })

    //DatePicker max && min
    var today = Date.now();
    var hoy = this.datePipe.transform(today,"yyyy-MM-dd");
    var datePickers = ['to', 'from']; 

    jQuery.each(datePickers, function (ind, elem) { 
      jQuery("#"+elem).attr({
        "max" : hoy
      })
    });    

  }
  //==== Funciones de los formularios ====

  onSubmit(formData){
    
    this.submitted = true;
    //Formulario inválido 
      if(this.consultaForm.invalid){
        return;
      } 
    //guarda en facturacion service los filtros de búsqueda
    this.facturacionService.setBusquedaFacturacion(formData);

    this.formularioConsulta = false;
    this.route.navigate(['dashboard/facturacion/resultados'])
  }

  limpiar(){

    this.submitted = false;
    this.consultaForm.reset();
  }

  //controls
  get form(){
    return this.consultaForm.controls;
  }
  get desdeCreacion() {
    return this.consultaForm.get('desdeCreacion') as UntypedFormControl;
  }
  get hastaCreacion() {
    return this.consultaForm.get('hastaCreacion') as UntypedFormControl;
  }

  //Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
  datepicker(){
    //Toma los datos del formulario
      var dateFrom = this.consultaForm.get('desdeCreacion').value;
      var dateTo = this.consultaForm.get('hastaCreacion').value;

      //Si no está vacío
      if(dateFrom != ""){
        jQuery("#to").attr({   
              "min" : dateFrom
            });
      }

      if(dateTo != ""){
            jQuery("#from").attr({   
            "max" : dateTo,
          });
      }      
    }

  //Rango de fechas (validación)
  dateChange(e){
    this.dateIsSelected = true;
    
    if(e.target.value == ""){

        //Remueve la validación
        this.desdeCreacion.clearValidators();
        this.hastaCreacion.clearValidators();
        //Actualiza
        this.desdeCreacion.updateValueAndValidity();
        this.hastaCreacion.updateValueAndValidity();
    }else{
      //Añade validación
      this.desdeCreacion.setValidators([Validators.required]);
      this.hastaCreacion.setValidators([Validators.required]);
      //Actualiza
      this.desdeCreacion.updateValueAndValidity();
      this.hastaCreacion.updateValueAndValidity();
    }  
  }
}
