<app-spinner *ngIf="loading"></app-spinner>
<div class="title">
    <h1>Cobranzas</h1>
</div>
<div class="col-lg-12 col-md-6 col-sm-12">
     <div class="mb-3">
        <form [formGroup]="consultaForm">

            <div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="cliente" class="form-label">Cliente</label>
                        <input matInput placeholder="Todos" aria-label="cliente" [matAutocomplete]="auto" formControlName="cliente" class="form-select">
                        <mat-autocomplete (optionSelected)="select($event)" #auto="matAutocomplete">
                        <mat-option *ngFor="let cliente of clientes$ | async" [value]="cliente.name">
                            <span>{{cliente.name  | titlecase}}</span>
                        </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="mb-3">
                            <label for="numeroDocumento" class="form-label">Número Documento</label>
                            <input (blur)='busqueda()' type="text" formControlName="numeroDocumento" digitOnly decimal="decimal" inputmode="numeric" placeholder="Número de documento" class="form-control" autocomplete="off">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="mb-3">
                            <label for="numeroFactura" class="form-label">Número Factura</label>
                            <input (blur)='busqueda()' type="text" formControlName="numeroFactura"  placeholder="Número de la factura" class="form-control" autocomplete="off">
                        </div>
                    </div>
                </div>
            </div>
            
            
        </form>
    </div> 
</div>
<app-tabla-resultados  [configTable]="configTable" [data]="dataSource" [totalElements]="totalElements" [pageNumber]="pageNumberMov" (eventGetPage)="nuevaSolicitud($event)"></app-tabla-resultados>

