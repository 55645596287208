import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationComponent } from './side-navigation.component';
import { MaterialModule } from 'src/app/material/material.module';
import { NavbarComponent } from './components/navbar/navbar.component';

/**
 * Modulo encargado de la sidebar y sus respectivas funcionalidades
 * La idea inicial es generar un sidebar responsivo, UI rich y facil de usar
 */

@NgModule({
  declarations: [SideNavigationComponent, NavbarComponent],
  imports: [CommonModule, MaterialModule],
  exports: [SideNavigationComponent],
})
export class SideNavigationModule {}
