import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Storage } from 'src/app/core/decorators/storage.decorator';
import { CommonsService } from 'src/app/core/commons.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-credenciales',
  templateUrl: './dialog-credenciales.component.html',
  styleUrls: ['./dialog-credenciales.component.scss'],
  imports: [MatDialogModule, MatButtonModule, CommonModule, MatIconModule],
  standalone: true,
})
export class DialogCredencialesComponent {
  @Storage('BEARER_CREDENTIAL')
  bearerCredential: string | null;
  @Storage('WEBHOOK_CREDENTIAL')
  webHookCredential: string | null;

  constructor(
    public dialogRef: MatDialogRef<DialogCredencialesComponent>,
    private _Commons: CommonsService,
    private _router: Router
  ) {}

  copyValue(value: string) {
    this._Commons.clipboardItem(value);
  }

  volver(){
    this._router.navigate([`dashboard/clientes`]);
  }
}
