import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-grafico-torta',
  templateUrl: './grafico-torta.component.html',
  styleUrls: ['./grafico-torta.component.css']
})
export class GraficoTortaComponent implements OnInit {


  ngOnInit(): void {


  }

}