import { Component, OnInit } from '@angular/core';
import { ClientesService } from '../services/clientes.service';
import { ActivatedRoute } from '@angular/router';
import {
  IComisionesCliente,
  IComisionesClientePost,
} from '../types/comisiones-cliente.type';
import { ErrorHandler } from '../services/ErrorHandler.service';
import { CommonsService } from 'src/app/core/commons.service';

@Component({
  templateUrl: './comisiones-cliente.component.html',
  styleUrls: ['./comisiones-cliente.component.scss'],
})
export class ComisionesClienteComponent implements OnInit {
  id: number;
  loading = true;
  errorOnLoad = false;

  comisionesList: IComisionesCliente[] = [];

  constructor(
    private _Clientes: ClientesService,
    private actRoute: ActivatedRoute,
    private _Common: CommonsService
  ) {
    actRoute.params.subscribe((param) => (this.id = param['id']));
  }

  getComisiones() {
    this._Clientes.getComisiones(this.id).subscribe({
      next: this.getComisionesSuccessHandler.bind(this),
      error: this.errorHandler.bind(this),
    });
  }

  getComisionesSuccessHandler(data: IComisionesCliente[]) {
    this.comisionesList = data.map((item) => ({
      ...item,
      comision: item.comision,
      gasto: item.gasto,
    }));
  }
  errorHandler() {
    const errHandler = new ErrorHandler(
      'ERROR',
      'Ocurrio un error solicitando los datos, por favor intentelo nuevamente mas tarde',
      'error'
    );
    errHandler.launch();
  }

  updateComision(comision: IComisionesClientePost) {
    this._Clientes.updateComision(this.id, comision).subscribe({
      next: this.updateComisionSuccessHandler.bind(this),
      error: this.errorHandler.bind(this),
    });
  }

  updateComisionSuccessHandler() {
    this._Common.showSnackbar('Guardando cambios');
    this.getComisiones();
  }

  ngOnInit(): void {
    this.getComisiones();
  }
}
