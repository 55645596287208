import { IComisionesClientePost } from '../types/comisiones-cliente.type';

export class ComisionesClientePost implements IComisionesClientePost {
  medio_pago: number;
  comision: number;
  gasto: number;

  constructor(data: IComisionesClientePost | null = null) {
    this.medio_pago = (data && data.medio_pago) ?? 0;
    this.comision = (data && data.comision) ?? 0;
    this.gasto = (data && data.gasto) ?? 0;
  }
}
