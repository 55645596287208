import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
declare var jQuery: any;

@Component({
  selector: 'app-btn-pago',
  templateUrl: './btn-pago.component.html',
  styleUrls: ['./btn-pago.component.css']
})
export class BtnPagoComponent implements OnInit {

  crearBotonPago: UntypedFormGroup;
  submitted:boolean;
  decimal:boolean = true;
  

  constructor( 
    private _router:Router,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
     //Arma el formulario
     this.makeForm();
  }

  makeForm(){
    //Form Builder
    this.crearBotonPago = this.formBuilder.group({
        titulo: ['', Validators.required],
        descripcion: ['', Validators.required],
        monto: ['', Validators.required],
        vencimiento: ['', Validators.required]
    })

    //DatePicker max && min
    var today = Date.now();
    var hoy = this.datePipe.transform(today,"yyyy-MM-dd");
    var datePickers = ['desdeVencimiento', 'hastaVencimiento']; 
    //Asigna propiedad máx = hoy por defecto
    jQuery.each(datePickers, function (ind, elem) { 
      jQuery("#"+elem).attr({
        "max" : hoy
      })
    });   

  }

  //==== Funciones de los formularios ====
  onSubmit(formData){
    this.submitted = true;
    //Formulario inválido 
      // if(this.crearBotonPago.invalid){
      //   return;
      // } 

     //Redirecciona a la vista de los resultados
    this._router.navigate(['dashboard/compartir-btn-pago']);
  } 

  //Controls
  get form(){
    return this.crearBotonPago.controls;
  }

  limpiar(){
    this.crearBotonPago.reset();
    this.submitted = false;
  }

  //Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
  datepicker(){
    var dateFrom = null;
    var dateTo = null;
    //Toma los datos del formulario
    dateFrom = this.crearBotonPago.get('desdeVencimiento').value;
    dateTo = this.crearBotonPago.get('hastaVencimiento').value;

    //Si no está vacío
    if(dateFrom != ""){
      jQuery("#desdeVencimiento").attr({
            "min" : dateFrom
          });
    }
    if(dateTo != ""){
          jQuery("#hastaVencimiento").attr({
          "max" : dateTo,
        });
    }
  }
}
