import { TableItem } from './models/extras';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination';
import { ProductionConfigTableInterface } from 'src/app/components/shared/tabla-resultados/production-config-table.interface';
import { PageableRequestInterface } from 'src/app/core/interfaces/pageable.interface';
import Swal from 'sweetalert2';
import { DialogTriggerFileComponent } from './dialog-trigger-file/dialog-trigger-file.component';
import { PaymentFileService } from './payment-file.service';
import { DialogPaymentFileComponent } from './dialog-payment-file/dialog-payment-file.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IMatPaginationItems } from 'src/app/material/types/SortEmitter.type';
import { MatPaginationSettings } from 'src/app/material/models/pagination.model';
import { MatPaginator } from '@angular/material/paginator';
import { ICliente, IClientesExpanded, ideable } from '../clientes/types/clientes.type';
import { Router } from '@angular/router';


@Component({
  selector: 'app-payment-file',
  templateUrl: './payment-file.component.html',
  styleUrls: ['./payment-file.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PaymentFileComponent implements OnInit {



  public totalElements: number;
  public configTable: any;
 // public configTable: Partial<ProductionConfigTableInterface>;
  public pageNumberMov = 1;
  dataSource: TableItem[];
  static DELETE_CLIENT_FILE_FLAG = 'delete_client_file';
  static CREATE_CLIENT_FILE_FLAG = 'create_client_file';
  dataFromDialog: any | null = null;

  screenWidth: number = 2000;
  expandedElement: IDetallesExpanded | null;
  clientDetail: IDetallesExpanded | null = null;
  columnsToDisplay = [];
  matPaginationSettings: MatPaginationSettings;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //screenWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    this.setTableColumnsList();
  }
  allColumnsObject = availableColumnsToShow;
  
 /* TERMINAL_CONFIG_TABLE: Partial<ProductionConfigTableInterface> = {
    flag: 'RELATED_USERS',
    headers: {
      columns_show: ['ID Cliente', 'Cliente', 'Estado'],
      columns_search: ['id', 'razon_social', 'enabled'],
    },
    buscar: true,
    buttonsPanel: [
      {
        id: PaymentFileComponent.DELETE_CLIENT_FILE_FLAG,
        columnTitle: '',
        action: null,
        title: null,
      },
      {
        id: PaymentFileComponent.CREATE_CLIENT_FILE_FLAG,
        columnTitle: '',
        action: null,
        title: null,
      }
    ],
    
  };*/
  public pagingSettings: PaginationInstance = {
    id: 'server',
    itemsPerPage: 10,
    currentPage: null,
    totalItems: null
};
  isTableLoading: boolean;
  node: PageableRequestInterface<any> | null = null;
  constructor(private _paymentFileService: PaymentFileService, 
              public dialog: MatDialog,
              private _router: Router) {this.matPaginationSettings = new MatPaginationSettings();this.setTableColumnsList(); }

  ngOnInit(): void {
    let headers = {
      columns_show: ['ID Cliente', 'Cliente', 'Estado', 'Archivo caja', 'Archivo pago','Liquidación','Rendición'],
      columns_search: ['id', 'razon_social', 'enabled','archivo_caja','archivo_pago','liquidacion','rendicion'],
    }
    this.configTable = {
      headers: headers,
      exportToExcel: false,
      verDetallesTarjeta:false,
      buscar:false,
      verDetalles:false,
      functionUsuarios:false,
      functionBlacklist:false,
      functionPaymentFile:true
    }

   // this.configTable = this.TERMINAL_CONFIG_TABLE;
    this.cargarTabla();
  }

  cargarTabla(): void {
    this.isTableLoading = true;
    this._paymentFileService.getClientFilePayment(10,this.matPaginationSettings.PageNumber).subscribe(
      (response) => {
        this.matPaginationSettings.TotalElements = response.totalElements;
        this.matPaginationSettings.PageNumber = response.pageable.pageNumber;
        this.totalElements = response.totalElements;
        this.dataSource = response.content.map((item): TableItem => {
          return {
            id: item.id,
            razon_social: item.razon_social,
            enabled: item.enabled === true ? 'Habilitado' : 'Deshabilitado',
            texto_enable:item.enabled,
            archivo_caja:item.archivo_caja === true ? 'Habilitado' : 'Deshabilitado',
            archivo_pago:item.archivo_pago === true ? 'Habilitado' : 'Deshabilitado',
            liquidacion:item.liquidacion === true ? 'Habilitado' : 'Deshabilitado',
            rendicion:item.rendicion === true ? 'Habilitado' : 'Deshabilitado',
            email:item.email
          };
        });
        this.isTableLoading = false;
      },

      (error) => {
        console.log(error);
        this.isTableLoading = false;
      }
    );
    
  }

  handleButtonPanelEmitter(data: TableItem): void {
    console.log("data: ", data);
   switch (data.button_id) {
      case PaymentFileComponent.DELETE_CLIENT_FILE_FLAG:
       // this.enableClientFile(data.id,data.texto_enable,null);
        break;
      case PaymentFileComponent.CREATE_CLIENT_FILE_FLAG:
        //this.openDialog();
        break;
      default:
        break;
    }
  }

 /* getNewPageRequest(page: { pageNumber: number; pageSize: number }): void {
    this.cargarTabla(page.pageNumber, page.pageSize);
  }
*/
  enableClientFile(value:any): void {
    Swal.showLoading();
    const text:string = value.enable === 'Habilitado' ? 'Deshabilar':'Habilitar';
    const isEnabled:boolean = value.enable === 'Habilitado' ? false: true;
      Swal.fire({
        title:    `¿Esta seguro de ${text} al cliente ${value.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si!',
        cancelButtonText: 'Cancelar!'
    }).then((result) => {
        if (result.isConfirmed) {
          this.enableClient(value.id,isEnabled)
        }
    });
    
  }

  enableClient(clientId: number,isEnabled:boolean){
    console.log("llega modificado ", isEnabled);
    const text:string = isEnabled === true ? 'Habilitado': 'Deshabilitado';
    this._paymentFileService.enableClientPaymentFile(clientId,isEnabled).subscribe({
      next: (response) => {
          Swal.fire({icon: 'success', text: `Cliente ${text}`});
        //  this.cargarTabla(this.pageNumberMov, 10);
      },
      error: (error) => {
          Swal.fire({icon: 'error', text: 'Error en el proceso.'});
         // this.cargarTabla(this.pageNumberMov, 10);
      }
    });
  }

  openDialogGenerar(value:any): void {
    const data_cliente ={
      name:value.name,
      id:value.id
    }
    const dialogRef = this.dialog.open(DialogTriggerFileComponent, {
      data:data_cliente,
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
        console.log(result)
    });
  }

  openDialogEdit(value:number){
    this._paymentFileService.getClienteArchivoPago(value).subscribe({
      next: (response) => {
          const dialogRef = this.dialog.open(DialogPaymentFileComponent, {
            data:response,
            width: '30%'
          });
      
          dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
              console.log(result)
          });
      },
      error: (error) => {
          Swal.fire({icon: 'error', text: 'Error en el recuperar datos.'});
      }
    });
  }

  openDialogAlta(){
      const dialogRef = this.dialog.open(DialogPaymentFileComponent, {
        data:null,
        width: '30%'
      });
      
      dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
        this._router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=> this._router.navigate(["dashboard/payment-file"]));
      });
  }

  public nextOrPreviousPage(pageNumber: number): void {
    this.pagingSettings.currentPage = pageNumber;
   // this.cargarTabla(pageNumber - 1, 10);
  }

  private setTableColumnsList(): void {
    let type = 'small';
    if (window.innerWidth > 700) type = 'big';
    switch (type) {
      case 'small':
        this.columnsToDisplay = ['id'];
        break;
      default:
        this.columnsToDisplay = Object.keys(this.allColumnsObject);
        break;
    }
  }


  setterPagination(event: IMatPaginationItems): void {
    this.matPaginationSettings.PageNumber = event.pageIndex;
    this.matPaginationSettings.PageSize = event.pageSize;
    this.cargarTabla();
  }

  getClientDetail(cliente: IDetallesExpanded & ideable): void {
    this.clientDetail = cliente;
  }

  


}

const availableColumnsToShow = {
  id: 'ID',
  razon_social: 'Razon Social',
  enabled: 'Estado',
};

export interface IDetallesExpanded{
  id: string,
  razon_social: string,
  enabled: string,
  archivo_caja: string,
  archivo_pago: string,
  liquidacion: string,
  rendicion: string,
  texto_enable: string,
  email:string
}

