import { Component, OnInit } from '@angular/core';
import { Facturacion } from 'src/app/models/facturacion/facturacion';

@Component({
  selector: 'app-facturacion-resultados',
  templateUrl: './facturacion-resultados.component.html',
  styleUrls: ['./facturacion-resultados.component.css']
})
export class FacturacionResultadosComponent implements OnInit {

  
  public headersFacturacion:string[];
  public headersFacturacionSearch :string[];
  public datosTabla:Facturacion[];
  public totalElements:number;
  ///////////////
  public filtros:any; //tipo consulta Liquidación
  public valorBusquedaTermino:any;
  public cantidadItems:any;
  ///////////////
  itemPerPage:string;

  constructor() { }

  ngOnInit(): void {
  }
  
  peticionApiHijo(respuesta:any){
    // recibe llamado del hijo para realizar la solicitud
    // this.solicitudRendiciones(respuesta.page, respuesta.itemPerPage); //ACA HACE LA PETICIÓN
  }

  filtrar(termino:string){
    // console.clear();
    this.valorBusquedaTermino = termino;  
  }
  cargarItems(cantidad: string){
    // console.clear();
    this.cantidadItems = cantidad;  
  }

}
