import { IClientPersonalData } from '../types/clientes.type';

export class ClientPersonalData implements IClientPersonalData {
  web: string;
  email: string;
  domicilio: string;
  localidad: string;
  documento: any;
  enabled: boolean;
  razon_social: string;
  nombre_legal: string;

  constructor(data: IClientPersonalData) {
    this.web = (data && data.web) || '';
    this.email = (data && data.email) || '';
    this.domicilio = (data && data.domicilio) || '';
    this.localidad = (data && data.localidad) || '';
    this.documento = (data && data.documento) || '';
    this.enabled = (data && data.enabled) || true;
    this.razon_social = (data && data.razon_social) || '';
    this.nombre_legal = (data && data.nombre_legal) || '';
    
  }
}
