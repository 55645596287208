<div class="row animated fadeIn" style="padding:10px">
    <div class="col-9">
        <div class="col-lg-4 col-md-6 col-sm-12">
            <app-buscador (valorBusqueda)="filtrar($event)"></app-buscador>
        </div>
    </div>
    <div class="col-3 text-center">
        <button class="btn btn-green" (click)="nuevoBotonPago()"> + Crear nuevo</button>
    </div>
</div>
<ul class="listado animated fadeIn">
    <li class="d-grid" *ngFor="let item of items">
        <div class="d-flex">
            <div class="box">
                <p>P</p>
            </div>
            <div class="datos">
                <h6>Prueba</h6>
                <p>$12.00,08</p>
            </div>
        </div>
        <div class="opciones d-flex ">
            <a [routerLink]="'../compartir-btn-pago'">Compartir</a>
            <div class="dropdown">
                <a href="#" role="button" class="d-block" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fal fa-ellipsis-v"></i>
                </a>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li><a class="dropdown-item" href="#">Modificar</a></li>
                    <li><a class="dropdown-item" href="#">Borrar</a></li>
                </ul>
            </div>
        </div>
    </li>

</ul>