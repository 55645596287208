import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUserPersonalData } from '../../types/clientes.type';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserPersonalData } from '../../models/UserPersonalData.model';
import Swal from 'sweetalert2';
import { ErrorHandler } from '../../services/ErrorHandler.service';
import Password from '../../constants/password.constant';

@Component({
  selector: 'cliente-form-usuario',
  templateUrl: './form-usuario.component.html',
  styleUrls: ['./form-usuario.component.scss'],
})
export class FormUsuarioComponent implements OnInit {
  @Input() userData: IUserPersonalData | null = null;
  @Output() userDataEmitter: EventEmitter<IUserPersonalData> | null = null;

  userDataFormGroup: FormGroup = this.makeFormGroup(null);

  constructor(private _formBuilder: FormBuilder) {
    this.userDataEmitter = new EventEmitter<IUserPersonalData>();
    this.userDataFormGroup = this.makeFormGroup(null);
  }

  ngOnInit(): void {}

  makeFormGroup(data?: IUserPersonalData | null) {
    const formData = new UserPersonalData(data);

    return this._formBuilder.group({
      username: [(formData && formData.username) || '', Validators.required],
      password: [(formData && formData.password) || '', Validators.required],
      confirmPassword: ['', [Validators.required]],
    });
  }

  get confirmPasswordControlValue() {
    return this.userDataFormGroup.get('confirmPassword').value;
  }

  get passwordControlValue() {
    return this.userDataFormGroup.get('password').value;
  }

  /**
   * Hara una revision de los password y luego generara una respuesta
   */
  submitFormGroup(form: FormGroup) {
    try {
      if (this.passwordControlValue !== this.confirmPasswordControlValue)
        throw new ErrorHandler(Password.MUSTMATCH_HTML);
      if (!Password.REGEX.test(this.passwordControlValue))
        throw new ErrorHandler(Password.WEAK_HMTL);
      this.userDataEmitter.emit(new UserPersonalData(form.value));
    } catch (error: any) {
      const errorHandler = error as ErrorHandler;
      errorHandler.launch();
    }
  }
}
