import { CurrencyPipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SimpleMaskMoney from 'simple-mask-money'; // import mask
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';
import Swal from 'sweetalert2';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
@Component({
  selector: 'app-transferir',
  templateUrl: './transferir.component.html',
  styleUrls: ['./transferir.component.css']
})
export class TransferirComponent implements OnInit {
   public datos:any;
   public dataSource: any []; 
   public cliente:any;
   datosTransferencia:boolean=true;
   msjfinal:boolean=false;
   msjError:boolean=false;

   @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;

  constructor( private _route:ActivatedRoute,private _api:ApiService,
     private currencyPipe: CurrencyPipe,private _router: Router,
     private _functionsService: FunctionsService) { }

  ngOnInit(): void {
    
   let id = +this._route.snapshot.paramMap.get('id');
   let id_cliente = +this._route.snapshot.paramMap.get('id_cliente');
   let neto = +this._route.snapshot.paramMap.get('neto');
  
   this._api.getClienteID(id_cliente).then((response) =>{
    this.cliente = response;
   

    var array = Array<any>();
    this.datos = {
      id:id,
      nombreCliente:this.cliente.nombre_legal,
      neto:this.currencyPipe.transform((neto/100), 'CAD', '$', '2.2-2', 'es-ARS'),
      banco:this.cliente.cliente_cbu[0].banco.bank_description,
      tipo_cuenta:this.cliente.cliente_cbu[0].tipo_cta,
      cbu:this.cliente.cliente_cbu[0].address
    };
 
   
    array.push(this.datos);
    this.dataSource = array;
   
  })
  .catch((error: any) =>{
    //this._functionsService.imprimirMensaje(error,"error al cargar la lista de clientes");
   
  });
  

  
  }

  realizarTransferencia(){
   
      Swal.fire({
        title: '¿Está seguro de realizar la transferencia?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Transferir'
      }).then((result) => {
        if (result.isConfirmed) {
          //this.msjfinal=true;
          Swal.fire(
            '¡Transferencia exitosa!',
            '',
            'success'
            
          );
          this.datosTransferencia=false;
          this.msjfinal=true;
          
        }else{
          Swal.fire({
            icon: 'error',
            text: 'Error al intentar realizar la transferencia.'
   
            
          })
          this.datosTransferencia=false;
          this.msjError=true;
         // this._router.navigate(['dashboard/liquidacion/resultados']);
        }
      }).catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al intentar realizar la transferencia.',
          //footer: '<a href="">Why do I have this issue?</a>'
        })
           
      })

   
  }


  volver(){
    this._router.navigate(['dashboard/liquidacion/resultados']);
  }

}
