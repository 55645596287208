import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerminalListComponent } from './terminal-list/terminal-list.component';
import {RouterModule} from '@angular/router';
import {TERMINAL_ROUTES} from './terminal-routing.routes';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SharedModule} from '../../components/shared/shared.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { DialogAddTerminalToUserComponent } from './components/dialog-add-terminal-to-user/dialog-add-terminal-to-user.component';
import { DialogNewTerminalComponent } from './components/dialog-new-terminal/dialog-new-terminal.component';



@NgModule({
  declarations: [
    TerminalListComponent,
    DialogAddTerminalToUserComponent,
    DialogNewTerminalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(TERMINAL_ROUTES),
    MatProgressBarModule,
    SharedModule,
    SweetAlert2Module,
  ]
})
export class TerminalModule { }
