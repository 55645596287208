import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import {
  IClientesExpanded,
  IClientesResponse,
  ICliente,
  ideable,
} from '../types/clientes.type';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { ClientesService } from '../services/clientes.service';
import { IMatPaginationItems } from 'src/app/material/types/SortEmitter.type';
import { MatPaginationSettings } from 'src/app/material/models/pagination.model';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'listado-cliente',
  templateUrl: './listado-cliente.component.html',
  styleUrls: ['./listado-cliente.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListadoClienteComponent implements OnInit {
  /**
   * allColumnsObject - Una variable la cual contiene
   * como key las propiedades traidas a la API y como valor
   * obtiene los nombres que seran mostrados en la tabla
   */
  allColumnsObject = availableColumnsToShow;
  /**
   * MatTable - Required - Lista de las propiedades que seran
   * mostradas dentro de la tabla
   */
  columnsToDisplay = [];
  /**
   * Detalle del cliente, se vera solo cuando damos click al cliente
   */
  expandedElement: IClientesExpanded | null;
  clientDetail: IClientesExpanded | null = null;
  /**
   * La tabla depende del ancho de pantalla, en la variable se carga
   * el ancho actual en caso de que se haga un resize
   */
  screenWidth: number = 2000;
  /**
   * Se guardan los datos de la lista de clientes
   */
  dataSource = new MatTableDataSource([]);

  /**
   * - Paginacion -
   * - matPaginationSettings - Configuracion de uso para la paginacino de
   * MatTable
   * - paginator - Rastrea los cambios que ocurran dentro del paginador como
   * componente hijo
   */
  matPaginationSettings: MatPaginationSettings;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * @constructor
   * @param _Clientes - Dependency Injeciton of HTTP Clientes
   */
  constructor(private _Clientes: ClientesService) {
    this.setTableColumnsList();
    this.matPaginationSettings = new MatPaginationSettings();
  }

  /**
   * Vigila los cambios de ancho de pantalla
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    this.setTableColumnsList();
  }

  /**
   * Gestiona los datos obtenidos para el listado de clientes
   * @description Esta funcion solamente hace la peticion y luego
   * delega la responsabilidad en caso de salir exitoso o erroneo
   */
  getDataSource() {
    this._Clientes
      .getClientes(
        this.matPaginationSettings.PageNumber,
        this.matPaginationSettings.PageSize
      )
      .subscribe({
        next: this.getClienteSuccessHandler.bind(this),
        error: this.errorHandler.bind(this),
      });
  }

  getClientDetail(cliente: ICliente & ideable): void {
    this._Clientes.getClienteID(cliente.id).subscribe({
      next: (data: IClientesExpanded): void => {
        this.clientDetail = data;
      },
      error: this.errorHandler.bind(this),
    });
  }

  /**
   * - Ocurre En caso de que getDataSource ocurra exitosamente.
   * @description Actualiza los datos de la tabla y tambien datos de paginacion
   * como elementos totales y numero de pagina actual
   * @param response
   */
  getClienteSuccessHandler(response: IClientesResponse): void {
    this.dataSource = new MatTableDataSource(response.content);
    this.matPaginationSettings.TotalElements = response.totalElements;
    this.matPaginationSettings.PageNumber = response.pageable.pageNumber;
  }
  errorHandler(error) {
    console.log(error);
    Swal.fire(
      'ERROR',
      'Ocurrio un error buscando los clientes. Por favor intente mas tarde. COD: 400 | 500',
      'error'
    );
  }

  /**
   * setterPagination
   * Actualiza las variables necesarias y luego delega la llamada a la api
   * a getDataSource()
   */
  setterPagination(event: IMatPaginationItems): void {
    this.matPaginationSettings.PageNumber = event.pageIndex;
    this.matPaginationSettings.PageSize = event.pageSize;
    this.getDataSource();
  }

  /**
   * Manejador de filtros directamente con el MatTable
   * @param event
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Decide el formato de las columnas necesarias segun el espacio que tienen
   */
  private setTableColumnsList(): void {
    let type = 'small';
    if (window.innerWidth > 700) type = 'big';
    switch (type) {
      case 'small':
        this.columnsToDisplay = ['id'];
        break;
      default:
        this.columnsToDisplay = Object.keys(this.allColumnsObject);
        break;
    }
  }

  // Ciclo de vida de un componente

  ngOnInit(): void {
    this.getDataSource();
  }
}

// valorApi:textoMostradoEnTable
const availableColumnsToShow = {
  id: 'ID',
  razon_social: 'Razon Social',
  nombre_legal: 'Nombre de Negocio',
  enabled: 'Habilitado',
};
