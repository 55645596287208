<h1 mat-dialog-title>Asociar terminal con usuario</h1>
<div mat-dialog-content>
  <p>1. Ingresa el usuario</p>
  <mat-form-field>
    <input type="text" placeholder="Ingresa el usuario" matInput [matAutocomplete]="autoUser" [formControl]="userControl">
    <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFnUsers" (optionSelected)="onUserOptionSelected($event)">
      <mat-option *ngFor="let user of filteredUsers" [value]="user">
        {{ user.nombre}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <p>2. Ingresa la terminal a asociar</p>
  <mat-form-field>
    <input type="text" placeholder="Ingresa la terminal" matInput [matAutocomplete]="autoTerminal" [formControl]="terminalControl">
    <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayFnPos" (optionSelected)="onTerminalOptionSelected($event)">
      <mat-option *ngFor="let posnet of filteredPosnet" [value]="posnet">
        {{ posnet.terminal_number }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button mat-button (click)="onClicked()"  cdkFocusInitial>Asociar</button>
</div>
