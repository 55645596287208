import { Component, OnInit,  ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { TransaccionesService } from 'src/app/services/transacciones.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FunctionsService } from 'src/app/services/functions.service';
import { ClientesService } from '../../clientes/services/clientes.service';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
declare var jQuery: any;

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.css']
})

export class ConsultaComponent implements OnInit{

  consultaForm: UntypedFormGroup;
  selectable = true;
  addOnBlur = true;
  removable = true;
  submitted:boolean;
  dateIsSelected:boolean;
  listaClientes;
  mediosPago;
  estados:Cliente[];
  //Fechas
  dateCreacionIsSelected:boolean;
  dateAcreditacionIsSelected:boolean;
  datePaymentIsSelected:boolean;

  @ViewChild('inputSimulate') input_simulate_numbers: any;
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent



  constructor(
    private _router: Router,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private _functionsService: FunctionsService,
    private apiService: ApiService,
    private _Clientes: ClientesService,
    private transaccionesService: TransaccionesService
    ) {

    //Lista Estados
  }

  ngOnInit(): void {
    this.transaccionesService.getListaEstados()
    .subscribe({
      next:(response) => {
        this.estados = response.map((item) => ({id: item.id, name: item.descripcion}));
      },
      error: (error) => {
        Swal.fire({icon:'error', text:'Error al cargar estados de movimientos'})
      }
    })
    //Arma el formulario
    this.makeForm();
    //Obtiene el listado de Clientes
    this._Clientes.getClientesPromise()
      .then((response) =>{
        this.listaClientes = response;
        // this._functionsService.imprimirMensaje(this.listaClientes, "Clientes: ");
      })
      .catch(()=>{
        this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los clientes`, "error", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire();
      });

    this.apiService.getMedioPago()
      .then((response) =>{
        this.mediosPago = response;
        //ordena alfabéticamente
        this.mediosPago.sort((a, b) => {
          let fa = a.marca.toLowerCase(),
              fb = b.marca.toLowerCase();
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
      });
        // this._functionsService.imprimirMensaje(this.mediosPago, "Medio pagos: ");
      })
      .catch((error)=>{
        this._functionsService.imprimirMensaje(error, "Error en medio pagos: ");
        this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los medios de pagos`, "error", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire();
      });


  }

  makeForm(){
    //Form Builder
    this.consultaForm = this.formBuilder.group({
        cliente: [''],
        numero: [''],
        estado: [''],
        medioPago: [''],
        ref_externa: [''],
        codigo_barras: [''],
        desdeCreacion: new UntypedFormControl(''),
        hastaCreacion: new UntypedFormControl(''),
        desdePago: new UntypedFormControl(''),
        hastaPago: new UntypedFormControl(''),
        desdeAcreditacion: new UntypedFormControl(''),
        hastaAcreditacion: new UntypedFormControl(''),
    })

    //DatePicker max && min
    var today = Date.now();
    var hoy = this.datePipe.transform(today,"yyyy-MM-dd");
    var datePickers = ['toCreation', 'fromCreation', 'toPayment', 'fromPayment'];
    //Asigna propiedad máx = hoy por defecto
    jQuery.each(datePickers, function (ind, elem) {
      jQuery("#"+elem).attr({
        "max" : hoy
      })
    });
  }

    //==== Funciones de los formularios ====

    onSubmit(formData){
      this.submitted = true;
      //Formulario inválido
      if(this.consultaForm.invalid){
        return;
      }
      //Guarda en el servivio el formulario
      this.transaccionesService.setFiltrosBusqueda(formData);
       //Redirecciona a la vista de los resultados
      this._router.navigate(['dashboard/movimientos/resultados']);
    }

    limpiar(){
      this.consultaForm.reset();
      this.submitted = false;
      //Remueve la validaciónde las fechas
      // this.desdeCreacion.clearValidators();
      // this.hastaCreacion.clearValidators();
      // this.desdePago.clearValidators();
      // this.hastaPago.clearValidators();
      // this.desdeAcreditacion.clearValidators();
      // this.hastaAcreditacion.clearValidators();
      //Actualiza
      // this.desdeCreacion.updateValueAndValidity();
      // this.hastaCreacion.updateValueAndValidity();
      // this.desdePago.updateValueAndValidity();
      // this.hastaPago.updateValueAndValidity();
      // this.desdeAcreditacion.updateValueAndValidity();
      // this.hastaAcreditacion.updateValueAndValidity();

      //Set default values options
      // 
      this.enabled();
      this.makeForm();
    }

    //Controls
    get form(){
      return this.consultaForm.controls;
    }

    get desdeCreacion() {
      return this.consultaForm.get('desdeCreacion') as UntypedFormControl;
    }
    get hastaCreacion() {
      return this.consultaForm.get('hastaCreacion') as UntypedFormControl;
    }
    get desdePago() {
      return this.consultaForm.get('desdePago') as UntypedFormControl;
    }
    get hastaPago() {
      return this.consultaForm.get('hastaPago') as UntypedFormControl;
    }
    get desdeAcreditacion() {
      return this.consultaForm.get('desdeAcreditacion') as UntypedFormControl;
    }
    get hastaAcreditacion() {
      return this.consultaForm.get('hastaAcreditacion') as UntypedFormControl;
    }

   //El estado de pago condiciona los demas inputs, es decir, por EJ: si ESTADO = acreditado, entonces, fecha de acreditacion se deshabilita
   changeEstado(estado:string){
    //Deshabilita medios de pago y fecha de acreditacion
    switch(estado){
      case "1":
        this.disabled();
        break;
      case "2":
        this.enabled();
        break;
      case "3":
        this.enabled();
        break;
      case "4":
        this.disabled();
        break;
        case "5":
        this.enabled();
        break;
    }

  }
  //Deshabilita fecha de pago y fecha de acreditacion
  disabled(){
    this.consultaForm.controls['medioPago'].disable();
    this.consultaForm.controls['desdeAcreditacion'].disable();
    this.consultaForm.controls['hastaAcreditacion'].disable();
    this.consultaForm.controls['desdePago'].disable();
    this.consultaForm.controls['hastaPago'].disable();
  }
   //Habilita fecha de pago y fecha de acreditacion
  enabled(){
    this.consultaForm.controls['medioPago'].enable();
    this.consultaForm.controls['desdeAcreditacion'].enable();
    this.consultaForm.controls['hastaAcreditacion'].enable();
    this.consultaForm.controls['desdePago'].enable();
    this.consultaForm.controls['hastaPago'].enable();
  }
    //Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
    datepicker(filtroFecha:string){
      var dateFrom = null;
      var dateTo = null;
      switch (filtroFecha) {
        case "creacion":
            //Toma los datos del formulario
            dateFrom = this.consultaForm.get('desdeCreacion').value;
            dateTo = this.consultaForm.get('hastaCreacion').value;

            //Si no está vacío
            if(dateFrom != ""){
              jQuery("#toCreation").attr({
                    "min" : dateFrom
                 });
            }
            if(dateTo != ""){
                 jQuery("#fromCreation").attr({
                  "max" : dateTo,
               });
            }
        break;
        case "pago":
          //Toma los datos del formulario
          dateFrom = this.consultaForm.get('desdePago').value;
          dateTo = this.consultaForm.get('hastaPago').value;

          //Si no está vacío
          if(dateFrom != ""){
            jQuery("#toPayment").attr({
                  "min" : dateFrom
                });
          }
          if(dateTo != ""){
                jQuery("#fromPayment").attr({
                "max" : dateTo,
              });
          }
        break;
        case "acreditacion":
            //Toma los datos del formulario
            dateFrom = this.consultaForm.get('desdeAcreditacion').value;
            dateTo = this.consultaForm.get('hastaAcreditacion').value;

            //Si no está vacío
            if(dateFrom != ""){
              //si es fecha acreditación puede consultar en fechas futuras (mayores a hoy)
              var today = new Date();
              var hoy =  today.setDate(today.getDate() + 365);
              var oneMoreYear = this.datePipe.transform(hoy,"yyyy-MM-dd");

              jQuery("#toAccreditation").attr({
                    "min" : dateFrom
                 });
            }
            if(dateTo != ""){
                  jQuery("#fromAccreditation").attr({
                  "max" : dateTo,
               });
            }
          break;

      }
    }

    //Rango de fechas (validación)
    dateChange(e, filtroFecha:string){

      switch (filtroFecha) {
        case "creacion":
          this.dateCreacionIsSelected = true;
          if(e.target.value == ""){
            //Remueve la validación
            this.desdeCreacion.clearValidators();
            this.hastaCreacion.clearValidators();
            //Actualiza
            this.desdeCreacion.updateValueAndValidity();
            this.hastaCreacion.updateValueAndValidity();
          }else{
            //Añade validación
            this.desdeCreacion.setValidators([Validators.required]);
            this.hastaCreacion.setValidators([Validators.required]);
            //Actualiza
            this.desdeCreacion.updateValueAndValidity();
            this.hastaCreacion.updateValueAndValidity();
          }
          break;
        case "pago":
          this.datePaymentIsSelected = true;
          if(e.target.value == ""){
            //Remueve la validación
            this.desdePago.clearValidators();
            this.hastaPago.clearValidators();
            //Actualiza
            this.desdePago.updateValueAndValidity();
            this.hastaPago.updateValueAndValidity();
          }else{
            //Añade validación
            this.desdePago.setValidators([Validators.required]);
            this.hastaPago.setValidators([Validators.required]);
            //Actualiza
            this.desdePago.updateValueAndValidity();
            this.hastaPago.updateValueAndValidity();
          }
          break;
        case "acreditacion":
          this.dateAcreditacionIsSelected = true;
          if(e.target.value == ""){
            //Remueve la validación
            this.desdeAcreditacion.clearValidators();
            this.hastaAcreditacion.clearValidators();
            //Actualiza
            this.desdeAcreditacion.updateValueAndValidity();
            this.hastaAcreditacion.updateValueAndValidity();
          }else{
            //Añade validación
            this.desdeAcreditacion.setValidators([Validators.required]);
            this.hastaAcreditacion.setValidators([Validators.required]);
            //Actualiza
            this.desdeAcreditacion.updateValueAndValidity();
            this.hastaAcreditacion.updateValueAndValidity();
          }
          break;
      }
    }
}
export interface Cliente{
  id:number;
  name:string;
}
