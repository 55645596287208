import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListaCliente } from '../movimientos/consulta/consulta.component';
import { Observable } from 'rxjs';
import { ArchivoProcesadoService } from 'src/app/services/archivo-procesado.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-cobranzas',
  templateUrl: './cobranzas.component.html',
  styleUrls: ['./cobranzas.component.scss']
})
export class CobranzasComponent implements OnInit {

  public loading:boolean;
  public configTable: any;
  dataSource:any[];
  public pageNumberMov:any;
  public totalElements:number;
  totalPages:number;
  public responseArchivos:any[];
  consultaForm!: FormGroup;
  public listaClientes;
  array = Array<any>();
  related:any;
  clientes$: Observable<ListaCliente[]>;
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;
  constructor( private archivoService: ArchivoProcesadoService,private datePipe:DatePipe,
                private formBuilder: FormBuilder,private apiService: ApiService,
                private _functionsService: FunctionsService,private currencyPipe: CurrencyPipe) {
    this.consultaForm = this.formBuilder.group({
      cliente: [''],
      numeroFactura:[''],
      numeroDocumento:['']
    });
   }
  async ngOnInit(): Promise<void> {
    
    let headers = {
      columns_show:  ['Cliente','Servicio','Apellido y nombre', 'Razón social','Nº de DNI', 'Nº de factura','Nº de movimiento'],
      columns_search:  ["nombre_legal","servicio","apellido_nombre","razon_zocial","numeroDocumento","numeroFactura","solicitud_pago"],
    }
    this.configTable = {
      headers: headers,
      exportToExcel: false,
      verDetallesTarjeta:false,
      buscar:true,
      verDetalles:false,
      functionUsuarios:false,
      functionBlacklist:false,
      
    }

    this.loading = true;
    this.pageNumberMov = 1;
    this.cargarTabla(1,10);

    await this.apiService.getClientes()
      .then((response) =>{
        this.listaClientes = response;
        
        for (let i = 0; i < this.listaClientes.length; i++) {
            let dato: any = {
              id:this.listaClientes[i].id,
              name:this.listaClientes[i].razon_social
            }
            this.array.push(dato);
        }
      
        //console.log(response);
        // this._functionsService.imprimirMensaje(this.listaClientes, "Clientes: ");
      })
      .catch(()=>{
        this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los clientes`, "error", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire();
      });
      this.clientes$ = this.consultaForm.controls.cliente.valueChanges.pipe(
        startWith(null),
        map(
          text => text? 
            this.array.filter(
              country => 
                String(country.name).toLowerCase().includes(text.toLowerCase())
            ) :
            this.array
        )
      );

  }

  cargarTabla(page: number, pageSize: number){
    this.apiService.getCobranzas(page-1,pageSize,this.consultaForm).subscribe((response : any) => {
      this.responseArchivos = response?.content;
      this.totalElements = response.totalElements;
      this.totalPages = response.totalPages;
      this.preparardataSource();
      this.loading = false;   
    },

    
    (error)=>{
      this.dataSource = error;
      this.loading = false;
      console.log("ERROR EN TABLA ARCHIVOS PROCESADOS", error);
      //Limpia la consola
      console.clear();
       
    });
  }

  preparardataSource(){
    var array = Array<any>();
    var nodisponible = "No disponible";
   
    for (let i = 0; i < this.responseArchivos.length; i++) {
     let nombre_apellido = this.responseArchivos[i].nombre +" " + this.responseArchivos[i].apellido
      var dato: any;
          dato = {
            nombre_legal:this.responseArchivos[i].nombreLegal  != null? this.responseArchivos[i].nombreLegal: "-",
          //  nombre: this.responseArchivos[i].nombre_archivo  != null? this.responseArchivos[i].nombre_archivo: "-",
            servicio: this.responseArchivos[i].servicioDescripcion != null? this.responseArchivos[i].servicioDescripcion: "-",
            apellido_nombre: nombre_apellido != null? nombre_apellido : "-",
            razon_zocial:  this.responseArchivos[i].razonSocial  != null? this.responseArchivos[i].razonSocial: "-",
            numeroDocumento: this.responseArchivos[i].numeroDocumento  != null? this.responseArchivos[i].numeroDocumento: "-",
            numeroFactura: this.responseArchivos[i].numeroFactura  != null? this.responseArchivos[i].numeroFactura: "-",
            solicitud_pago: this.responseArchivos[i].idSolicitudPago  != null? this.responseArchivos[i].idSolicitudPago: "-"
          }
      array.push(dato);
    }
    
    this.dataSource = array;
  }


  select(selected: any) {
    this.pageNumberMov = 1;
    this.related = this.array.filter(cliente => cliente.name == selected.option.value);
    this.consultaForm.value.cliente = this.related[0].id;
    this.cargarTabla(1,10);
   }

   nuevaSolicitud(page:any){
    this.pageNumberMov = page.pageNumber;
    this.cargarTabla(page.pageNumber, page.pageSize);
  }
  selectArchivo(){
    if(this.related){
     this.consultaForm.value.cliente = this.related[0].id;
    }
   this.cargarTabla(1,10);
   
  }

  busqueda(){
    this.cargarTabla(1,10);
  }

}
