// import { StorageService } from '../services/storage.service';

const enum StorageType {
  Local,
  Session,
}

const isNull = (item) => item === null || item === undefined;

// service commented out for demonstration purposes
export function Storage<Type>(
  key: string,
  defaultValue: Type = null,
  storageType: StorageType = StorageType.Session
): Function {
  return (target: object, propName: string) => {
    let _val: Type = target[propName];
    let storage = sessionStorage;

    if (storageType === StorageType.Local) {
      storage = localStorage;
    }

    Object.defineProperty(target, propName, {
      get(): Type | unknown {
        if (!isNull(_val)) {
          return _val;
        }
        
        let item = JSON.parse(storage.getItem(key));
        // TODO: Luego de obtener el valor, vamos a tener que desencriptarlo
        if (isNull(item)) {
          item = defaultValue;
          _val = defaultValue;
          storage.setItem(key, JSON.stringify(item));
        }

        return item;
      },
      set(item: Type): void {
        _val = item;
        // TODO: Encriptar todas las directas que vienen
        storage.setItem(key, JSON.stringify(item));
      },
    });
  };
}
