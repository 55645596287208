import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IClientPersonalData } from '../../types/clientes.type';
import { ClientPersonalData } from '../../models/ClientPersonalData.model';
import Swal from 'sweetalert2';
import { CommonsService } from 'src/app/core/commons.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cliente-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss'],
})
export class DatosPersonalesComponent implements OnInit, OnChanges {
  @Input() datosPersonales: IClientPersonalData | null = null;
  @Output() datosPersonalesEmitter: EventEmitter<IClientPersonalData> | null =
    null;

  personalDataFormGroup: FormGroup = this.makeFormGroup(this.datosPersonales);

  constructor(
    private _formBuilder: FormBuilder,
    private _Commons: CommonsService,
    private router: Router
  ) {
    this.datosPersonalesEmitter = new EventEmitter<IClientPersonalData>();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.personalDataFormGroup = this.makeFormGroup(this.datosPersonales);
  }

  ngOnInit(): void {}

  /**
   * Genera el formGroup adecuado en caso de que exista informacion o este vacio
   */
  makeFormGroup(data: IClientPersonalData = null) {
    const formData = new ClientPersonalData(data);

    return (this.personalDataFormGroup = this._formBuilder.group({
      razon_social: [formData.razon_social, Validators.required],
      nombre_legal: [formData.nombre_legal, Validators.required],
      email: [formData.email, [Validators.required, Validators.email]],
      domicilio: [formData.domicilio],
      documento: [formData.documento],
      web: [formData.web],
      localidad: [formData.localidad],
    }));
  }

  /**
   * Metodo que revisa especialmente si un string es una entrada CUIT/CUIL
   * aprobable
   */
  cuitCuilValidator(cuitCuilInput: FormControl) {
    const isValidFunction = this._Commons.isValidCUITCUIL.bind(this);
    // const documento = this.personalDataFormGroup.get('documento').value;
    if (isValidFunction(cuitCuilInput.value ?? '')) {
      cuitCuilInput.setErrors({ notCuil: true });
      this.personalDataFormGroup.get('documento').setErrors({ notCuil: true });
    } else {
      cuitCuilInput.setErrors(null);
      this.personalDataFormGroup.get('documento').setErrors(null);
    }
  }

  /**
   * Maneja el formGroup completo de datos personales
   */
  submitFormGroup(form: FormGroup) {
    if (form.valid) {
      if (
        // Si existen datos en el CUIT seran validados
        // Caso contrario el CUIT no es mandatorio
        form.value['documento'].length > 0 &&
        !this._Commons.isValidCUITCUIL(form.value['documento'])
      ) {
        Swal.fire(
          'CUIT/CUIL INVALIDO',
          'Por favor, revise los datos e intente nuevamente. <br><br> En caso de no ser necesario, el CUIT/CUIL es opcional'
        );
      }
      this.datosPersonalesEmitter.emit(new ClientPersonalData(form.value));
    } else {
      Swal.fire(
        'Campos invalidos',
        'Por favor revise los campos marcados y vuelva a intentarlo nuevamente',
        'error'
      );
    }
  }

  backToUsuariosHome() {
    this.router.navigate(['dashboard/clientes']);
  }
}
