import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PaymentFileService } from '../payment-file.service';

@Component({
  selector: 'app-dialog-trigger-file',
  templateUrl: './dialog-trigger-file.component.html',
  styleUrls: ['./dialog-trigger-file.component.scss']
})
export class DialogTriggerFileComponent implements OnInit {

  public name_cliente: string;
  formGenerar!: FormGroup;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public node: any,
    public dialogRef: MatDialogRef<DialogTriggerFileComponent>,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _paymentFileService:PaymentFileService
  ) { }

  ngOnInit(): void {
    this.date();
  }

  date(){
    this.name_cliente = this.node.name;
    this.formGenerar = this.formBuilder.group({
      id: [this.node.id],
      fecha:['',[Validators.required] ],
    });
  }

  submit(){
    if (this.formGenerar.get('fecha') && this.formGenerar.get('fecha').value){
      const fechaOriginal = new Date(this.formGenerar.value.fecha);
      const fechaFormateada = this.datePipe.transform(fechaOriginal, 'yyyy-MM-dd');
      this.formGenerar.value.fecha = fechaFormateada;
    }
    Swal.fire({
      title: `¿Esta seguro de generar la el archivo de pago para el cliente ${this.name_cliente}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar!'
    }).then((result) => {
        if (result.isConfirmed) {
          this._paymentFileService.generatefile(this.formGenerar.value.id,this.formGenerar.value.fecha).subscribe({
            next: (result: string) => {
              //Swal.fire(`Se ha generado el Archivo de pagos correspondiente al día ${this.formGenerar.value.fecha} y ha sido enviado a la dirección de correo electrónico del cliente registrada en nuestro sistema`, 'success');
              Swal.fire({
                position: "center",
                icon: "success",
                title: `Se ha generado el Archivo de pagos correspondiente al día ${this.formGenerar.value.fecha} y ha sido enviado a la dirección de correo electrónico del cliente registrada en el sistema`,
                showConfirmButton: true,
                timer: 3500
              });
              this.dialogRef.close();
            },
            error: ({ error }: { error: string }) => {
              Swal.fire('Error al generar rendición', 'error');
              console.log("error al generar rendición",error);
            },
          });
        }
    });

  }

}
