<div *ngIf="datosTabla" class="row">
    <div class="col-lg-4 col-md-6 col-sm-12">
        <app-buscador (valorBusqueda)="filtrar($event)"></app-buscador>
    </div>
    <div class="col-lg-4 col-md-2 col-sm-12 mb-lg-0 mb-md-3 mb-sm-3 auto">
        <app-number-pagination (cantidad)="cargarItems($event)"></app-number-pagination>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 mb-lg-0 mb-md-3 mb-sm-3 auto">
        <app-enviar-comentario></app-enviar-comentario>
    </div>
</div>


<app-tabla-resultados></app-tabla-resultados>