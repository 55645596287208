import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Role } from 'src/app/models/role.models';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  direction = "";
  url = "";
  urlExcel : any;
  version = 'N/A';

  constructor(
    private router: Router, 
    private authService: AuthService,
    private activateRoute: ActivatedRoute,
    private tokenService:TokenService,
    private _api:ApiService
    ) { 
      this.version = environment.version;
    }

  get isAuthorized() {
    return this.authService.isAuthorized();
  }

  get isAdmin() {
    return this.authService.hasRole(Role.ROLE_ADMIN);
  }

  cambioUrl(url: string){
    this.router.navigate([url])
    this.url = url;
  }

  logout() {
    // this.authService.logout();
    this.tokenService.logOut();
    this.router.navigate(['login']);
  }


   
}
