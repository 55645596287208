import { Component, HostListener, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ICON } from 'src/app/constants/Icons';
import { LOGOS } from 'src/app/constants/Logos';
import { Router } from '@angular/router';
import {
  SidebarHandlerService,
  iconDirection,
  viewPortMode,
} from './services/sidebar-handler.service';
import { TokenService } from 'src/app/services/token.service';
import { NavigationHandlerService } from './services/navigation-list-handler.service';
import { INavigationNode } from './types/nav-node.type';

@Component({
  selector: 'side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: [
    trigger('changeWidth', [
      state('initial', style({ width: '50px' })),
      state('initial:responsive', style({ width: '0px' })),
      state('fullfilled', style({ width: '320px' })),
      transition('* <=> *', animate('300ms ease-in')),
    ]),
    trigger('onOff', [
      transition(':enter', [
        style({
          width: '0px',
        }),
        animate('300ms ease-in-out'),
      ]),
      transition(':leave', [
        style({
          width: '280px',
        }),
        animate('300ms ease-in-out'),
      ]),
    ]),
  ],
})
export class SideNavigationComponent implements OnInit {
  // Variables de la animacion
  public widthState: viewPortMode;
  public viewportState: viewPortMode = viewPortMode.FULLFILLED;
  public direction: iconDirection = iconDirection.LEFT;

  // Configuraciones de la vista
  public navigationSidebarList = SidebarHandlerService.SidebarNavigationList;
  public hamburgerIcon = ICON.MENU_BURGER;
  public helipagosLogo = LOGOS.HELIPAGOS_SQUARED;

  // Sidebar detalle
  navigationDetailSidebar: INavigationNode[] = null;
  navigationDetailTitle: string = null;

  constructor(
    private router: Router,
    private _Token: TokenService,
    private _NavHandler: NavigationHandlerService,
  ) {
    this.widthState = viewPortMode.FULLFILLED;
    this.direction = iconDirection.LEFT;
  }

  onToggleSidebarVisible() {
    const [initial, fullfilled] = SidebarHandlerService.widthStates();
    this.widthState = this.widthState.includes('initial')
      ? fullfilled
      : initial;
    this.reviewDirection();
  }

  // Actualizar el ancho inicial cuando cambie el tamaño de la pantalla
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    const { viewportState, widthState } =
      SidebarHandlerService.handleAnimationReset(this.viewportState);
    this.widthState = widthState;
    this.viewportState = viewportState;
    this.reviewDirection();
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
    const navNode = this._NavHandler.findNodeByHref(
      this.navigationSidebarList,
      url
    );
    this.navigationDetailSidebar =
      navNode.children && navNode.children.length > 0 ? navNode.children : null;
    this.navigationDetailTitle = navNode.title;
  }

  logOut() {
    this._Token.logOut();
  }

  reviewDirection() {
    this.direction =
      this.widthState === viewPortMode.FULLFILLED
        ? iconDirection.LEFT
        : iconDirection.RIGHT;

    return this.direction === iconDirection.LEFT
      ? ICON.CHEVRON_LEFT
      : ICON.CHEVRON_RIGHT;
  }

  ngOnInit(): void {}
}
