<section class="section">
    <h3 class="header">Modificar blacklist</h3>
  
    <form class="form-container" [formGroup]="formNuevo"  autocomplete="off">
        <div class="row ">
            <div class="col-md-6 col-sm-12 mt-3">
                <div class="mb-3 form-email">
                    <label for="name" class="form-label">Apellido y nombre</label>
                    <input  formControlName="name" type="text" class="form-control" placeholder= "Ingresar nombre"  id="name" autocomplete="off">
                </div>
             <!-- <div *ngIf="formNuevo.controls['name'].invalid && (formNuevo.controls['name'].dirty || formNuevo.controls['name'].touched)"
                    class="validacion-error">
                    <div *ngIf="formNuevo.controls['name'].errors.required">
                        Nombre Requerido.
                    </div>
                </div>   -->
            </div>
            <div class="col-md-6 col-sm-12 mt-3">
                <div class="mb-3 form-dni">
                    <label for="dni" class="form-label">DNI</label>
                    <input  formControlName="dni" type="text" class="form-control" placeholder= "Ingresar dni" mask="00.000.000" id="dni" autocomplete="off" (keypress)="validateFormat($event)">
                </div>
                <!-- <div *ngIf="formNuevo.controls['dni'].invalid && (formNuevo.controls['dni'].dirty || formNuevo.controls['dni'].touched)"
                    class="validacion-error">
                    <div *ngIf="formNuevo.controls['dni'].errors.required">
                        Dni Requerido.
                    </div>
                </div>   -->
            </div>
            <div class="col-md-6 col-sm-12 mt-3">
                <div class="mb-3 form-direccion">
                    <label for="domicilio" class="form-label">Dirección</label>
                    <input  formControlName="domicilio" type="text" class="form-control" placeholder= "Ingresar domicilio"  id="direccion" autocomplete="off">
                </div>
                 <!-- <div *ngIf="formNuevo.controls['domicilio'].invalid && (formNuevo.controls['domicilio'].dirty || formNuevo.controls['domicilio'].touched)"
                    class="validacion-error">
                    <div *ngIf="formNuevo.controls['domicilio'].errors.required">
                        Direccion Requerida.
                    </div>
                </div>   -->
            </div>
            <div class="col-md-6 col-sm-12 mt-3">
                <div class="mb-3 form-tarjeta">
                    <label for="tarjeta" class="form-label">Tarjeta</label>
                    <input  formControlName="tarjeta" type="text" class="form-control" placeholder= "Ingresar tarjeta"  id="tarjeta" autocomplete="off">
                </div>
                 <!-- <div *ngIf="formNuevo.controls['tarjeta'].invalid && (formNuevo.controls['tarjeta'].dirty || formNuevo.controls['tarjeta'].touched)"
                    class="validacion-error">
                    <div *ngIf="formNuevo.controls['tarjeta'].errors.required">
                        Tarjeta Requerida.
                    </div>
                </div>   -->
            </div>
            <div class="col-md-6 col-sm-12 mt-3">
                <div class="mb-3 form-telefono">
                    <label for="telefono" class="form-label">Telefono</label>
                    <input  formControlName="telefono" type="text" class="form-control" placeholder= "Ingresar telefono"  id="telefono" autocomplete="off" (keypress)="validateFormat($event)">
                </div>
             <!-- <div *ngIf="formNuevo.controls['telefono'].invalid && (formNuevo.controls['telefono'].dirty || formNuevo.controls['telefono'].touched)"
                    class="validacion-error">
                    <div *ngIf="formNuevo.controls['telefono'].errors.required">
                        Telefono Requerido.
                    </div>
                </div>   -->
            </div>
            <div class="col-md-6 col-sm-12 mt-3">
                <div class="mb-3 form-email">
                    <label for="email" class="form-label">Email</label>
                    <input formControlName="email" type="email" class="form-control" placeholder= "Ingresar email" id="email" autocomplete="off" email>
                </div>
                <!-- <div *ngIf="formNuevo.controls['email'].invalid && (formNuevo.controls['email'].dirty || formNuevo.controls['email'].touched)"
                    class="validacion-error">
                    <div *ngIf="formNuevo.controls['email'].errors.required">
                        Email Requerido.
                    </div>
                    <div *ngIf="formNuevo.controls['email'].errors.email">
                        El email no es válido.
                    </div>
                </div>   -->
            </div>
        </div>
      
          <div class="btn-container">
            <button (click)="accionable()" class="btn volver">
              Volver
            </button>
            <button
              type="submit"
              (click)="submit()"
              class="btn guardar"
    >
              Guardar
            </button>
          </div>
    </form>
  </section>
