<section>
    <article >
        <!-- TODO: listado-cliente-detalle -->
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput type="text" [value]="cliente.email" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Archivo de pago</mat-label>
          <input matInput type="text"  [value]="cliente.archivo_pago" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Archivo de caja</mat-label>
          <input matInput type="text"  [value]="cliente.archivo_caja"  readonly />
        </mat-form-field>
        <!-- <mat-form-field class="example-form-field">
          <mat-label>Nombre de usuario</mat-label>
          <input matInput type="text" [value]="cliente.username" readonly>
        </mat-form-field> -->
        <mat-form-field>
          <mat-label>Rendición</mat-label>
          <input matInput type="text" [value]="cliente.rendicion" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Liquidación</mat-label>
          <input matInput type="text" [value]="cliente.liquidacion" readonly />
        </mat-form-field>
      </article>
      
      <div class="detail-panel">
        <button class="btn-deshabilitar" *ngIf="cliente.enabled=='Habilitado'"  (click)="enableClientFile(cliente.id, cliente.enabled,cliente.razon_social)" matTooltip="Deshabilitar cliente"> 
          <img  src="../../../assets/img/enabled_false_cleinte.svg">
        </button>
        <button class="btn-habilitar" *ngIf="cliente.enabled=='Deshabilitado'"  (click)="enableClientFile(cliente.id, cliente.enabled,cliente.razon_social)" matTooltip="Habilitar cliente"> 
          <img  src="../../../assets/img/enabled_true_cliente.svg">
        </button>
        <button class="btn-habilitar"  matTooltip="Editar" (click)="openDialogEdit(cliente.id)" > 
          <img  src="../../../assets/img/editar_datos.svg">
        </button>
        <button [disabled]="cliente.enabled === 'Deshabilitado'"  [ngClass]="{'btn-archivo-generar': (cliente.enabled=='Habilitado'),'btn-archivo-bloquear': (cliente.enabled=='Deshabilitado')}"  (click)="openDialogGenerar(cliente.razon_social,cliente.id)" matTooltip="Generar archivo"> 
          <img  src="../../../assets/img/generar_archivo.svg">
        </button>
        <button mat-raised-button color="primary" (click)="openDialogGenerico(cliente.razon_social,cliente.id,'Liquidación')"> Generar Archivo liquidación</button>
        <button mat-raised-button color="warn" (click)="openDialogGenerico(cliente.razon_social,cliente.id,'Rendición')">Generar Archivo rendición</button>
      </div>
      
</section>
