import { SimpleChanges } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Location } from '@angular/common';
import { SearchPipe } from 'src/app/pipes/search.pipe';
import { FunctionsService } from 'src/app/services/functions.service';

@Component({
  selector: 'app-tabla, helipagos-table',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css'],
})
export class TablaComponent implements OnInit, OnChanges {
  @Input() displayedColumns: any = {};
  @Input() displayedColumnsSearch: any = {};
  @Input() dataSource: any = [];
  @Input() mensajeResultado: any;
  //Funciones
  @Input() showVerDetalles: boolean;
  @Input() functionUsuarios: boolean;
  @Input() functionCliente: boolean;
  @Input() indiceItems: boolean = false;
  @Input() btnExportarCSV: boolean = false;
  // @Input() exportToCSV:boolean = false;
  @Input() exportToExcel: boolean = false;

  //Filtro
  @Input() searchText: string = '';
  @Input() searching: boolean = true;

  @Output() verDetallesFunction: EventEmitter<number>;
  @Output() buttonToken: EventEmitter<number>;
  @Output() editUsuario: EventEmitter<number>;
  @Output() eliminarUsuario: EventEmitter<number>;
  @Output() volverAtras: EventEmitter<any>;
  @Output() emitExportExcel: EventEmitter<number>;

  public arrayAux: any = []; //guarda el dataSource (no afecta a la integridad)
  public asc: boolean = false;

  constructor(
    private _functionsService: FunctionsService,
    private location: Location
  ) {
    this.verDetallesFunction = new EventEmitter();
    this.buttonToken = new EventEmitter();
    this.editUsuario = new EventEmitter();
    this.eliminarUsuario = new EventEmitter();
    this.volverAtras = new EventEmitter();
    this.emitExportExcel = new EventEmitter();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchText) {
      if (this.arrayAux == '' || this.arrayAux == null) {
        this.arrayAux = this.dataSource; //guarda el dataSource completo

        // this.arrayAux2 = this.dataSource;
      }
      var filtro = new SearchPipe();
      this.dataSource = filtro.transform(this.arrayAux, this.searchText);
      if (this.searchText == '') {
        this.dataSource = this.arrayAux;
      }
    }
    //
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'btnExportarCSV': {
            this.btnExportarCSV = true;
          }
        }
      }
    }
  }

  Volver() {
    //Vuelve atrás
    this.location.back();
    //emite un evento "Volver"
    this.volverAtras.emit(true);
  }

  verDetalles(idx: number) {
    this.verDetallesFunction.emit(idx);
  }

  modalButtonToken(idx: number) {
    this.buttonToken.emit(idx);
  }

  modalEdit(idx: number) {
    this.editUsuario.emit(idx);
  }

  modalDelete(idx: number) {
    this.eliminarUsuario.emit(idx);
  }
  // emitirExportarCSV(){
  //   this.emitExportCSV.emit(true);
  // }
  emitirExportarExcel(id: number) {
    this.emitExportExcel.emit(id);
  }

  //Ordenar resultados de la tabla
  sort(head: any) {
    this.dataSource.sort((a, b) => {
      var fa = a[head],
        fb = b[head];

      // Si es dinero
      if (isNaN(fa) && fa.charAt(0) == '$') {
        if (!this.asc) {
          return parseExpr(fb) - parseExpr(fa);
        } else {
          return parseExpr(fa) - parseExpr(fb);
        }
      } else {
        if (!this.asc) {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
        } else {
          if (fa < fb) {
            return 1;
          }
          if (fa > fb) {
            return -1;
          }
        }
      }
      return 0;
    });

    function parseExpr(exp) {
      return (
        (exp[0] == '<' ? -1 : 1) + parseInt(exp.replace(/[.,]/g, '').substr(1))
      );
    }
  }
}
