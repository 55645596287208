class UserTerminalRequestModel {

  user_id: number;
  terminal_id: number;

  constructor(userId: number | null = null, terminalId: number | null = null) {
    this.user_id = userId;
    this.terminal_id = terminalId;
  }
}

export default UserTerminalRequestModel;

