<h1 mat-dialog-title>Nueva terminal</h1>
<div mat-dialog-content>
  <form class="group-example-form" [formGroup]="newTerminalFormGroup">
<!--    NOTE: We can make a dynamic form for this -->
    <mat-form-field [appearance]="'outline'">
      <mat-label>SN</mat-label>
      <input matInput placeholder="N78802797979" formControlName="sn">
      <mat-hint>Serial number de la terminal</mat-hint>
      <mat-error *ngIf="formgroupcontrols.sn.hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
      <mat-error *ngIf="formgroupcontrols.sn.errors && formgroupcontrols.sn.errors.minlength">
        El SN debe tener al menos 10 caracteres
      </mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="'outline'">
      <mat-label>Prisma ID</mat-label>
      <input matInput placeholder="380000000" formControlName="prisma_id">
      <mat-hint>ID de la terminal en Prisma</mat-hint>
      <mat-error *ngIf="formgroupcontrols.prisma_id.hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
      <mat-error *ngIf="formgroupcontrols.prisma_id.errors && formgroupcontrols.prisma_id.errors.minlength">
        El SN debe tener al menos 10 caracteres
      </mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="'outline'">
      <mat-label>Marca</mat-label>
      <input matInput placeholder="NEWLAND" formControlName="marca">
      <mat-hint>De la terminal</mat-hint>
      <mat-error *ngIf="formgroupcontrols.marca.hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="'outline'">
      <mat-label>Modelo</mat-label>
      <input matInput placeholder="N95" formControlName="modelo">
      <mat-hint>De la terminal</mat-hint>
      <mat-error *ngIf="formgroupcontrols.modelo.hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
  </form>
  <br>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-stroked-button color="primary" (click)="onNoClick()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="onClicked()" cdkFocusInitial>Asociar</button>
</div>
