<article class="animated fadeIn fast">
  <div class="title">
    <h1>
      Liquidaciones 
    </h1>
    <div class="acciones">
      <button class="btn-accion" (click)="toggleFilters()">Filtros</button>
      <button class="btn-accion"  (click)="openDialog()">Generar Liquidación</button> 
    </div>
  </div>
  
  <consulta
    @onOff
    *ngIf="showFilters"
    (submitEmitter)="searchHandler($event)"
  ></consulta>
  <resultados [filtros]="filtros" (cleanFilters)="resetFilters()"></resultados>
</article>
