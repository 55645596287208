import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  private endpoint = `${environment.endpoint}/api`;
  uuidRegExp: RegExp =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  userKeyRegExp = /^(?=.*[A-Z])(?=.*[0-9a-zA-Z]).{8,70}$/;

  constructor() {}

  /**
   * getURL
   * @param args Cualquier argumento que llegue como string sera convertido a una URL utilizable
   * @returns string endpoint
   */
  private getURL(...args: string[]): string {
    return this.endpoint + '/' + args.join('/');
  }

  /**
   * getQueries
   * @param queries un objeto de cualquier tipo
   * @returns Un string en formato queryParams con los valores no nulos que pudieron ser recuperados
   */
  private getQueries(queries: any): string {
    let queryString = '';
    for (const key in queries) {
      if (queries.hasOwnProperty(key)) {
        if (queryString !== '') {
          queryString += '&';
        }
        queryString += key + '=' + queries[key];
      }
    }
    return '?' + queryString;
  }
  private getQuerie(queries: any): string {
    let queryString = '';
    for (const key in queries) {
      if (queries.hasOwnProperty(key)) {
        if (queryString !== '') {
          queryString += '&';
        }
        queryString += key + '=' + queries[key];
      }
    }
    return  queryString;
  }

  /**
   * Herramienta que permite hacer mas sencillo la creacion de una URL, especialmente pensado para GET
   * @param query
   * @param args
   * @returns
   */
  getUri(query: any, ...args: string[]): string {
    return this.getURL(...args) + this.getQueries(query);
    //     console.log(this.getUri({email: 'vader@darkside.com'},'users', 'reset', 'reset-password'));
  }
  getUr(query: any, ...args: string[]): string {
    return this.getURL(...args) + this.getQuerie(query);
    //     console.log(this.getUri({email: 'vader@darkside.com'},'users', 'reset', 'reset-password'));
  }
}
