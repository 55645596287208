import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module'

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}