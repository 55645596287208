<app-spinner *ngIf="loading"></app-spinner>
<div class="title">
    <h1>Blacklist</h1>
    <!-- <button (click)="mofificarBlackList()">prueba </button> -->
</div>
<div class="content animated fadeIn">
    <div class="card-header">
        <div class="col-12">
            <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#crearUsuario" class="btn btn-green">Nuevo Usuario</button> -->
            <button type="button" class="btn btn-green" (click)="open(nuevo)">Nuevo</button>
        </div>
    </div>
    <app-tabla-resultados (editUsuario)="modalEditUsuario($event)"  (eliminarUsuario)="modalDeleteUsuario($event)"  [configTable]="configTable" [data]="dataSource" [totalElements]="totalElements" [pageNumber]="pageNumberMov" (eventGetPage)="nuevaSolicitud($event)"></app-tabla-resultados>
</div>
<ng-template #nuevo let-modal>
    <app-blacklist-new (propagarUser)="onPropagarUser($event)"
        [accionable]="cerrarModal.bind(this)">
    </app-blacklist-new>
</ng-template>

 <ng-template #editar let-modal>
    <app-blacklist-update (propagarUser)="onPropagarUser($event)"
        [accionable]="cerrarModal.bind(this)"  [listId]="listId">
    </app-blacklist-update>
</ng-template> 


