import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { LocalService } from 'src/app/services/storage/local.service';
import { ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { PaginationInstance } from 'ngx-pagination';
import { SearchPipe } from 'src/app/pipes/search.pipe';
import { LiqTransferenciaEmitter } from 'src/app/models/liquidacion/extras';
import { CommonsService } from 'src/app/core/commons.service';
import {ActionButton, ProductionConfigTableInterface} from './production-config-table.interface';

declare var $: any;

interface FieldButtonDataType<T> {
  data: T;
  flag: string;
}

@Component({
  selector: 'app-tabla-resultados, helitable',
  templateUrl: './tabla-resultados.component.html',
  styleUrls: ['./tabla-resultados.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TablaResultadosComponent implements OnInit {
  public loading: boolean = false;
  public mensajeResultado: string = '';
  public asc: boolean = false;
  public arrayAux: any = []; // guarda el dataSource (no afecta a la integridad
  public total: number = 0;

  // Props
  @ViewChild('failSwal') failSwal!: SwalComponent;
  @ViewChild('sesionSwal') sesionSwal!: SwalComponent;
  @Output() emitDescargaPDF: EventEmitter<{ id: number; id_cliente: number }>;
  @Output() emitExportExcel: EventEmitter<number>;
  @Output() emitExportExcelLiq: EventEmitter<{
    id: number;
    id_cliente: number;
  }>;
  @Output() eventGetPage: EventEmitter<any> = new EventEmitter();
  @Output() verDetallesOutput: EventEmitter<any> = new EventEmitter();
  @Output() emitTranferirMonto: EventEmitter<LiqTransferenciaEmitter>;
  @Output() emitConciliarVista: EventEmitter<{ id: number; estado: string }>;
  @Output() emitIDTarjeta: EventEmitter<number>;
  @Output() emitResetSearch = new EventEmitter<void>();
  @Output() editUsuario: EventEmitter<number>;
  @Output() buttonPanelEmitter = new EventEmitter<any>();
  @Output() eliminarUsuario: EventEmitter<number>;
  @Output() emitEnableClientFile: EventEmitter<{
    id: number;
    enabled: string;
    name:string;
  }>;
  @Output() emitGenerarFile: EventEmitter<{
    name:string;
    id: number;
  }>;
  @Input() buttonsPanel: ActionButton[] = [];
  @Input() needsBackButton = false; // For the unupdated views, later the button will be deleted
  @Output() emitGenerarFileEdit: EventEmitter<number>;
  // TO later research
  @Output() emitter: EventEmitter<FieldButtonDataType<any>> = new EventEmitter<FieldButtonDataType<any>>();

  // Variables del componente
  public pagingSettings: PaginationInstance = {
    id: 'server',
    itemsPerPage: 10,
    currentPage: 0,
    totalItems: 0,
  };

  // Datos formateados que recibe de su componente padre
  public _data: any;
  get data() {
    return this._data;
  }

  @Input() set data(val: any) {
    if (val) {
      //Si hay un error
      if (val?.status) {
        this.failSwal.fire().finally(() => this.location.back());
        return;
      }

      this._data = val;
      $('.table-body').removeClass('table-loading');
      this.loading = false;
    }
  }

  private _totalElements: any;
  get totalElements() {
    return this._totalElements;
  }

  @Input() set totalElements(val: any) {
    if (val) {
      this._totalElements = val;
      this.pagingSettings.totalItems = val;
      this.mensajeResultado =
        'Se encontraron ' + this._totalElements + ' resultados';
    }
  }

  private _pageNumber: number = 0;
  get pageNumber() {
    return this._pageNumber;
  }

  @Input() set pageNumber(val: number) {
    if (val) {
      // Actualiza la página
      this.pagingSettings.currentPage = val;
    }
  }

  // Configuración inicial de la tabla
  // tslint:disable-next-line:variable-name
  private _configTable: Partial<ProductionConfigTableInterface>;
  get configTable(): Partial<ProductionConfigTableInterface> {
    return this._configTable;
  }

  @Input() set configTable(val: any) {

    if (val) {
      // Contiene los headers, y opciones de visualización (exportar a excel, ver detalles)
      this._configTable = val;
    }
  }

  constructor(
    private localService: LocalService,
    private location: Location,
    private _Commons: CommonsService
  ) {
    this.emitExportExcel = new EventEmitter();
    this.emitDescargaPDF = new EventEmitter();
    this.emitExportExcelLiq = new EventEmitter();
    this.emitTranferirMonto = new EventEmitter();
    this.emitConciliarVista = new EventEmitter();
    this.emitIDTarjeta = new EventEmitter();
    this.editUsuario = new EventEmitter();
    this.eliminarUsuario = new EventEmitter();
    this.emitEnableClientFile = new EventEmitter();
    this.emitGenerarFile = new EventEmitter();
    this.emitGenerarFileEdit = new EventEmitter();
  }

  ngOnInit(): void {}

  getPage(pageNumber: number, pageSize: string) {
    // Cargando
    this.loading = true;
    $('.table-body').addClass('table-loading');
    // Emite el evento (solicitud a componente padre para solicitud Http)
    const page = {
      pageNumber,
      pageSize,
    };
    this.eventGetPage.emit(page);
    // Actualiza la configuración del paginador
    this.pagingSettings.currentPage = pageNumber;
  }

  emitirClickPanel<T>(event: T, button_id: string): void {
    this.buttonPanelEmitter.emit({ ...event, button_id });
  }

  cargarItems(cantidad: number) {
    //Actualiza la configuración del paginador
    this.pagingSettings.itemsPerPage = cantidad;
    this.getPage(1, cantidad.toString());
  }

  nextOrPreviousPage(pageNumber: number) {
    //Actualiza la configuración del paginador
    this.pagingSettings.currentPage = pageNumber;
    this.getPage(pageNumber, this.pagingSettings.itemsPerPage.toString());
  }

  filtrar(dato: any) {
    console.log(dato);
    if (dato != null) {
      if (this.arrayAux == '' || this.arrayAux == null) {
        this.arrayAux = this._data; //guarda el dataSource completo
      }
      var filtro = new SearchPipe();
      this._data = filtro.transform(this.arrayAux, dato);
      if (dato == '') {
        this._data = this.arrayAux;
      }
    }
    console.log(this._data);
  }

  getLocalStorage() {
    // Get the user data
    let palabra = this.localService.getJsonValue('user');
  }

  //Funciones de la vista
  verDetalles(idx: number, datos: any) {
    this.verDetallesOutput.emit(idx);
  }
  Volver() {
    //Vuelve atrás
    this.location.back();
  }

  removeCharacter(array1: Array<any>) {
    let array2 = array1.map(function (element) {
      for (const property in element) {
        if (typeof element[property] == 'string') {
          if (element[property].charAt(0) == '$')
            element[property] = element[property].substr(2);
        }
      }
      return element;
    });
    return array2;
  }

  //Ordenar resultados de la tabla
  sort(head: any) {
    // REFACTOR ME PLS
    this._data.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
      var fa = a[head],
        fb = b[head];

      // Si es dinero
      if (isNaN(fa) && fa.charAt(0) == '$') {
        if (!this.asc) {
          return parseExpr(fb) - parseExpr(fa);
        } else {
          return parseExpr(fa) - parseExpr(fb);
        }
      } else {
        if (!this.asc) {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
        } else {
          if (fa < fb) {
            return 1;
          }
          if (fa > fb) {
            return -1;
          }
        }
      }
      return 0;
    });

    function parseExpr(exp: string) {
      return (
        (exp[0] === '<' ? -1 : 1) + Number(exp.replace(/[.,]/g, '').substr(1))
      );
    }
  }

  //DESCARGAR RESULTADOS en PDF
  emitirDescargarPDF(id: number, id_cliente: number) {
    this.emitDescargaPDF.emit({ id: id, id_cliente: id_cliente });
  }

  //EXPORTAR RESULTADOS Excel Rendiciones
  emitirExportarExcel(id: number) {
    this.emitExportExcel.emit(id);
  }

  //EXPORTAR RESULTADOS Excel Liquidaciones
  emitirExportarExcelLiq(id: number, id_cliente: number) {
    this.emitExportExcelLiq.emit({ id: id, id_cliente: id_cliente });
  }

  transferirLiq(
    id: number,
    id_cliente: number,
    neto: string,
    estado: string = ''
  ) {
    this.emitTranferirMonto.emit({
      id: id,
      id_cliente: id_cliente,
      neto,
      estado,
    });
  }

  //trae id de a conciliar
  conciliar(id: number, estado: string) {
    this.emitConciliarVista.emit({ id: id, estado: estado });
  }
  //trae id de tarjeta
  verDetallesTarjeta(id: number) {
    this.emitIDTarjeta.emit(id);
  }

  emitNewSearch() {
    this.emitResetSearch.emit();
  }

  clipboard(value: string) {
    this._Commons.clipboardItem(value);
  }

  modalEdit(idx: number) {
    this.editUsuario.emit(idx);
  }

  /**
   * handles data manipulation all over actions
   */
  callbackManipulation<T>(flag: string, data: T): void {
    this.emitter.emit({ flag, data });
  }

  modalDelete(idx: number) {
    this.eliminarUsuario.emit(idx);
  }

  enableClientFile(id:number,enabled:string,name:string){
    this.emitEnableClientFile.emit({id:id,enabled:enabled,name:name});
  }

  openDialogGenerar(name:string,id:number){
    this.emitGenerarFile.emit({id:id,name:name});
  }

  openDialogEdit(id:number){
    this.emitGenerarFileEdit.emit(id);
  }
}
