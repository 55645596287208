import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeARS from '@angular/common/locales/es-AR';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Liquidacion } from 'src/app/models/liquidacion/liquidacion';
import { ApiService } from 'src/app/services/api.service';
import { LiquidacionService } from 'src/app/services/liquidacion.service';

declare var XLSX: any;
@Component({
  selector: 'resultados',
  templateUrl: './liquidacion-resultados.component.html',
  styleUrls: ['./liquidacion-resultados.component.css'],
})
export class LiquidacionResultadosComponent implements OnInit, OnChanges {
  @Input() filtros: any = {}; // TODO: Please a model
  @Output() cleanFilters = new EventEmitter<void>();

  public responseLiquidaciones: any[];
  public rendiciones: any[];
  public valorBusquedaTermino: any;
  public cantidadItems: any;
  itemPerPage: string;
  loading: boolean = true;
  mostrarAccion: boolean = false;
  ////------------------------------------------------------------////
  public dataSource: Liquidacion[];
  public totalElements: number;
  public configTable: any;
  public pageNumberLiq: any;

  constructor(
    private _api: ApiService,
    private _liquidacionesService: LiquidacionService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private _router: Router
  ) {
    registerLocaleData(localeARS, 'es-ARS');
  }

  ngOnInit(): void {
    //Configuración de la tabla (componente hijo)
    this.configTable = {
      headers: this._liquidacionesService.getHeadersLiquidaciones(),
      verDetalles: false,
      tTransferencia: false,
      tLiquidacion: true,
      verDetallesTarjeta: false,
      verGestion: true,
      buscar: true,
      tGestion: true,
    };

    //Página inicial de la consulta
    this.pageNumberLiq = this._liquidacionesService.getPageNumber();
    //Request: solicitud de pago
    this.getLiquidaciones(this.pageNumberLiq, '10');
  }

  ngOnChanges() {
    this.pageNumberLiq = this._liquidacionesService.getPageNumber();
    this.getLiquidaciones(this.pageNumberLiq, '10');
  }

  //Hace la solicitud
  getLiquidaciones(page: number, itemPerPage: string) {
    this._api.getLiquidaciones(page - 1, itemPerPage, this.filtros).subscribe({
      next: (response: any) => {
        this.responseLiquidaciones = response?.content;
        this.totalElements = response.totalElements;

        this.preparardataSource();
        this.loading = false;
      },
      error: (error) => {
        this.dataSource = error;
        //console.log('ERROR EN liquidaciones resultados ', error);
        //Limpia la consola
      
        this.loading = false;
      },
    });
  }

  preparardataSource() {
    var array = Array<Liquidacion>();
    var nodisponible = 'No disponible';

    for (let i = 0; i < this.responseLiquidaciones.length; i++) {
      var distribuida = null;
      if (
        !this.responseLiquidaciones[i].distribuida ||
        this.responseLiquidaciones[i].distribuida == null
      ) {
        distribuida = 'No';
      } else {
        distribuida = 'Sí';
      }

      if (this.responseLiquidaciones[i].created_at < '2022-04-04') {
        this.mostrarAccion = false;
      } else {
        this.mostrarAccion = true;
      }

      var dato: Liquidacion;
      dato = {
        /* Numero */ numero:
          this.responseLiquidaciones[i].id != null
            ? this.responseLiquidaciones[i].id
            : 0,
        /* Cliente */ cliente:
          this.responseLiquidaciones[i].cliente?.razon_social != null
            ? this.responseLiquidaciones[i].cliente?.razon_social
            : '',
        /* Bruto */ importe_total:
          this.responseLiquidaciones[i].importe_total != null
            ? this.currencyPipe.transform(
                this.responseLiquidaciones[i].importe_total / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
            : nodisponible,
        /* Comisión */ importe_comision:
          this.responseLiquidaciones[i].importe_comision != null
            ? this.currencyPipe.transform(
                this.responseLiquidaciones[i].importe_comision / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
            : nodisponible,
        /* Gastos a recuperar */ factura_comision:
          this.responseLiquidaciones[i].importe_gasto_fra != null
            ? this.currencyPipe.transform(
                this.responseLiquidaciones[i].importe_gasto_fra / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
            : nodisponible,
        /* Contracargo */ contracargo:
          this.responseLiquidaciones[i].importe_contracargo != null
            ? this.currencyPipe.transform(
                this.responseLiquidaciones[i].importe_contracargo / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
            : '$ 0,00',//bank_description

            /* Cliente */ banco:
          this.responseLiquidaciones[i].banco?.bank_description != null
          ? this.responseLiquidaciones[i].banco?.bank_description
          : '-',
        /* Fecha */ fecha:
          this.datePipe.transform(
            this.responseLiquidaciones[i].created_at,
            'dd-MM-yyyy'
          ) != null
            ? this.datePipe.transform(
                this.responseLiquidaciones[i].created_at,
                'dd-MM-yyyy'
              )
            : nodisponible,
        /* Distribuida */ distribuida: distribuida,
        /* Rendicion */ //  rendicion: this.responseLiquidaciones[i].rendicion != null?  this.responseLiquidaciones[i].rendicion.id: 0,
        /* Neto */ neto:
          this.responseLiquidaciones[i].importe_liquidado != null
            ? this.currencyPipe.transform(
                this.responseLiquidaciones[i].importe_liquidado / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
            : nodisponible,
        /*id_cliente*/ id_cliente: this.responseLiquidaciones[i].cliente.id,
        /*estado*/ //estado:"TRANSFERIDA",
        detalle: this.mostrarAccion,
        estado: this.responseLiquidaciones[i]['estado']?.estado,
        //comentario para git
      };
      array.push(dato);
    }
    this.dataSource = array;
  }

  filtrar(termino: string) {
    // console.clear();
    this.valorBusquedaTermino = termino;
  }

  cargarItems(cantidad: string) {
    // console.clear();
    this.cantidadItems = cantidad;
  }

  nuevaSolicitud(page: any) {
    this.getLiquidaciones(page.pageNumber, page.pageSize);
  }

  descargarPDF(emitDescargaPDF) {
    let item = null;
    this._liquidacionesService.makePDF(
      emitDescargaPDF.id,
      emitDescargaPDF.id_cliente
    );
  }

  gestionHandler(id: number) {
    this._router.navigate([`dashboard/liquidacion/s/${id}/ver`]);
  }

  emitNewSearch() {
    this.cleanFilters.emit();
  }

  exportarExcel(liqui) {
    this._liquidacionesService.exportarExcel(liqui);
  }
}
