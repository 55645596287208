<form class="grid-gallery" [formGroup]="clienteRegulacionesFormGroup">
  <div class="grid-gallery">
    <!-- Campos del formulario que deben estar de manera obligatoria -->
    <!-- Primeros dos mat-card tienen dos input -->
    <!-- Retenciones de IVA -->
    <mat-card>
      <mat-card-title-group>
        <mat-card-title>Retencion de IVA</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <mat-checkbox color="accent" formControlName="ret_iva" (change)="setCheckbox()">
          Ret. IVA Habilitada
        </mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>Debito</mat-label>
          <input matInput type="text" placeholder="Alicuota" formControlName="alicuota_ret_iva"
             aria-label="Alicuota que sera usada para las retenciones del IVA al debito"  
             (input)="onInputChange($event, 'alicuota_ret_iva')"
             (blur)="onBlur('alicuota_ret_iva')" />
          <mat-hint>Alicuota retenciones de IVA al debito</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Crédito</mat-label>
          <input matInput type="text" placeholder="Alicuota" formControlName="alicuota_ret_iva_credito" 
          (input)="onInputChange($event, 'alicuota_ret_iva_credito')"
          (blur)="onBlur('alicuota_ret_iva_credito')" 
            aria-label="Alicuota que sera usada para las retenciones del IVA al credito" />
          <mat-hint>Alicuota retenciones de IVA al credito</mat-hint>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <!-- Retenciones de Ganancia -->
    <mat-card>
      <mat-card-title-group>
        <mat-card-title>Retencion de ganancias</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <mat-checkbox color="accent" formControlName="ret_ganancia" (change)="setCheckbox()">
          Ret. Ganancias habilitada al cliente
        </mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>Debito</mat-label>
          <input matInput placeholder="Alicuota" type="text" formControlName="alicuota_ret_ganancia"
            (input)="onInputChange($event, 'alicuota_ret_ganancia')"
            (blur)="onBlur('alicuota_ret_ganancia')"
            aria-label="Alicuota que sera usada para las retenciones de anancias al debito" />
          <mat-hint>Alicuota retencion de ganancias al debito</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Credito</mat-label>
          <input matInput type="text" placeholder="Alicuota" formControlName="alicuota_ret_ganancia_credito"
            (input)="onInputChange($event, 'alicuota_ret_ganancia_credito')"
            (blur)="onBlur('alicuota_ret_ganancia_credito')"
            aria-label="Alicuota que sera usada para las retenciones de anancias al credito" />
          <mat-hint>Alicuota retención de ganancias al crédito</mat-hint>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <!-- Segunda seccion, solamente un campo input -->
    <!-- SIRTAC -->
    <mat-card>
      <mat-card-title-group>
        <mat-card-title>Sistema de Recaudación sobre Tarjetas de Crédito y Compra
          (SIRTAC)</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <mat-checkbox color="accent" formControlName="iibb_sirtac" (change)="setCheckbox()">
          IIBB SIRTAC
        </mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>Valor númerico</mat-label>
          <input matInput type="text" placeholder="Alicuota" formControlName="alicuota_sirtac" 
            (input)="onInputChange($event, 'alicuota_sirtac')"
            (blur)="onBlur('alicuota_sirtac')"
            aria-label="Alicuota de cobro para SIRTAC" />
          <mat-hint>Alicuota SIRTAC</mat-hint>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <!-- Otros aparte, no relacionados -->
    <mat-card>
      <mat-card-title-group>
        <mat-card-title>Otros</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <mat-checkbox color="accent" formControlName="agente_ret_iva">
          Soy un agente de retención de IVA
        </mat-checkbox>
       
        <!-- Input numerico -->
        <mat-form-field appearance="fill">
          <mat-label>Semanas de liquidación desde el inicio de mes</mat-label>
          <input matInput type="number" placeholder="Ej: 1,2,3" formControlName="semana_liquidacion"
            aria-label="Semanas pasadas luego del inicio de mes requeridas para la liquidacion" (change)="setOpcionSemana()" />
          <label for="" matSuffix>Semanas</label>
          <mat-hint>Semana/s de liquidación o puede dejarlo en cero</mat-hint>
        </mat-form-field>
         <!-- Input numerico -->
         <mat-form-field appearance="fill">
          <mat-label>Día de liquidación del mes</mat-label>
          <input matInput type="number" placeholder="Ej: 1,2,3" formControlName="dia_liquidacion" (change)="setOpcionDia()"
            aria-label="Día requerida para la liquidacion"/>
          <label for="" matSuffix>Día</label>
          <mat-hint>Día de liquidación o puede dejarlo en cero</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Costo por uso de plataforma</mat-label>
          <input matInput type="text" placeholder="Costo de plataforma + IVA" formControlName="cost_platform_use"
            aria-label="Costo por uso de plataforma + IVA" />
          <mat-hint>Costo por uso de plataforma + IVA</mat-hint>
        </mat-form-field>
        <div class="section-checkbox">
          <mat-checkbox color="accent" formControlName="terminal_ecr">
            Terminal ECR (Habilitación de POS)
          </mat-checkbox>
          <mat-checkbox color="accent" formControlName="checkoutInternacional">
            Internacional
          </mat-checkbox>
        </div>
          <mat-label>QR</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="qr_bind_enable">
            <mat-radio-button value="true">QR BIND</mat-radio-button>
            <mat-radio-button value="false">QR Naranja</mat-radio-button>
          </mat-radio-group>
      </mat-card-content>
    </mat-card>
    <!-- Seccion final, checboxes -->

    <mat-card>
      <mat-card-title-group>
        <mat-card-title>Ingresos Brutos</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <mat-checkbox color="accent" formControlName="iibb_misiones">
          IIBB Misiones
        </mat-checkbox>
      </mat-card-content>
    </mat-card>
  </div>

  <button matStepperPrevious mat-raised-button color="primary" (click)="submitFormGroup(clienteRegulacionesFormGroup)"
    [disabled]="!clienteRegulacionesFormGroup.valid">
    Volver
  </button>
  <button matStepperNext mat-raised-button color="primary" (click)="submitFormGroup(clienteRegulacionesFormGroup)"
    [disabled]="!clienteRegulacionesFormGroup.valid">
    Siguiente
  </button>
</form>