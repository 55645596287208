import { IUserPersonalData } from '../types/clientes.type';

export class UserPersonalData implements IUserPersonalData {
  username: string;
  password: string;

  constructor(data: IUserPersonalData | null = null) {
    this.username = (data && data.username) || '';
    this.password = (data && data.password) || '';
  }

  passwordMatch(verify: string) {
    if (this.password === verify) {
      return true;
    }
    return false;
  }
}
