//Modulos
// import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from './components/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';

import localeEs from '@angular/common/locales/es';

//Rutas
import { RoutingModule } from './routing.module';

//Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/shared/deprecated-sidebar/sidebar.component';
import { NavbarComponent } from './components/shared/side-navigation/components/navbar/navbar.component';
import { MiCuentaComponent } from './components/mi-cuenta/mi-cuenta.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

//Servicios
import { AuthService } from './services/auth.service';

//Directivas
import { UserDirective } from './directives/user.directive';
import { UserRoleDirective } from './directives/user-role.directive';

//Pipes
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ContactoComponent } from './components/contacto/contacto.component';
import { interceptorProviders } from './interceptors/interceptors';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ApiService } from './services/api.service';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MiCuentaComponent,
    PagosComponent,
    UserDirective,
    UserRoleDirective,
    NotFoundComponent,
    ContactoComponent,
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    SharedModule,
    DashboardModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2'),
    }),
    CoreModule,
  ],
  exports: [UserDirective, UserRoleDirective],
  providers: [
    ApiService,
    AuthService,
    DatePipe,
    CurrencyPipe,
    interceptorProviders,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    //{provide: LOCALE_ID, useValue: 'es'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
