import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ideable } from '../../clientes/types/clientes.type';
import { DialogLiquidacionComponent } from '../dialog-liquidacion/dialog-liquidacion.component';
import { DialogPaymentFileComponent } from '../dialog-payment-file/dialog-payment-file.component';
import { DialogTriggerFileComponent } from '../dialog-trigger-file/dialog-trigger-file.component';
import { IDetallesExpanded } from '../payment-file.component';
import { PaymentFileService } from '../payment-file.service';

@Component({
  selector: 'app-payment-file-card',
  templateUrl: './payment-file-card.component.html',
  styleUrls: ['./payment-file-card.component.scss']
})
export class PaymentFileCardComponent implements OnInit {

  @Input() cliente: (IDetallesExpanded & ideable) | null = null;
 
  constructor(private _paymentFileService: PaymentFileService,
              public dialog: MatDialog,private _router: Router) { }

  ngOnInit(): void {
  
  }

  enableClientFile(id:number,enabled:string, name:string): void {
   
    Swal.showLoading();
    const text:string = enabled === 'Habilitado' ? 'Deshabilar':'Habilitar';
    const isEnabled:boolean = enabled === 'Habilitado' ? false: true;
      Swal.fire({
        title:    `¿Esta seguro de ${text} al cliente ${name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si!',
        cancelButtonText: 'Cancelar!'
    }).then((result) => {
        if (result.isConfirmed) {
          this.enableClient(id,isEnabled)
        }
    });
    
  }


  enableClient(clientId: number,isEnabled:boolean){
    const text:string = isEnabled === true ? 'Habilitado': 'Deshabilitado';
    this._paymentFileService.enableClientPaymentFile(clientId,isEnabled).subscribe({
      next: (response) => {
          Swal.fire({icon: 'success', text: `Cliente ${text}`});
          this.volver();
      },
      error: (error) => {
          Swal.fire({icon: 'error', text: 'Error en el proceso.'});
          this.volver();
         // this.cargarTabla(this.pageNumberMov, 10);
      }
    });
    
  }

  openDialogEdit(value:number){
    this._paymentFileService.getClienteArchivoPago(value).subscribe({
      next: (response) => {
          const dialogRef = this.dialog.open(DialogPaymentFileComponent, {
            data:response,
            width: '30%'
          });
      
          dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
            this.volver();
          });
      },
      error: (error) => {
          Swal.fire({icon: 'error', text: 'Error en el recuperar datos.'});
          this.volver();
      }
    });
    
  }

  
  openDialogGenerar(name:string,id:number): void {
    const data_cliente ={
      name:name,
      id:id
    }
    const dialogRef = this.dialog.open(DialogTriggerFileComponent, {
      data:data_cliente,
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
        this.volver();
    });
  }

  openDialogGenerico(name:string,id:number,type:string){
    const data_cliente ={
      name:name,
      id:id,
      type:type
    }
    const dialogRef = this.dialog.open(DialogLiquidacionComponent, {
      data:data_cliente,
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
      this.volver();
    });
  }

  


  volver(){
    this._router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=> this._router.navigate(["dashboard/payment-file"]));
  }

}
