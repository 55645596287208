import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LiquidacionRoutingModule } from './liquidacion-routing.module';
import { LiquidacionComponent } from './liquidacion/liquidacion.component';
import { LiquidacionResultadosComponent } from './features/liquidacion-resultados/liquidacion-resultados.component';
import { LiquidacionConsultaComponent } from './features/consulta-liquidacion/liquidacion-consulta.component';
import { NvaLiquidacionComponent } from './features/nva-liquidacion/nva-liquidacion.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { DetalleLiquidacionComponent } from './features/detalle/detalle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { GenerarLiquidacionComponent } from './features/generar-liquidacion/generar-liquidacion.component';

@NgModule({
  declarations: [
    LiquidacionComponent,
    LiquidacionResultadosComponent,
    LiquidacionConsultaComponent,
    NvaLiquidacionComponent,
    DetalleLiquidacionComponent,
    GenerarLiquidacionComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LiquidacionRoutingModule,
    SharedModule,
    NgxPaginationModule,
  ],
})
export class LiquidacionModule {}
