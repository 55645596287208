import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Storage } from 'src/app/core/decorators/storage.decorator';
import { ClientCrudService } from '../../services/client-crud.service';
import { MaterialModule } from 'src/app/material/material.module';

@Component({
  selector: 'app-dialog-delete',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss'],
})
export class DialogDeleteComponent implements OnInit {
  @Storage('CLIENT_ID')
  clientId: number | null;

  constructor(private _ClientCrud: ClientCrudService) {}

  ngOnInit(): void {}

  deleteClient(id: number) {
    this._ClientCrud.deleteClient(id);
  }
}
