import { Injectable } from '@angular/core';
import { ClientesService } from './clientes.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../components/dialog-delete/dialog-delete.component';
import { Storage } from 'src/app/core/decorators/storage.decorator';
import Swal from 'sweetalert2';
import { IClientesExpanded } from '../types/clientes.type';

@Injectable({
  providedIn: 'root',
})
export class ClientCrudService {
  constructor(private _Clientes: ClientesService, public dialog: MatDialog) {}

  /**
   * Guardamos sessionStorage el ID para pasarlo a un dialog
   */

  @Storage('CLIENT_ID')
  clientId: number;

  deleteClient(id: number) {
    Swal.fire('Eliminando cliente');
    Swal.showLoading();

    this._Clientes.getClienteID(id).subscribe({
      next: (response) => {
        this.clientId = id;
        this.endDeleteProcess(this.clientId, response);
      },
      error() {
        Swal.fire(
          'ERROR',
          'Ocurrio un error deshabilitando el cliente, por favor intente de nuevo mas tarde',
          'error'
        );
      },
    });
  }

  endDeleteProcess(id: number, client: IClientesExpanded) {
    this._Clientes.disableCliente(id, client).subscribe({
      next: (response) => {
        Swal.fire('Cliente eliminado satisfactoiramente');
      },
      error: (error) => {
        Swal.fire(
          'ERROR',
          'Ocurrio un error, por favor intentelo de nuevo mas tarde',
          'error'
        );
      },
    });
  }

  beignDeleteProcess(id: number) {
    this.clientId = id;
    this.openDialogDelete(id);
  }

  /**
   * Llamara al dialog encargado de generar la eliminacion
   * @param id
   * @param enterAnimationDuration
   * @param exitAnimationDuration
   */
  private openDialogDelete(
    id: number,
    enterAnimationDuration: string = '0ms',
    exitAnimationDuration: string = '0ms'
  ): void {
    this.dialog.open(DialogDeleteComponent, {
      width: '600px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
}
