<div class="content animated fadeIn">
    <div class="row  justify-content-center">
        <div class="column  mt-3 col-xl-8 col-lg-10" *ngIf="formConsulta">
            <!--Consulta-->
            <div class="card mb-5">
                <div class="card-header">
                    <h4>Reporte Whastsapp</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="consultaForm" (keydown)="EnterSubmit($event, consultaForm.value)">
                        <div class="card">
                            <div class="row">
                                <!--Fecha acreditación-->
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            
                                                <label class="form-label ">Desde la fecha</label>
                                                <div class="col-12 ">
                                                    <input (change)="dateChange($event, 'creacion')" (focus)="datepicker('creacion')" (blur)="datepicker('creacion')" id="fromCreation" formControlName="desdeCreacion" class="input form-control" type="date" [ngClass]="{ 'is-invalid': submitted && form.desdeCreacion.errors }">
                                                    <div *ngIf="submitted && dateCreacionIsSelected && form.desdeCreacion.errors" class="invalid-feedback">
                                                        <div *ngIf="form.desdeCreacion.errors.required">Este campo es necesario</div>
                                                    </div>
                                                </div>
                                               
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Hasta la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'creacion')" (blur)="datepicker('creacion')" id="toCreation" formControlName="hastaCreacion" class="input form-control " type="date" [ngClass]="{ 'is-invalid': submitted && form.hastaCreacion.errors }">
                                                <div *ngIf="submitted && dateCreacionIsSelected && form.hastaCreacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.hastaCreacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="email" class="form-label">Email</label>
                                        <input formControlName="email" type="email" class="form-control"  id="email" autocomplete="off" email  [ngClass]="{ 'is-invalid': submitted && form.email.errors }">
                                        <div *ngIf="consultaForm.controls['email'].invalid && (consultaForm.controls['email'].dirty || consultaForm.controls['email'].touched)" class="validacion-error animated fadeIn fast">
                                            <div *ngIf="consultaForm.controls['email'].errors.required">
                                                Email Requerido.
                                            </div>
                                            <div *ngIf="consultaForm.controls['email'].errors.email">
                                                El email no es válido.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="buttons ">
                            <button type="button " class="link-green-outline btn" (click)="limpiar()">Limpiar</button>
                            <!-- <button type="button" (click)="onSubmit(consultaForm.value) " class="btn btn-green ml-15 ">Buscar</button> -->
                            <button type="button " class="btn btn-green-secondary ml-15" (click)="onSubmit(consultaForm.value)">
                                Generar reporte
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

     <!--Generando resultados-->
     <div *ngIf="generar" class="row justify-content-center animated fadeIn mt-5">
        <div class="col-xxl-4 col-xl-5 col-lg-8 col-md-6 col-sm-10">
            <div class="card not-found text-center">
                <div class="card-body">
                    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    <h5>Solicitud de reporte generada.</h5>
                    <p *ngIf="fechas">Fecha desde: {{fechaDesde}}</p>
                    <p *ngIf="fechas">Fecha hasta:  {{fechaHasta}}</p>
                    <p> enviado al correo: {{emailForm}}</p>
                    <button class="btn btn-green mt-3 mb-3"  (click)="volver()">Realizar otra consulta</button>
                </div>
            </div>
        </div>
    </div>
     <!--No se han encontrado resultados-->
     <div *ngIf="NoResultado" class="row justify-content-center animated fadeIn mt-5">
        <div class="col-xxl-4 col-xl-5 col-lg-8 col-md-6 col-sm-10">
            <div class="card not-found text-center">
                <div class="card-body">
                    <i class="fas fa-search mt-3 mb-3"></i>
                    <h5>No se encontraron resultados</h5>
                    <button class="btn btn-green mt-3 mb-3" (click)="volver()">Realizar otra consulta</button>
                </div>
            </div>
        </div>
     </div>
     <!--Error -->
     <div *ngIf="error" class="row justify-content-center animated fadeIn mt-5">
        <div class="col-xxl-4 col-xl-5 col-lg-8 col-md-6 col-sm-10">
            <div class="card not-found text-center">
                <div class="card-body">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                    <h5>Se ha producido un error.</h5>
                    <button class="btn btn-green mt-3 mb-3" (click)="volver()">Realizar otra consulta</button>
                </div>
            </div>
        </div>
     </div>

<swal #mensajeSwal title icon confirmButtonText cancelButtonText showCancelButton cancelButtonColor confirmButtonColor confirm></swal>
