<div id="content" class=" h-100 d-grid align-items-center">
    <div class="row justify-content-center">
        <div class="column">
            <div class="brand mt-5 mb-5">
                <img src="../../../assets/img/helipagos-color.png" alt="">
            </div>
            <div class="card animated fadeIn">
                <div class="card-header">
                    <h4><b>¡Bienvenido! Ingresá tus datos para continuar</b></h4>
                </div>
                <div class="card-body">
                    <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
                    <form [formGroup]="loginForm" (keyup.enter)="onSubmit(loginForm.value)">
                        <div class="mb-3 form-email">
                            <label for="email" class="form-label">Correo electrónico</label>
                            <input formControlName="email" #email type="email" class="form-control" placeholder="Usuario" id="email" autocomplete="off">
                            <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Usuario requerido</div>
                            </div> -->
                        </div>
                        <div class="mb-3 form-password">
                            <label for="exampleInputPassword1" class="form-label">Contraseña</label>
                            <!-- <small>¿No recordás tu constraseña?</small> -->
                            <input formControlName="password" #password type="password" class="form-control" placeholder="Contraseña" id="password">
                            <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback text-left">
                                <div *ngIf="f.password.errors.required">Contraseña requerida</div>
                            </div> -->
                        </div>
                        <!-- <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="checkSesion">
                            <label class="form-check-label" for="checkSesion">Mantener la sesión inciada en este equipo</label>
                        </div> -->
                        <div class="d-flex justify-content-center">
                            <button type="button" id="btn-login" class="btn btn-submit btn-primary w-100 mb-3 text-white" (click)="onSubmit(loginForm.value)">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Continuar</button>
                        </div>
                    </form>

                    <!-- <small class="create-account-text">¿No tienes cuenta? <a href="#">Creá tu cuenta</a></small> -->
                </div>
            </div>
            <div class="copy"><p>Helipagos <span>&reg;</span> {{anioActual}}</p> 
        </div>
    </div>
</div>


<swal #failSwal title="Password o contraseña incorrectos" icon="warning" confirmButtonText="Aceptar" confirmButtonColor="#53BAAB"></swal>