<h2 mat-dialog-title>{{tituloAccion}}</h2>
<mat-dialog-content>
    <form [formGroup]="formClienteArchivoPago">
        <mat-grid-list cols="1"> 

            <mat-form-field appearance="outline">
                <mat-label>Cliente</mat-label>
                <mat-select formControlName="id_cliente">
                  <mat-option *ngFor="let cliente of todosClientes" [value]="cliente.id" >
                    {{cliente.razon_social}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Email | Correo electronico</mat-label>
                <input matInput placeholder="helipagos@example.com" aria-label="Correo electronico del cliente" formControlName="email"/>
            </mat-form-field>

            <section class="section-checkbox">
                <div class="opciones-checkbox">
                    <div class="opcion">
                        <mat-checkbox formControlName="archivo_pago">Archivo de pago</mat-checkbox>
                    </div>
                    <div class="opcion">
                        <mat-checkbox formControlName="archivo_caja">Archivo Caja</mat-checkbox>
                    </div>
                </div>
                <div class="opciones-checkbox">
                    <div class="opcion">
                        <mat-checkbox formControlName="rendicion">Rendición</mat-checkbox>
                    </div>
                    <div class="opcion">
                        <mat-checkbox formControlName="liquidacion">Liquidación</mat-checkbox>
                    </div>
                </div>
            </section> 
        </mat-grid-list>
        
    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="warn">Volver</button>
  <button  mat-raised-button color="primary"
  (click)="guardarEditar()" [disabled]="!formClienteArchivoPago.valid">{{botonAccion}}</button>
</mat-dialog-actions>
