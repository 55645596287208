<mat-stepper
  orientation="horizontal"
  [selectedIndex]="currentStepIndex"
  [linear]="true"
  #stepper
>
  <mat-step [completed]="isValid.clientPersonalData">
    <ng-template matStepLabel>Usuarios</ng-template>
    <cliente-datos-personales
      [datosPersonales]="clientPersonalData"
      (datosPersonalesEmitter)="personalDataFormSuccessHandler($event)"
    ></cliente-datos-personales>
  </mat-step>
  <mat-step [completed]="isValid.regulacionesData">
    <ng-template matStepLabel>Regulaciones</ng-template>
    <cliente-form-regulaciones
      [clienteRegulacionesData]="regulacionesData"
      (clienteRegulacionesEmitter)="
        clientRegulacionesFormSuccessHandler($event)
      "
    ></cliente-form-regulaciones>
  </mat-step>
  <mat-step>
    <!-- EL PROP DRILLING SE ESTA REALIZANDO MEDIANTE SESSION STORAGE -->
    <ng-template matStepLabel>Done</ng-template>
    <cliente-form-data-review
      *ngIf="regulacionesData && clientPersonalData && isValid.regulacionesData"
      (confirmActionEmitter)="postNewClientHandler($event)"
    ></cliente-form-data-review>
  </mat-step>
</mat-stepper>
