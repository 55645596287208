import Swal from 'sweetalert2';

export class ErrorHandler {
  private title: string;
  private description: string;
  private icon: AvailableIcon;

  constructor(
    title: string,
    description: string = '',
    icon: AvailableIcon = 'error'
  ) {
    this.title = title;
    this.description = description;
    this.icon = icon;
  }

  get Title() {
    return this.title;
  }

  get Description() {
    return this.description;
  }

  get Icon() {
    return this.icon;
  }

  launch() {
    Swal.fire(this.title, this.description, this.icon);
  }
}
type AvailableIcon = 'warning' | 'info' | 'error';
