<h5 mat-dialog-title>Generar archivo para el cliente {{name_cliente}}</h5>
<mat-dialog-content>
    <form [formGroup]="formGenerar">
        <mat-form-field [appearance]="'outline'">
            <input matInput [matDatepicker]="picker" placeholder="Fecha" formControlName="fecha" (focus)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="warn">Volver</button>
    <button  mat-raised-button color="primary"
    (click)="submit()" [disabled]="!formGenerar.valid">Generar</button>
  </mat-dialog-actions>

