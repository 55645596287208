import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { IClienteFormDetails } from '../../types/clientes.type';
import { ClientRegulaciones } from '../../models/Regulaciones.model';
import Swal from 'sweetalert2';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'cliente-form-regulaciones',
  templateUrl: './form-regulaciones.component.html',
  styleUrls: ['./form-regulaciones.component.scss'],
})
export class FormRegulacionesComponent implements  OnChanges {
  @Input() clienteRegulacionesData: IClienteFormDetails | null = null;
  @Output() clienteRegulacionesEmitter: EventEmitter<IClienteFormDetails | null>;
  @Output() stepperBackForm: EventEmitter<void>;

  clienteRegulacionesFormGroup: FormGroup = this.makeFormGroup(
    this.clienteRegulacionesData
  );

  constructor(private _formBuilder: FormBuilder, private _Api: ApiService,private _currencyPipe: CurrencyPipe) {
    this.clienteRegulacionesEmitter = new EventEmitter<IClienteFormDetails>();
    this.stepperBackForm = new EventEmitter<void>();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.clienteRegulacionesFormGroup = this.makeFormGroup(
      this.clienteRegulacionesData
    );

    
  }
  
  onInputChange(event: any, controlform: string): void {
    const inputValue = event.target.value;
    const updatedValue = inputValue.replace(',', '.');
    const importeManipulator = this.clienteRegulacionesFormGroup.get(controlform);
    importeManipulator.setValue(updatedValue);
  }
  
  onBlur(controlform: string): void {
    const value = this.clienteRegulacionesFormGroup.get(controlform).value;
    if (!isNaN(value) && value >= 0) {
      const formattedValue = this._currencyPipe.transform(value, 'CAD', '', '1.2-2', 'es-ARS');
      const importeManipulator = this.clienteRegulacionesFormGroup.get(controlform);
      importeManipulator.setValue(formattedValue);
    }
  }
  

  /**
   * Genera un formGroup adecuado con valores por defecto
   * En caso de ser tipo edicion, el tomara todos los datos y los rellenara en sus respectivos
   * lugares segun corresponda
   * @param data
   * @returns
   */
  private makeFormGroup(data?: IClienteFormDetails | null) {
    
    const formData = new ClientRegulaciones(data);
    return this._formBuilder.group({
      // Contenedor "Otros"
      semana_liquidacion: [formData.semana_liquidacion],
      dia_liquidacion:[formData.dia_liquidacion],
      terminal_ecr: [formData.terminal_ecr],
      qr_bind_enable:[formData.qr_bind_enable.toString()],
      checkoutInternacional: [formData.checkoutInternacional],
      cost_platform_use: [formData.cost_platform_use],
      // IIBB - Ingresos brutos
      iibb_misiones: [formData.iibb_misiones],
      iibb_sirtac: [formData.iibb_sirtac],

      // Retenciones
      agente_ret_iva: [formData.agente_ret_iva],

      // SIRTAC
      alicuota_sirtac: [ {value: formData.id_cliente !== null ? formData.alicuota_sirtac_formateada : formData.alicuota_sirtac  , disabled: !formData.iibb_sirtac}],

      // RETENCIONES

      ret_iva: [formData.ret_iva],
      alicuota_ret_iva: [ {value: formData.id_cliente !== null ? formData.alicuota_ret_iva_formateada : formData.alicuota_ret_iva, disabled: !formData.ret_iva}] ,
      alicuota_ret_iva_credito: [ {value: formData.id_cliente !== null ? formData.alicuota_ret_iva_credito_formateada : formData.alicuota_ret_iva_credito, disabled: !formData.ret_iva}],

      ret_ganancia: [formData.ret_ganancia],
      alicuota_ret_ganancia: [{value: formData.id_cliente !== null ? formData.alicuota_ret_ganancia_formateada :formData.alicuota_ret_ganancia , disabled: !formData.ret_ganancia}],
      alicuota_ret_ganancia_credito: [ {value: formData.id_cliente !== null ? formData.alicuota_ret_ganancia_credito_formateada :formData.alicuota_ret_ganancia_credito, disabled: !formData.ret_ganancia}],
    });
  }

  /**
   * Habilitara o deshabilitara
   * los formControls que esten inhibidos por algun checkbox con getter.
   *
   * @param getterId - El id o nombre del getter en cuestion, hay un Type bajo la clase para detalle
   * @param formLabels - Una lista de formControl usados en el fromGroup que pueden ser
   * inhibidos por el checkbox pasado en getterId
   */
  private enableFormControls(getterId: classGetters, formLabels: string[]) {
    formLabels.forEach((item) => {
      if (this[getterId]) {
        this.clienteRegulacionesFormGroup.get(item).enable();
      } else {
        this.clienteRegulacionesFormGroup.get(item).disable();
      }
    });
  }

  /**
   * Hace uso de this.enableFormControls() para revisar cada uno de los getters generados por los
   * checkbox que pueden inihibir a campos de datos
   */
  private reviewControls() {
    // Segun si estan habilitados el metodo pasara a quitarles las inhibiciones
    this.enableFormControls('alicuota_sirtac_disabled', [
      'alicuota_sirtac',
      //'crc_sirtac',
    ]);
    this.enableFormControls('ret_ganancia_disabled', [
      'alicuota_ret_ganancia',
      'alicuota_ret_ganancia_credito',
    ]);
    this.enableFormControls('ret_iva_disabled', [
      'alicuota_ret_iva',
      'alicuota_ret_iva_credito',
    ]);
  }

  /**
   * Sube un formGroup de manera directa al componente padre
   */
  submitFormGroup(form: FormGroup): void {
    console.log("form: ",form.value);
    if (this.clienteRegulacionesFormGroup.valid) {
      const clienteRegulacionData = new ClientRegulaciones(form.value);
      this.clienteRegulacionesEmitter.emit(clienteRegulacionData);
    } else {
      Swal.fire(
        'ERROR',
        'Ocurrio un error tomando los datos, por favor intente nuevamente mas tarde',
        'warning'
      );
    }
  }

  /**
   * Por cada cambio que ocurra en un checkbox, se revisaran los disable de cada valor
   */
  setCheckbox() {
    this.reviewControls();
  }

  setOpcionSemana(){

    if(this.clienteRegulacionesFormGroup.value.semana_liquidacion != 0){
      this.clienteRegulacionesFormGroup.get('dia_liquidacion').disable();
      this.clienteRegulacionesFormGroup.value.dia_liquidacion = null;
    }else{
      this.clienteRegulacionesFormGroup.get('dia_liquidacion').enable();
    }

  }

  setOpcionDia(){

    if(this.clienteRegulacionesFormGroup.value.dia_liquidacion != 0){
      this.clienteRegulacionesFormGroup.get('semana_liquidacion').disable();
      this.clienteRegulacionesFormGroup.value.semana_liquidacion = 0;
    }else{
      this.clienteRegulacionesFormGroup.get('semana_liquidacion').enable();
    }
  }

 


  // GETTERS de los checkbox que pueden inhibir la edicion de valores

  get ret_iva_disabled() {
    return this.clienteRegulacionesFormGroup.get('ret_iva').value;
  }

  get ret_ganancia_disabled() {
    return this.clienteRegulacionesFormGroup.get('ret_ganancia').value;
  }

  get alicuota_sirtac_disabled() {
    return this.clienteRegulacionesFormGroup.get('iibb_sirtac').value;
  }

  get dia_liquidacion_disabled(){
    return this.clienteRegulacionesFormGroup.get('dia_liquidacion').value;
  }
}

type classGetters =
  | 'ret_iva_disabled'
  | 'ret_ganancia_disabled'
  | 'alicuota_sirtac_disabled'
  | 'dia_liquidacion_disabled';
