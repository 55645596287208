import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css']
})
export class MiCuentaComponent implements OnInit {

  title:string = "Ingreso del día";
  cifra:string = "$4.450";
  constructor() { }

  ngOnInit(): void {
     
  }

}