/**
 * Main config table to use
 */
export interface ProductionConfigTableInterface {
  headers: TableShowSearchConfig;   // Headers list of the current table
  exportToExcel: boolean;           // Warning - Shows excel exporting button
  showDetails: boolean;             // Let handle details route management
  buscar: boolean;                  // Handle searching bar
  verDetallesTarjeta: boolean;      // Deprecated - Shows details of an specific button of credit
  verDetalles: boolean;             // Deprecated - Show details buttton
  functionUsuarios: boolean;        // Deprecated - Handle users callback function
  buttonsPanel: ActionButton[];     // Columns for actions and buton clicks
  // Properties in case we need to recognize config tables in other components
  flag?: string;
  title?: string;
}



export interface TableShowSearchConfig {
  columns_show: string[];           // Table headers html element to edit in table
  columns_search: string[];         // Table properties to use and search in table
}

/**
 * Interface to handle button columns in the table
 */
export interface ActionButton {
  id: string;
  title: string;                    // Label for the button text
  columnTitle?: string;             // Table header for the button panel column
  icon?: string;                    // If we need an icon in the button
  action: (...args: any[]) => void; // Callback function to use in the click with data
  iconStyle?: boolean;
}
