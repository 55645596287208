import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Role } from './models/role.models';
import { AuthService } from './services/auth.service';
import { TokenService } from 'src/app/services/token.service';
import { ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FunctionsService } from './services/functions.service';
import { registerLocaleData } from '@angular/common';
import localeARS from '@angular/common/locales/es-AR';

registerLocaleData(localeARS, 'es-ARS');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HelipagosDashboard'; 
  @ViewChild('sesionSwal') mensajeSwal!: SwalComponent

  constructor(
    private router: Router, 
    private authService: AuthService, 
    private TokenService: TokenService,
    ){
     

  }

  get isAuthorized() {
    return this.authService.isAuthorized();
  }

  get isAdmin() {
    return this.authService.hasRole(Role.ROLE_ADMIN);
  }

  logout() {
    this.TokenService.logOut();
    this.router.navigate(['login']);
  }

  ngOnInit() {

  }
  ngOnDestroy() {
  }
  
}
