<div *ngIf="detalle" class="card">
    <div class="table-container">
        <div class="table-header">
            <h6>Transacción Nro. {{ id }}</h6>
            <div [ngClass]="{'target': head == 'estado', 'success': datos[head] == 'ACREDITADA', 'cancel': datos[head] == 'VENCIDA','info': datos[head] == 'DEVUELTA', 'generada': datos[head] == 'GENERADA', 'procesada': datos[head] == 'PROCESADA','rechazada': datos[head] == 'RECHAZADA', 'contracargo': datos[head] == 'CONTRACARGO'}"
                class="target success"> {{detalle.solicitud_estado.descripcion}}
            </div>
        </div>
        <!--Tabla Start-->
        <app-tabla-vertical [displayedColumns]="displayedColumns" [dataSource]="dataSource"></app-tabla-vertical>
        <!--Tabla End-->
        <div class="table-footer">
            <button (click)="Volver()">Volver</button>
            <button>Imprimir</button>
        </div>
    </div>
</div>