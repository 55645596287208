import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IClienteFormDetails,
  IClientesExpanded,
  IClientPersonalData,
} from '../../types/clientes.type';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Cliente } from '../../models/clientes.model';
import { ManageClientService } from '../../services/manage-client.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'cliente-form-data-review',
  templateUrl: './cliente-form-data-review.component.html',
  styleUrls: ['./cliente-form-data-review.component.scss'],
})
export class ClienteFormDataReviewComponent implements OnDestroy, OnInit {
  /**
   * NOTE: Mocks disponibles en la carpeta __mocks__ para visualizar campos
   * Por el momento, la funcionalidad mas simple sera manejar los datos por sessionStorage
   */
  clientPersonalData: IClientPersonalData | null;
  regulacionesData: IClienteFormDetails | null;

  @Input() isEditable = false;

  @Output() confirmActionEmitter: EventEmitter<IClientesExpanded> | null = null;

  clientesExpandedFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _currencyPipe: CurrencyPipe,
    private _NewClientService: ManageClientService
  ) {
    this.confirmActionEmitter = new EventEmitter<IClientesExpanded | null>();
    this.confirmActionEmitter = new EventEmitter<IClientesExpanded>();
  }

  ngOnInit(): void {
    this.clientPersonalData = this._NewClientService.clientPersonalData.value;
    this.regulacionesData = this._NewClientService.regulacionesData.value;
    this._NewClientService.clientPersonalData.subscribe((newValue) => {
      this.clientPersonalData = newValue;
      this._updateFormGroup();
    });
    this._NewClientService.regulacionesData.subscribe((newValue) => {
      this.regulacionesData = newValue;
      this._updateFormGroup();
    });

    this._updateFormGroup();
    if (this.isEditable) {
      this.clientesExpandedFormGroup.enable();
    }
  }

  private _updateFormGroup(): void {
    const clienteForm = new Cliente({
      ...this.clientPersonalData,
      ...this.regulacionesData,
    });
    this.clientesExpandedFormGroup = this.makeFormGroup(clienteForm);
  }

  /**
   * Envia todos los datos en conjunto para ser utilizados en el componente padre
   */
  successConfirmAction(): void {
    let clienteExpanded = {
      ...this.clientPersonalData,
      ...this.regulacionesData,
    };
    if (this.isEditable) {
      clienteExpanded = { ...this.clientesExpandedFormGroup.value };
    }
    this.confirmActionEmitter.emit(clienteExpanded);
  }

  makeFormGroup(data: IClientesExpanded | null) {
    console.log('review: ', this.regulacionesData);
    return this._formBuilder.group({
      razon_social: this.makeFormField(
        this.clientPersonalData.razon_social,
        true
      ),
      nombre_legal: this.makeFormField(
        this.clientPersonalData.nombre_legal,
        true
      ),
      email: this.makeFormField(
        this.clientPersonalData.email,
        !this.isEditable
      ),
      domicilio: this.makeFormField(
        this.clientPersonalData.domicilio,
        !this.isEditable
      ),
      web: this.makeFormField(this.clientPersonalData.web, !this.isEditable),
      documento: this.makeFormField(
        this.clientPersonalData.documento,
        !this.isEditable
      ),
      localidad: this.makeFormField(
        this.clientPersonalData.localidad,
        !this.isEditable
      ),
      agente_ret_iva: this.makeFormField(
        this.regulacionesData.agente_ret_iva,
        !this.isEditable
      ),
      terminal_ecr: this.makeFormField(
        this.regulacionesData.terminal_ecr,
        !this.isEditable
      ),
      semana_liquidacion: this.makeFormField(
        this.regulacionesData.semana_liquidacion,
        !this.isEditable
      ),
      dia_liquidacion: this.makeFormField(
        this.regulacionesData.dia_liquidacion,
        !this.isEditable
      ),
      qr_bind_enable: this.makeFormField(
        this.regulacionesData.qr_bind_enable,
        !this.isEditable
      ),
      checkoutInternacional: this.makeFormField(
        this.regulacionesData.checkoutInternacional,
        !this.isEditable
      ),
      cost_platform_use: this.makeFormField(
        this.regulacionesData.cost_platform_use,
        !this.isEditable
      ),
      ret_iva: this.makeFormField(
        this.regulacionesData.ret_iva,
        !this.isEditable
      ),
      alicuota_ret_iva: this.makeFormField(
        this._currencyPipe.transform(
          this.regulacionesData.alicuota_ret_iva / 100,
          'CAD',
          '',
          '1.2-2',
          'es-ARS'
        ),
        !this.isEditable
      ),
      alicuota_ret_iva_credito: this.makeFormField(
        this._currencyPipe.transform(
          this.regulacionesData.alicuota_ret_iva_credito / 100,
          'CAD',
          '',
          '1.2-2',
          'es-ARS'
        ),
        !this.isEditable
      ),
      ret_ganancia: this.makeFormField(
        this.regulacionesData.ret_ganancia,
        !this.isEditable
      ),
      alicuota_ret_ganancia: this.makeFormField(
        this._currencyPipe.transform(
          this.regulacionesData.alicuota_ret_ganancia / 100,
          'CAD',
          '',
          '1.2-2',
          'es-ARS'
        ),
        !this.isEditable
      ),
      alicuota_ret_ganancia_credito: this.makeFormField(
        this._currencyPipe.transform(
          this.regulacionesData.alicuota_ret_ganancia_credito / 100,
          'CAD',
          '',
          '1.2-2',
          'es-ARS'
        ),
        !this.isEditable
      ),
      iibb_sirtac: this.makeFormField(
        this.regulacionesData.iibb_sirtac,
        !this.isEditable
      ),
      alicuota_sirtac: this.makeFormField(
        //this.regulacionesData.alicuota_sirtac+'$',
        //this.formatearNumero(this.regulacionesData.alicuota_sirtac),
        this._currencyPipe.transform(
          this.regulacionesData.alicuota_sirtac / 100,
          'CAD',
          '',
          '1.2-2',
          'es-ARS'
        ),
        !this.isEditable
      ),
      iibb_misiones: this.makeFormField(
        this.regulacionesData.iibb_misiones,
        !this.isEditable
      ),
    });
  }

  get ret_iva_disabled() {
    return this.clientesExpandedFormGroup.get('ret_iva').value;
  }

  makeFormField<Type>(value: Type | any, disabled: boolean = true) {
    return new FormControl({
      value,
      disabled,
    });
  }

  ngOnDestroy(): void {
    /**
     *  Ya que al momento estamos trabajando con sessionStorage
     * Es importante, limpiar el sessionStorage una vez usado lo necesario
     */
    this.clientPersonalData = null;
    this.regulacionesData = null;
  }

  formatearNumero(numero) {
    return numero.toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}
