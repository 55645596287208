import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: any, input: any): any {
    console.log(value);
    let listaObjetosFiltrado: Object[] = [];
    if (input) {
      // Convierte el valor a minúsculas
      input = input.toLowerCase();
      //Devuelve
      for (let i in value) {
        const object = value[i];
        for (var key in object) {
          const stringWord = object[key] ?? '';
          const palabra = stringWord.toString().toLowerCase();
          if (palabra.indexOf(input) >= 0) {
            if (!listaObjetosFiltrado.includes(object)) {
              listaObjetosFiltrado.push(object);
            }
          }
        }
      }
      return listaObjetosFiltrado;
    } else {
      return value;
    }
  }
}
