import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base.service';
import { ClienteArchivoPago } from './models/extras';

@Injectable({
  providedIn: 'root'
})
export class PaymentFileService {

  constructor(private _httpClient: HttpClient, private _baseService: BaseHttpService) { }

  getClientFilePayment(size:number,page:number): Observable<any> {
    return this._httpClient.get<any>(this._baseService.getUri({size,page}, 'cuenta_virtual', 'proceso', 'cliente_archivo_pago'));
  }

  enableClientPaymentFile(id_cliente: number,enabled:boolean): Observable<any> {
    const uri = this._baseService.getUri({id_cliente,enabled}, 'cuenta_virtual', 'proceso', 'habilitar_cliente_archivo_pago');
    return this._httpClient.post(uri,null);
  }

  generatefile(id: number,fecha:string): Observable<any> {
    const uri = this._baseService.getUri({id,fecha}, 'cuenta_virtual', 'proceso', 'generar_archivo');
    return this._httpClient.post(uri,null);
  }

  abmClienteArchivoPago(archivo:ClienteArchivoPago){
    let paramss = new HttpParams();
    paramss = paramss.set('id_cliente', archivo.cliente_id);
    paramss = paramss.set('email',archivo.email);
    paramss = paramss.set('archivo_pago',archivo.archivo_pago);
    paramss = paramss.set('rendicion',archivo.rendicion);
    paramss = paramss.set('liquidacion',archivo.liquidacion);
    paramss = paramss.set('archivo_caja',archivo.archivo_caja);
    const uri = this._baseService.getUri({}, 'cuenta_virtual', 'proceso', 'am_cliente_archivo_pago');
    return this._httpClient.post(uri,paramss);
  }

  getClienteArchivoPago(id:number){
    const uri = this._baseService.getUri({}, 'cuenta_virtual', 'proceso', `get_cliente_archivo_pago/${id}`);
    return this._httpClient.get(uri);
  }

  fileLiquidacion(form:any){
    let paramss = new HttpParams();
    paramss = paramss.set('id', form.id);
    paramss = paramss.set('fecha',form.fecha);
    const uri = this._baseService.getUri({}, 'cuenta_virtual', 'proceso', 'generar_archivo_liquidacion');
    return this._httpClient.post(uri,paramss);
  }

  fileRendicion(form:any){
    let paramss = new HttpParams();
    paramss = paramss.set('id', form.id);
    paramss = paramss.set('fecha',form.fecha);
    const uri = this._baseService.getUri({}, 'cuenta_virtual', 'proceso', 'generar_archivo_rendicion');
    return this._httpClient.post(uri,paramss);
  }
  
}
