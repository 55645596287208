import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { TokenService } from 'src/app/services/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blacklist-lista',
  templateUrl: './blacklist-lista.component.html',
  styleUrls: ['./blacklist-lista.component.css']
})
export class BlacklistListaComponent implements OnInit {

  @ViewChild('editar', {static: false}) contenidoDelModal;
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;
  listId!: number;
  public loading:boolean;
  public configTable: any;
  lista:any[];
  dataSource:any[];
  @Input() accionable!: Function;
  public pageNumberMov:any;
  public totalElements:number; //cantidad de elementos totales de la tabla
  mensajeResultado;
  constructor(private _router: Router,private modal: NgbModal,
    private apiService: ApiService,
    private _functionService: FunctionsService,
    private _tokenService: TokenService) { }

  ngOnInit(): void {
    let headers = {
      columns_show:  ['Id','Nombre','Dni', 'Domicilio', 'Email', 'Tarjeta','Telefono'],
      columns_search:  ["id","name", "dni", "domicilio", "email", "tarjeta","telefono"],
    }
    this.configTable = {
      headers: headers,
      exportToExcel: false,
      verDetallesTarjeta:false,
      buscar:true,
      verDetalles:false,
      functionUsuarios:false,
      functionBlacklist:true
    }

    this.loading = true;
    this.pageNumberMov = 1;
    this.cargarTabla(this.pageNumberMov,10);

  }


  cargarTabla(page: number, pageSize: number){
    this.apiService.getListaBlacklist(page-1,pageSize).subscribe((response : any) => {
       this.lista = response.content;
       let json = {};
       let jsonList=[];
       this.totalElements = response.totalElements;
      // this.pageNumberMov = response.totalPages;
      this.lista.forEach((lista: any)=>{
         json['id'] = lista.id;
         json['email'] = lista.email;
         json['name'] = lista.name;
         json['dni'] = lista.dni;
         json['tarjeta'] = lista.tarjeta;
         json['telefono'] = lista.telefono;
         json['domicilio'] = lista.domicilio;
         jsonList.push(json);
         json = {};
      });
       this.mensajeResultado = "Se encontraron "+this.lista.length+" resultados.";
      
       this.dataSource = jsonList;
     
       this.loading = false;
    },

    
    (error)=>{
      this._functionService.imprimirMensaje(error, "-- Error en usuarios ");
      if(error.status == 403){
        this._functionService.configSwal(this.mensajeSwal, "Sesión expirada", "info", "Login", "", false, "#53BAAB", "")
        this.mensajeSwal.fire().finally(()=> {
          this._tokenService.logOut();
        });
      }else{
        this._functionService.configSwal(this.mensajeSwal, "Error de conexión", "warning", "Reintentar", "Cancelar", true, "#53BAAB", "");
        this.mensajeSwal.fire().then((resolve)=>{
          if(resolve.isConfirmed){
            this.ngOnInit();
          }
        })
        .finally(()=> {
          this.loading = false;
        });        
      } 
    });
  }

  nuevaSolicitud(page:any){
    this.cargarTabla(page.pageNumber, page.pageSize);
  }

  modalEditUsuario(idx:number){
    this.listId = idx;
    this.openLg();
  }

  modalDeleteUsuario(idx:number){
    Swal.fire({
      title: '¿Está seguro de eliminar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar!',
      cancelButtonText: 'Cancelar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.eliminarBlackList(idx)
        .subscribe({
          next:(response) => {
            Swal.fire(
              '¡Eliminado!',
              'Su archivo ha sido eliminado.',
              'success'
            )
            this.onPropagarUser("ok");
          },
          error: (error) => {
            Swal.fire({icon:'error', text:'Error al guardar.'});
            console.log(error);
           
          }
        })
        
      }
    })
    
  }

  openLg() {
    this.modal.open(this.contenidoDelModal, { size: 'lg', centered: true });
 }

  open(contenido:any, id?: number){
    if(id){
      //this.userId = id;
    }
    this.modal.open(contenido,{size: 'lg', centered:true});
  }

  cerrarModal() {
    this.modal.dismissAll();
  }

  onPropagarUser(mensaje:any) {
    this._router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=> this._router.navigate(["dashboard/blacklist/black-list"]));
    this.cargarTabla(1, 10);
  }



}
