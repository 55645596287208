import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDateFormats } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { ExtrasService } from '../../rendiciones-folder/services/extras.service';
import { PaymentFileService } from '../payment-file.service';

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dialog-liquidacion',
  templateUrl: './dialog-liquidacion.component.html',
  styleUrls: ['./dialog-liquidacion.component.scss']
})
export class DialogLiquidacionComponent implements OnInit { //DIALOGO GENERICO 

  formGenerar: FormGroup;
  fechaCtrl = new FormControl();
  name_cliente:string;
  tituloAccion:string;
  constructor(public dialogRef: MatDialogRef<DialogLiquidacionComponent>,
              private formBuilder: FormBuilder,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private paymentFileService:PaymentFileService,
              @Inject(MAT_DIALOG_DATA) public node: any,
              private _router: Router) {
                this.tituloAccion = node.type;
                this.formGenerar = this.formBuilder.group({
                  id: this.node.id,
                  fecha: [, Validators.required]
                });
              }

  ngOnInit(): void {
    
  }

  generar(){
    const fechaValue = this.formGenerar.get('fecha').value;
    const fechaFormateada = this.datePipe.transform(fechaValue, "yyyy-MM-dd");
    this.formGenerar.patchValue({
      fecha: fechaFormateada
    });
    this.dialogRef.close();
    Swal.showLoading();
      Swal.fire({
        title: `¿Esta seguro de generar el archivo ${this.node.type} para el cliente ${this.node.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si!',
        cancelButtonText: 'Cancelar!'
    }).then((result) => {
        if (result.isConfirmed) {
          if(this.node.type === 'Liquidación'){
            this.paymentFileService.fileLiquidacion(this.formGenerar.value).subscribe({
              next: (result: string) => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `Se ha generado el Archivo de pagos correspondiente al día ${this.formGenerar.value.fecha} y ha sido enviado a la dirección de correo electrónico del cliente registrada en el sistema`,
                  showConfirmButton: true,
                  timer: 3500
                });
                this.dialogRef.close();
              },
              error: ({ error }: { error: string }) => {
                Swal.fire('Error al generar liquidación', 'error');
                console.log("error al generar liquidación",error);
              },
            });
          }else{
            this.paymentFileService.fileRendicion(this.formGenerar.value).subscribe({
              next: (result: string) => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `Se ha generado el Archivo de pagos correspondiente al día ${this.formGenerar.value.fecha} y ha sido enviado a la dirección de correo electrónico del cliente registrada en el sistema`,
                  showConfirmButton: true,
                  timer: 3500
                });
                this.dialogRef.close();
              },
              error: ({ error }: { error: string }) => {
                Swal.fire('Error al generar rendición', 'error');
                console.log("error al generar rendición",error);
              },
            });
          }
          
        }
    });
  }

}
