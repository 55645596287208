<div class="row justify-content-center animated fadeIn fast">
    <div class="col-12">
        <!-- <div class="container"> -->
        <div class="card">
            <div class="card-header text-center">
                <h4 *ngIf="!cliente">Nuevo cliente</h4>
                <h4 *ngIf="cliente">Modificar cliente</h4>
            </div>
            <div class="card-body">
                <form [formGroup]="loginForm">
                    <div class="row ">
                        <div class="col-md-4 col-sm-12 mt-3">
                            <div class="mb-3 form-email">
                                <label for="documento" class="form-label">Documento</label>
                                <input formControlName="documento" type="text" class="form-control" placeholder="{{placeholder.documento}}" id="documento" autocomplete="off">
                                <div *ngIf="loginForm.controls['documento'].invalid && (loginForm.controls['documento'].dirty || loginForm.controls['documento'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['documento'].errors.required">
                                        Documento Requerido.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 mt-3">
                            <div class="mb-3 form-email">
                                <label for="razon_social" class="form-label">Razón Social</label>
                                <input formControlName="razon_social" type="text" class="form-control" placeholder="{{placeholder.razon_social}}" id="razon_social" autocomplete="off">
                                <div *ngIf="loginForm.controls['razon_social'].invalid && (loginForm.controls['razon_social'].dirty || loginForm.controls['razon_social'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['razon_social'].errors.required">
                                        Razón Social Requerida.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 mt-3">
                            <div class="mb-3 form-email">
                                <label for="nombre_legal" class="form-label">Nombre Legal</label>
                                <input formControlName="nombre_legal" type="text" class="form-control" placeholder="{{placeholder.nombre_legal}}" id="nombre_legal" autocomplete="off">
                                <div *ngIf="loginForm.controls['nombre_legal'].invalid && (loginForm.controls['nombre_legal'].dirty || loginForm.controls['nombre_legal'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['nombre_legal'].errors.required">
                                        Nombre Legal Requerido.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 mt-md-3">
                            <div class="mb-3 form-email">
                                <label for="domicilio" class="form-label">Domicilio</label>
                                <input formControlName="domicilio" type="text" class="form-control" placeholder="{{placeholder.domicilio}}" id="domicilio" autocomplete="off">
                                <div *ngIf="loginForm.controls['domicilio'].invalid && (loginForm.controls['domicilio'].dirty || loginForm.controls['domicilio'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['domicilio'].errors.required">
                                        Domicilio Requerido.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 mt-md-3">
                            <div class="mb-3 form-email">
                                <label for="username" class="form-label">Usuario</label>
                                <input formControlName="username" type="text" class="form-control" placeholder="{{placeholder.username}}" id="username" autocomplete="off">
                                <div *ngIf="loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['username'].errors.required">
                                        Usuario Requerido.
                                    </div>
                                    <div *ngIf="loginForm.controls['username'].errors.minlength">
                                        El Usuario debe tener al menos 4 caracteres.
                                    </div>
                                    <div *ngIf="loginForm.controls['username'].errors.maxlength">
                                        El Usuario debe tener menos de 60 caracteres.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 mt-md-3">
                            <div class="mb-3 form-email">
                                <label for="localidad" class="form-label">Localidad</label>
                                <input formControlName="localidad" type="text" class="form-control" placeholder="{{placeholder.localidad}}" id="localidad" autocomplete="off">
                                <div *ngIf="loginForm.controls['localidad'].invalid && (loginForm.controls['localidad'].dirty || loginForm.controls['localidad'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['localidad'].errors.required">
                                        Localidad Requerido.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <div class="mb-3 form-email">
                                <label for="email" class="form-label">Email</label>
                                <input formControlName="email" type="email" class="form-control" placeholder="{{placeholder.email}}" id="email" autocomplete="off" email>
                                <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['email'].errors.required">
                                        Email Requerido.
                                    </div>
                                    <div *ngIf="loginForm.controls['email'].errors.email">
                                        El email no es válido.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-12">
                            <div class="mb-3 form-email">
                                <label for="web" class="form-label">Web</label>
                                <input formControlName="web" type="text" class="form-control" placeholder="{{placeholder.web}}" id="web" autocomplete="off">
                                <div *ngIf="loginForm.controls['web'].invalid && (loginForm.controls['web'].dirty || loginForm.controls['web'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['web'].errors.web">
                                        La Web no es válido.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <div *ngIf="btnAcept != 'Modificar'" class="mb-3 form-password">
                                <label for="password" class="form-label">Contraseña</label>
                                <input formControlName="password" #password type="password" class="form-control" placeholder="Contraseña" id="password">
                                <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)" class="validacion-error animated fadeIn fast">
                                    <div *ngIf="loginForm.controls['password'].errors.required">
                                        Contraseña Requerida.
                                    </div>
                                    <div *ngIf="loginForm.controls['password'].errors.minlength">
                                        La contraseña debe tener al menos 9 caracteres.
                                    </div>
                                    <div *ngIf="loginForm.controls['password'].errors.maxlength">
                                        La contraseña debe tener menos de 60 caracteres.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <div class="mb-3 form-email">
                                <label for="estado" class="form-label">Estado</label>
                                <select formControlName="estado" class="form-select">
                                    <option selected disabled hidden>Seleccione el estado</option>
                                    <option>Habilitado</option>
                                </select>
                            </div>
                        </div>
                       
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn buttons" (click)="atras()">Volver</button>
                        <button type="button" class="btn buttons btn-green" (click)="onSubmit()">{{ btnAcept }}</button>
                    </div>

                </form>
            </div>
        </div>
        <!-- </div> -->
    </div>
</div>