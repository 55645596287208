import { Routes } from '@angular/router';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { FacturacionConsultaComponent } from './facturacion-folder/facturacion-consulta/facturacion-consulta.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { FacturacionResultadosComponent } from './facturacion-folder/facturacion-resultados/facturacion-resultados.component';
import { BtnPagoComponent } from './btn-pago-folder/btn-pago/btn-pago.component';
import { ListadoBtnPagoComponent } from './btn-pago-folder/listado-btn-pago/listado-btn-pago.component';
import { ModalShareBtnPagoComponent } from './btn-pago-folder/modal-share-btn-pago/modal-share-btn-pago.component';
import { ReportesComponent } from './reportes/reportes.component';
import { ArchivosTransmisionComponent } from './archivos-transmision/archivos-listado.component';
import { TransferirComponent } from './transferir/transferir.component';
import { WhastsappComponent } from './whastsapp/whastsapp.component';
import { GestionReportesComponent } from './gestion-reportes/gestion-reportes.component';
import { ArchivosProcesadosComponent } from './archivos-procesados/archivos-procesados.component';
import { CobranzasComponent } from './cobranzas/cobranzas.component';
import { PaymentFileComponent } from './payment-file/payment-file.component';

// canActivate: [AuthGuard]
export const APP_ROUTES: Routes = [
  {
    path: 'usuarios',
    component: UsuariosComponent
  },
  {
    path: 'clientes',
    loadChildren: () => import('./clientes/clientes.module').then((m) => m.ClientesModule),
  },
  {
    path: 'abm_usuarios',
    component: UsuariosComponent
  },
  {
    path: 'estadisticas',
    component: EstadisticasComponent
  },
  {
    path: 'movimientos',
    loadChildren: () => import('./movimientos/movimientos.module').then((m) => m.MovimientosModule),
  },
  {
    path: 'rendiciones',
    loadChildren: () => import('./rendiciones-folder/rendiciones.module').then((m) => m.RendicionesModule),
  },
  {
    path: 'transferir/:id/:id_cliente/:neto',
    component: TransferirComponent
  },
  {
    path: 'archivo_base',
    loadChildren: () => import('./archivo-base-folder/archivo-base.module').then((m) => m.ArchivoBaseModule),
  },
  {
    path: 'archivos-transmision',
    loadChildren: () => import('./archivos-transmision/archivos-transmision.module').then((m) => m.ArchivosTransmisionModule),
  },
  {
    path: 'transacciones/archivosTransmision',
    component: ArchivosTransmisionComponent,
  },
  {
    path: 'facturacion',
    component: FacturacionConsultaComponent
  },
  {
    path: 'facturacion/resultados',
    component: FacturacionResultadosComponent
  },
  {
    path: 'lista_btn_pago',
    component: ListadoBtnPagoComponent
  },
  {
    path: 'nvo_btn_pago',
    component: BtnPagoComponent
  },
  {
    path: 'compartir-btn-pago',
    component: ModalShareBtnPagoComponent
  },
  {
    path: 'reportes',
    component: ReportesComponent
  },
  {
    path: 'whastsapp',
    component: WhastsappComponent
  },
  {
    path: 'gestion-reportes',
    component: GestionReportesComponent
  },
  {
    path: 'cobranza',
    component: CobranzasComponent
  },
  {
    path: 'cobranza/archivos-procesados',
    component: ArchivosProcesadosComponent
  },
  {
    path: 'transacciones/archivosTransmision/:agencia',
    component: ArchivosTransmisionComponent,
  },
  {
    path: 'conciliaciones',
    loadChildren: () => import('./conciliaciones-folder/conciliaciones.module').then((m) => m.ConciliacionesModule),
  },
  {
    path: 'liquidacion',
    loadChildren: () => import('./liquidacion-folder/liquidacion.module').then((m) => m.LiquidacionModule),
  },
  {
    path: 'blacklist',
    loadChildren: () => import('./blacklist/blacklist-routing.module').then((m) => m.BlacklistRoutingModule)
  },
  {
    path: 'cvu',
    loadChildren: () => import('./cvu/cvu.module').then((m) => m.CvuModule),
  },
  {
    path: 'terminal',
    loadChildren: () => import('./terminals/terminal.module').then(m => m.TerminalModule)
  },
  {
    path: 'punto-de-venta',
    loadChildren: () => import('./point-of-sale/point-of-sale.module').then(m => m.PointOfSaleModule)
  },
  {
    path: 'payment-file',
    component: PaymentFileComponent
  },
  {
    path: 'cobro-presencial',
    loadChildren: () => import('./cobros-presenciales/cobros-presenciales.module').then(m => m.CobrosPresencialesModule)
  }
];
