import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators,UntypedFormControl } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ConsultaConciliaciones } from 'src/app/models/consultaConciliacion';
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { TransaccionesService } from 'src/app/services/transacciones.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare let jQuery: any;
@Component({
  selector: 'app-whastsapp',
  templateUrl: './whastsapp.component.html',
  styleUrls: ['./whastsapp.component.css']
})
export class WhastsappComponent implements OnInit {

  public consultaForm: UntypedFormGroup;
  public submitted:boolean = false;
  public mediosPago:any;
  public generar:boolean=false;
  public formConsulta:boolean=true;
  public fechas:boolean=false;
  public fechaDesde:String;
  public fechaHasta:String;
  public emailForm:String;
  listaClientes;
  public res:any;
  public NoResultado:boolean=false;
  public bandera:boolean=false;
  public error:boolean = false;
  fechasError:boolean;
  //Fechas
  dateCreacionIsSelected:boolean;
  emailErros:boolean;
  dateAcreditacionIsSelected:boolean;
  datePaymentIsSelected:boolean;
  placeholder;
  public userReporte_id:any;
  public id_user:any;
  //Actualizar
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;
  
  constructor(private formBuilder: UntypedFormBuilder, 
              private datePipe: DatePipe,
              private _functionsService: FunctionsService,
              private apiService: ApiService,
              private transaccionesService: TransaccionesService,
              private location: Location) { 
                this.consultaForm = this.formBuilder.group({
                  desdeCreacion: new UntypedFormControl('',Validators.required),
                  hastaCreacion: new UntypedFormControl('',Validators.required),
                  email:['',Validators.required]
            
            
               })
              }

   //Controls
   get form(){
    return this.consultaForm.controls;
  }
  get desdeCreacion() {
    return this.consultaForm.get('desdeCreacion') as UntypedFormControl;
  }
  get hastaCreacion() {
    return this.consultaForm.get('hastaCreacion') as UntypedFormControl;
  }
  get email() {
    return this.consultaForm.get('email') as UntypedFormControl;
  }

  ngOnInit(): void {
       //Arma el formulario
    this.makeForm();
  }

  makeForm(){
    this.consultaForm = this.formBuilder.group({
      desdeCreacion: new UntypedFormControl('', Validators.required),
      hastaCreacion: new UntypedFormControl('', Validators.required),
      email:['',Validators.required]
   });
    //DatePicker max && min
    var today = Date.now();
    var hoy = this.datePipe.transform(today,"yyyy-MM-dd");
    var datePickers = ['to', 'from']; 

    jQuery.each(datePickers, function (ind, elem) { 
      jQuery("#"+elem).attr({
        "max" : hoy
      })
    });    
  }

  onSubmit(consultaForm){
  
    this.fechas = true;
    this.fechaDesde = consultaForm.desdeCreacion;
    this.fechaHasta = consultaForm.hastaCreacion;

    this.emailForm= consultaForm.email;
    
    this.submitted = true;
    //Formulario inválido
      if(this.consultaForm.invalid){
        return;
      }
      this.generarReporte(consultaForm);
  }

  generarReporte(consultaForm){
      let user = JSON.parse(localStorage.getItem("userReporte"));
      this.apiService.getUsuario(user)
      .then((response) => {
        this.userReporte_id = response;
       
            this.apiService.reporteWhasp(consultaForm,this.userReporte_id.id)
            .then((response) => {
              this.res = response;
            })
            .catch(
              (error)=>{
                console.log(error);
                this.generar =false;
                this.formConsulta=false;
                this.error = true;
            });
     
      })
      .catch(
        (error)=>{
          console.log(error);
          this.generar =false;
          this.formConsulta=false;
          this.error = true;
      });
     
      //this.apiService.reporteWhasp(consultaForm);
     
      this.generar =true;
      this.formConsulta=false;
      this.error = false;
      
    }

 
  limpiar(){
    this.submitted = false;
    this.consultaForm.reset();
    this.makeForm();
  }

  EnterSubmit(event, form) {
    if (event.keyCode === 13)  this.onSubmit(form) 
  }

  //El estado de pago condiciona los demas inputs, es decir, por EJ: si ESTADO = acreditado, entonces, fecha de acreditacion se deshabilita
  changeEstado(estado:string){
    //Deshabilita medios de pago y fecha de acreditacion
    switch(estado){
      case "1":
        this.disabled();
        break;
      case "2":
        this.enabled();
        break;
      case "3":
        this.enabled();
        break;
      case "4":
        this.disabled();
        break;
        case "5":
        this.enabled();
        break;
    }

  }

  get hasta() {
    return this.consultaForm.get('hastaCreacion') as UntypedFormControl;
  }
  //Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
  datepicker(filtroFecha:string){
    var dateFrom = null;
    var dateTo = null;

     //Si no está vacío
     if(dateFrom != ""){
      jQuery("#to").attr({   
            "min" : dateFrom
          });
    }

    if(dateTo != ""){
          jQuery("#from").attr({   
          "max" : dateTo,
        });
    }      
  

    //Toma los datos del formulario
    dateFrom = this.consultaForm.get('desdeCreacion').value;
    dateTo = this.consultaForm.get('hastaCreacion').value;
     // Rango máximo 60 dias (sweetalert2)
     var dateFrom60 = this.datePipe.transform(new Date(dateFrom).getTime()+5150000000,'yyyy-MM-dd');
     if(dateTo > dateFrom60){
       this.hasta.reset();
       Swal.fire({
         icon: 'error',
         title: 'Rango máximo 60 días'
       })
     }
        
    
    
  }

  
   //Deshabilita fecha de pago y fecha de acreditacion
   disabled(){
    this.consultaForm.controls['medioPago'].disable();
    this.consultaForm.controls['desdeAcreditacion'].disable();
    this.consultaForm.controls['hastaAcreditacion'].disable();
    this.consultaForm.controls['desdePago'].disable();
    this.consultaForm.controls['hastaPago'].disable();
  }
   //Habilita fecha de pago y fecha de acreditacion
  enabled(){
    this.consultaForm.controls['medioPago'].enable();
    this.consultaForm.controls['desdeAcreditacion'].enable();
    this.consultaForm.controls['hastaAcreditacion'].enable();
    this.consultaForm.controls['desdePago'].enable();
    this.consultaForm.controls['hastaPago'].enable();
  }

   //Rango de fechas (validación)
   dateChange(e, filtroFecha:string){

    switch (filtroFecha) {
      case "creacion":
        this.dateCreacionIsSelected = true;
        this.emailErros = true;
        if(e.target.value == ""){
          //Remueve la validación
          this.desdeCreacion.clearValidators();
          this.hastaCreacion.clearValidators();
         
          //Actualiza
          this.desdeCreacion.updateValueAndValidity();
          this.hastaCreacion.updateValueAndValidity();
        }else{
          //Añade validación
          this.desdeCreacion.setValidators([Validators.required]);
          this.hastaCreacion.setValidators([Validators.required]);
          //Actualiza
          this.desdeCreacion.updateValueAndValidity();
          this.hastaCreacion.updateValueAndValidity();
        }
        break;
     
        
    }
  }

  volver(){
    this.limpiar();
    this.formConsulta = true;
    this.generar = false;
    this.NoResultado=false;
    this.error = false;
    
  }
  

}

