import { Component, OnInit,Inject  } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { ClienteArchivoPago } from '../models/extras';
import { PaymentFileService } from '../payment-file.service';

@Component({
  selector: 'app-dialog-payment-file',
  templateUrl: './dialog-payment-file.component.html',
  styleUrls: ['./dialog-payment-file.component.scss']
})
export class DialogPaymentFileComponent implements OnInit {

  formClienteArchivoPago:FormGroup;
  tituloAccion:string ="Agregar";
  botonAccion:string = "Guardar";
  todosClientes: any;
  constructor(private dialogRef: MatDialogRef<DialogPaymentFileComponent>,
              @Inject(MAT_DIALOG_DATA) public datosArchivoPago:ClienteArchivoPago,
              private fb:FormBuilder, private _paymentFileService:PaymentFileService,
              private apiService: ApiService) {

      this.formClienteArchivoPago = this.fb.group({
        id_cliente:['',Validators.required],
        email:['',Validators.compose([Validators.required,Validators.email])],
        archivo_pago:[false],
        rendicion:[false],
        liquidacion:[false],
        archivo_caja:[false]
      });
      
      if(this.datosArchivoPago != null){
        this.tituloAccion = "Editar";
        this.botonAccion = "Actualizar";
        //this.formClienteArchivoPago.get('id_cliente')?.disable();
      }

     }

  ngOnInit(): void {
    this.apiService.getClient().subscribe(clientes => {
      this.todosClientes = clientes;
    },error => {
      Swal.fire('Error al intentar cargar los clientes', error, 'error');
    });
    if(this.datosArchivoPago != null){
      this.formClienteArchivoPago = this.fb.group({
       // id_archivo: this.datosArchivoPago.id_archivo  == null ? 0:this.datosArchivoPago.id_archivo,
        id_cliente:[{value: this.datosArchivoPago.cliente_id, disabled: true}],
        email: [this.datosArchivoPago.email, Validators.compose([Validators.required,Validators.email])],
        archivo_pago:this.datosArchivoPago.archivo_pago,
        rendicion:this.datosArchivoPago.rendicion,
        liquidacion:this.datosArchivoPago.liquidacion,
        archivo_caja:this.datosArchivoPago.archivo_caja
      });
    }
  }

  guardarEditar(){
    console.log("Actualizar");
    const _archivo_pago :ClienteArchivoPago ={
      //id_archivo: this.datosArchivoPago.id_archivo  == null ? 0:this.datosArchivoPago.id_archivo,
      cliente_id:this.datosArchivoPago  === null ? this.formClienteArchivoPago.value.id_cliente:this.datosArchivoPago.cliente_id,
      email:this.formClienteArchivoPago.value.email,
      archivo_pago:this.formClienteArchivoPago.value.archivo_pago,
      rendicion:this.formClienteArchivoPago.value.rendicion,
      liquidacion:this.formClienteArchivoPago.value.liquidacion,
      archivo_caja:this.formClienteArchivoPago.value.archivo_caja
    }

    if(this.datosArchivoPago == null){
      this._paymentFileService.abmClienteArchivoPago(_archivo_pago).subscribe({
        next: (result: string) => {
          console.log("resultado: ",result);
          Swal.fire('Registro generado exitosamente.', result, 'success');
          this.dialogRef.close();
        },
        error: ({ error }: { error: string }) => {
          Swal.fire('Error al generar el registro', 'error');
          console.log("error al generar el registro",error);
        },
      });
    }else{
      this._paymentFileService.abmClienteArchivoPago(_archivo_pago).subscribe({
        next: (result: string) => {
          console.log("resultado: ",result);
          Swal.fire('Registro actualizado exitosamente.', result, 'success');
          this.dialogRef.close();
        },
        error: ({ error }: { error: string }) => {
          Swal.fire('Error al actualizar el registro', 'error');
          console.log("error al actualizar el registro",error);
        },
      });
    }
  }

}
