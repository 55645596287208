import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { LiquidacionExtrasService } from '../../services/extras.service';
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-generar-liquidacion',
  templateUrl: './generar-liquidacion.component.html',
  styleUrls: ['./generar-liquidacion.component.scss']
})
export class GenerarLiquidacionComponent implements OnInit {

  
  formGenerar: FormGroup;
  clienteCtrl = new FormControl();
  bancoCtrl = new FormControl();
  clientesFiltrados: Observable<any[]>;
  todosClientes: any;
  bancosFiltrados: Observable<any[]>;
  todosBancos: any;
  name_cliente:string;
  constructor(private apiService: ApiService,
              private formBuilder: FormBuilder,
              private liquidacionExtrasService: LiquidacionExtrasService,
              public dialogRef: MatDialogRef<GenerarLiquidacionComponent>) { 
    this.formGenerar = this.formBuilder.group({
      cliente_id: [, Validators.required],
      banco_id: [, Validators.required],
    });
  }

  ngOnInit() {
    this.cargarSelectCliente();
    this.cargarSelectBanco();
  }

  cargarSelectCliente(){
    this.apiService.getClient().subscribe(clientes => {
      this.todosClientes = clientes;
      this.clientesFiltrados = this.clienteCtrl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.razon_social),
        map(razonSocial => razonSocial ? this._filtroClientes(razonSocial) : this.todosClientes.slice())
      );
    },error => {
      Swal.fire('Error al intentar cargar los clientes', error, 'error');
    });
  }

  cargarSelectBanco(){
    this.liquidacionExtrasService.getBancks().subscribe(bancos => {
      this.todosBancos = bancos;
      this.bancosFiltrados = this.bancoCtrl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.bank_description),
        map(bankDescription => bankDescription ? this._filtroBancos(bankDescription) : this.todosBancos.slice())
      );
    },error => {
      Swal.fire('Error al intentar cargar los bancos', error, 'error');
    });
  }
  private _filtroClientes(value: string): any[] {
    const filtroValue = value.toLowerCase();

    return this.todosClientes.filter(cliente => cliente.razon_social.toLowerCase().includes(filtroValue));
  }
  private _filtroBancos(value: string): any[] {
    const filtroValue = value.toLowerCase();

    return this.todosBancos.filter(banco => banco.bank_description.toLowerCase().includes(filtroValue));
  }

  seleccionarCliente(event: MatAutocompleteSelectedEvent) {
    const clienteId = event.option.value;
    const clienteSeleccionado = this.todosClientes.find(cliente => cliente.id === clienteId);
    this.formGenerar.get('cliente_id').setValue(clienteId);
    this.clienteCtrl.setValue(clienteSeleccionado.razon_social);
    this.name_cliente = clienteSeleccionado.razon_social;
  }

  seleccionarBanco(event: MatAutocompleteSelectedEvent) {
    const bancoId = event.option.value;
    const bancoSeleccionado = this.todosBancos.find(banco => banco.id === bancoId);
    this.formGenerar.get('banco_id').setValue(bancoId);
    this.bancoCtrl.setValue(bancoSeleccionado.bank_description);
  }

  generar(){
      Swal.showLoading();
      Swal.fire({
        title: `¿Esta seguro de generar la liquidación manual para el cliente ${this.name_cliente}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si!',
        cancelButtonText: 'Cancelar!'
    }).then((result) => {
        if (result.isConfirmed) {
          this.liquidacionExtrasService.generarLiquidacion(this.formGenerar.value.cliente_id,this.formGenerar.value.banco_id).subscribe({
            next: (result: string) => {
              Swal.fire('Liquidación generada exitosamente.', result, 'success');
              this.dialogRef.close();
            },
            error: ({ error }: { error: string }) => {
              Swal.fire('Error al generar liquidación', 'error');
              console.log("error al generar liquidación",error);
            },
          });
        }
    });




    
  }

}
