import { Component, OnInit } from '@angular/core';
import { ProductionConfigTableInterface } from '../../../components/shared/tabla-resultados/production-config-table.interface';
import { TerminalService } from '../terminal.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddTerminalToUserComponent } from '../components/dialog-add-terminal-to-user/dialog-add-terminal-to-user.component';
import UserTerminalRequestModel from '../models/user-terminal-request.model';
import Swal from 'sweetalert2';
import { DialogNewTerminalComponent } from '../components/dialog-new-terminal/dialog-new-terminal.component';
import NewTerminalRequestModel from '../models/new-terminal-request.model';
import ErrorStrings from '../../../constants/ErrorStrings';

@Component({
  selector: 'app-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.scss'],
})
export class TerminalListComponent implements OnInit {
  constructor(
    private _terminalService: TerminalService,
    public dialog: MatDialog
  ) {}

  static DELETE_TERMINAL_FLAG = 'delete_terminal';

  /**
   * Table configuration
   */

  TERMINAL_CONFIG_TABLE: Partial<ProductionConfigTableInterface> = {
    flag: 'RELATED_USERS',
    headers: {
      columns_show: ['Nro. Terminal', 'Usuario', 'Cliente'],
      columns_search: ['terminal_number', 'username', 'cliente'],
    },
    buscar: true,
    buttonsPanel: [
      {
        id: TerminalListComponent.DELETE_TERMINAL_FLAG,
        columnTitle: 'Baja',
        action: null,
        title: 'Dar de baja terminal',
      },
    ],
  };

  dataSource: TableItem[];
  isTableLoading: boolean;

  // develop only
  userTerminalRequest: UserTerminalRequestModel;
  name: string;

  public totalElements: number;
  public configTable: Partial<ProductionConfigTableInterface>;
  public pageNumberMov = 1;

  ngOnInit(): void {
    this.configTable = this.TERMINAL_CONFIG_TABLE;
    this.cargarTabla(this.pageNumberMov, 10);
  }

  openDialogAddTerminalToUser(): void {
    const dialogRef = this.dialog.open(DialogAddTerminalToUserComponent, {
      width: '500px',
      data: null,
    });

    dialogRef.afterClosed().subscribe((result?: UserTerminalRequestModel) => {
      if (result) {
        this.userTerminalRequest = result;
        Swal.showLoading();
        this._terminalService
          .httpSetPosnetWithUser(this.userTerminalRequest)
          .subscribe({
            // tslint:disable-next-line:no-shadowed-variable
            next: (result: string) => {
              Swal.fire('OK', result, 'success');
              this.cargarTabla(this.pageNumberMov, 10);
            },
            error: ({ error }: { error: string }) => {
              Swal.fire('ERROR', error.toString(), 'error');
              this.cargarTabla(this.pageNumberMov, 10);
            },
          });
      }
    });
  }

  openDialogAddNewTerminal(): void {
    const dialogRef = this.dialog.open(DialogNewTerminalComponent, {
      width: '500px',
      data: null,
    });

    // TODO: Match interface
    dialogRef.afterClosed().subscribe((result?: NewTerminalRequestModel) => {
      if (result) {
        this._terminalService.httpCreateNewTerminal(result).subscribe({
          next: (data: string): void => {
            Swal.fire('OK', data, 'success');
          },
          error: (error: any) => {
            Swal.fire('ERROR', ErrorStrings.UNEXPECTED_ERROR, 'error');
          }
        });
      }
    });
  }

  cargarTabla(page: number | string, pageSize: string | number): void {
    const _pageSize = Number(pageSize);
    const _page = Number(page);
    this.isTableLoading = true;
    const deleteTerminalFromUserProperty =
      this.deleteTerminalFromUser.bind(this);
    this._terminalService.httpGetRelatedPosnetList().subscribe(
      (response) => {
        this.totalElements = response.totalElements;
        this.dataSource = response.content.map((terminalitem): TableItem => {
          return {
            id: terminalitem.id_user,
            username: terminalitem.username,
            terminal_number: terminalitem.terminal_number,
            cliente: terminalitem.cliente,
          };
        });
        this.isTableLoading = false;
      },

      (error) => {
        console.log(error);
        this.isTableLoading = false;
      }
    );
  }

  deleteTerminalFromUser(userId: number): void {
    console.log('HACIENDO');
    Swal.showLoading();
    this._terminalService.httpDeleteUserTerminals(userId).subscribe({
      next: (result: string) => {
        Swal.fire('OK', result, 'success');
        this.cargarTabla(this.pageNumberMov, 10);
      },
      error: ({ error }: { error: string }) => {
        Swal.fire('Error', error, 'error');
        this.cargarTabla(this.pageNumberMov, 10);
      },
    });
  }

  getNewPageRequest(page: { pageNumber: number; pageSize: number }): void {
    this.cargarTabla(page.pageNumber, page.pageSize);
  }

  handleButtonPanelEmitter(data: TableItem): void {
    switch (data.button_id) {
      case TerminalListComponent.DELETE_TERMINAL_FLAG:
        this.deleteTerminalFromUser(data.id);
        break;
      default:
        break;
    }
  }
}

interface TableItem {
  id: number;
  username: string;
  terminal_number: string;
  cliente: string;
  button_id?: string;
}

type KindOfTable = 'RELATED_USERS' | 'ENABLED_TERMINALS';
