<h5 mat-dialog-title>Generar liquidación manual</h5>
<mat-dialog-content>
    <form [formGroup]="formGenerar">
        <mat-grid-list cols="1" rowHeight="300px"> 
          <mat-form-field appearance="outline">
            <mat-label>Seleccionar Cliente</mat-label>
            <input type="text" placeholder="Buscar cliente" matInput [formControl]="clienteCtrl" [matAutocomplete]="autoCliente">
            <mat-autocomplete #autoCliente="matAutocomplete" (optionSelected)="seleccionarCliente($event)">
              <mat-option *ngFor="let cliente of clientesFiltrados | async" [value]="cliente.id">
                {{cliente.razon_social}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Seleccionar Banco</mat-label>
            <input type="text" placeholder="Buscar banco" matInput [formControl]="bancoCtrl" [matAutocomplete]="autoBanco">
            <mat-autocomplete #autoBanco="matAutocomplete"  (optionSelected)="seleccionarBanco($event)">
              <mat-option *ngFor="let banco of bancosFiltrados | async" [value]="banco.id">
                {{banco.bank_description}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-grid-list>
    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="warn">Volver</button>
  <button  mat-raised-button color="primary"
  (click)="generar()"  [disabled]="!formGenerar.valid">Generar</button>
</mat-dialog-actions>