<app-spinner *ngIf="loading"></app-spinner>
<div class="title">
    <h1>Archivos</h1>
</div>
<div class="col-lg-12 col-md-6 col-sm-12">
     <div class="mb-3">
        <form [formGroup]="consultaForm">
            <div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="cliente" class="form-label">Cliente</label>
                        <input matInput placeholder="Todos" aria-label="cliente" [matAutocomplete]="auto" formControlName="cliente" class="form-select">
                        <mat-autocomplete (optionSelected)="select($event)" #auto="matAutocomplete">
                        <mat-option *ngFor="let cliente of clientes$ | async" [value]="cliente.name">
                            <span>{{cliente.name  | titlecase}}</span>
                        </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="mb-3">
                            <label for="tipo_archivo" class="form-label">Tipo de Archivo</label>
                            <select formControlName="tipo_archivo" class="form-select" aria-label="Default select example" (change)="selectArchivo()">
                                <option  value="archivo_procesado">Generador de solicitud de pago</option>
                                <option value="archivo_notificacion">Notificación de pago</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<app-tabla-resultados  [configTable]="configTable" [data]="dataSource" [totalElements]="totalElements" [pageNumber]="pageNumberMov" (eventGetPage)="nuevaSolicitud($event)"></app-tabla-resultados>
