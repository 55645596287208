<div class="principal" >
    <div *ngIf="datosTransferencia">
        <div class="card animated fadeIn" *ngFor="let dato of dataSource">
            <div class="card-header">
                <h4>Revisá si está todo bien</h4>
            </div>
            <div class="transferencia">
                <div class="div">
                    <div class="divNumero">
                    <small>Liquidación Nº {{dato.id}} </small> 
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="card-body text-center">
                            <small>Vas a transferir</small>
                            <div class="importe d-grid justify-content-center mt-3">
                                <small Class="monto">{{dato.neto}}</small>
                            </div>
            
                        </div>
                    
                    </div>
                </div>
                <div class="div">
                    <div class="col-12">
                        <p>Para</p>
                        <h6>{{dato.nombreCliente}}</h6>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-3">
                        <p>Banco</p>
                        <h6>{{dato.banco}}</h6>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-3">
                        <p>Tipo de cuenta</p>
                        <h6>{{dato.tipo_cuenta}}</h6>
                    </div>
                    <div class="col-12 mt-3">
                        <p>CBU</p>
                        <h6>{{dato.cbu}}</h6>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center border-top-0">
            <!-- <button class="btn btn-green m-3" (click)="habilitaMensajeFinal()"> Transferir</button>-->
                <button class="btn btn-green m-3" (click)="realizarTransferencia()" >Transferir</button>
                <button class="btn btn m-3" (click)="volver()">Volver</button>
            </div>
        </div>

    </div>
          

            <div  *ngIf="msjfinal">

                <div class="card animated fadeIn" *ngFor="let dato of dataSource">
                    <div class="transferencia">
                        <div class="div">
                            <div class="msj">
                                <i class='fa fa-check-circle-o'></i>
                            </div>
                            <div class="d-flex justify-content-between mt-2">
                                <div class="card-body text-center">
                                   
                                    <h1>¡Listo! Transferencia finalizada.</h1>
                                    <p>Operación #12345</p>
                                    <small>Importe transferido</small>
                                    <div class="importe d-grid justify-content-center mt-3">
                                        <small Class="monto">{{dato.neto}}</small>
                                    </div>
                    
                                </div>
                            
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-footer text-center border-top-0">
                    <!-- <button class="btn btn-green m-3" (click)="habilitaMensajeFinal()"> Transferir</button>-->
                      
                        <button class="btn btn m-3" (click)="volver()">Volver</button>
                    </div>
                </div>
            </div>

            <div  *ngIf="msjError">

                <div class="card animated fadeIn" *ngFor="let dato of dataSource">
                    <div class="transferencia">
                        <div class="div">
                            <div class="msjError">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            </div>
                            <div class="d-flex justify-content-between mt-2">
                                <div class="card-body text-center">
                                   
                                    <h1>¡Error en transferencia!</h1>
                                   
                                    <small></small>
                                    <div class="importe d-grid justify-content-center mt-3">
                                       
                                    </div>
                    
                                </div>
                            
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-footer text-center border-top-0">
                    <!-- <button class="btn btn-green m-3" (click)="habilitaMensajeFinal()"> Transferir</button>-->
                      
                        <button class="btn btn m-3" (click)="volver()">Volver</button>
                    </div>
                </div>
            </div>

</div>

    



