<mat-card>
    <mat-card-header>
        <mat-card-title>{{comision.medio}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="comisionClienteFormGroup">
            <mat-form-field appearance="fill">
                <mat-label>Comision</mat-label>
                <input matInput type="number" placeholder="Comision" formControlName="comision"
                    aria-label="Alicuota de cobro para SIRTAC" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Gasto</mat-label>
                <input matInput type="number" placeholder="Comision" formControlName="gasto" />
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="accent" (click)="toggleEdit()" *ngIf="isDisabled">EDITAR</button>
        <button mat-raised-button color="primary" (click)="saveNewData(comisionClienteFormGroup)"
            *ngIf="!isDisabled">GUARDAR</button>
    </mat-card-actions>
</mat-card>