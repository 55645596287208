 <div class="material-progress-bar-div mb-3" *ngIf="true"> <!--loading -->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- <div *ngIf="dataSource" class="row"> -->
<div *ngIf="true" class="row">
    <div class="col-lg-5 col-md-5 col-sm-12">
        <app-buscador (valorBusqueda)="filtrar($event)"></app-buscador>
    </div>
    <div class="col-lg-7 col-md-7 col-sm-12 mb-lg-0 mb-md-3 mb-sm-3 auto">
        <app-enviar-comentario></app-enviar-comentario>
    </div>
</div>

<div>
    <app-grafico-torta></app-grafico-torta>
</div>


