import { NavigationHandlerService } from '../services/navigation-list-handler.service';
import { INavigationNode } from '../types/nav-node.type';

export const rendicionesChildren: INavigationNode[] = [
  {
    title: 'Simples',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('simples'),
  },
  {
    title: 'Distribuidas',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('distribuidas'),
  },
];

export const archivosTransmisionChildren: INavigationNode[] = [
  {
    title: 'Rapipago',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('rapipago'),
  },
  {
    title: 'Pago Fácil',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('pagofacil'),
  },
  {
    title: 'Provincia Net',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('provincianet'),
  },
  {
    title: 'Multipago',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('multipago'),
  },
  {
    title: 'Ripsa',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('ripsa'),
  },
  {
    title: 'Bica Ágil',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('bicaagil'),
  },
  {
    title: 'Cobro Express',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('cobroexpress'),
  },
  {
    title: 'Pronto Pago',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('prontopago'),
  },
];

export const conciliacionesChildren: INavigationNode[] = [
  // {
  //   title: 'Tarjetas',
  //   tooltip: '',
  //   href: NavigationHandlerService.CHILDREN_URL('consultaTarjeta'),
  // },
  {
    title: 'Subir archivo csv tarjeta',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('consultaTarjeta'),
  },
  {
    title: 'Efectivo',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('consulta-mpEfectivo'),
  },
  {
    title: 'Consulta efectivo',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('consulta'),
  },
  {
    title: 'Tarjeta Naranja',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('tarjeta-naranja'),
  },
 /* {
    title: 'Conciliación tarjeta naranja',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('resultadosTarjeta'),
  },*/
  {
    title: 'Subir archivo csv tarjeta naranja',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('upload-naranja'),
  },
];

export const cobranzaChildren: INavigationNode[] = [
  {
    title: 'Lista Cobranzas',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL(''),
  },
];

export const liquidacionChildren: INavigationNode[] = [
  {
    title: 'Generar liquidación',
    tooltip: '',
    href: NavigationHandlerService.CHILDREN_URL('generar-liquidacion'),
  },
];
