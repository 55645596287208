<form [formGroup]="userDataFormGroup">
  <mat-form-field>
    <input
      matInput
      placeholder="Username"
      formControlName="username"
      required
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="password"
      placeholder="Password"
      formControlName="password"
      required
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="password"
      placeholder="Confirm Password"
      formControlName="confirmPassword"
      required
    />
  </mat-form-field>

  <button
    matStepperPrevious
    mat-raised-button
    color="primary"
    (click)="submitFormGroup(userDataFormGroup)"
    [disabled]="!userDataFormGroup.valid"
  >
    Volver
  </button>
  <button
    matStepperNext
    mat-raised-button
    color="primary"
    (click)="submitFormGroup(userDataFormGroup)"
    [disabled]="!userDataFormGroup.valid"
  >
    Siguiete
  </button>
</form>
