import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchivoProcesadoService {

  private url: string;
  constructor(private http: HttpClient) { this.url  =  environment.endpoint }

 
}
