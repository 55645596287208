<section id="top-navigation">
  <div>
    <img [src]="LogoHelipagos" alt="Logo Header" />
    <h3 class="header-logo-text">| ADMIN</h3>
  </div>
  <article>
    <h3>{{ header }}</h3>
    <!-- Toggler, solamente se ve el boton de cerrar sesion en escritorios -->
    <button (click)="logOut()" class="off-sesion">Cerrar sesion</button>
    <!-- Solo se ve en tablets y vistas menores -->
    <button (click)="showToggleHandler()" class="toggle-sidebar">
      <img [src]="toggleIcon" alt="Toggle menu builder" />
    </button>
  </article>
</section>
