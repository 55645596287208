<div class="content animated fadeIn">
    <div class="row  justify-content-center">
        <div class="column  mt-3 col-lg-6">
            <!--Consulta-->
            <div class="card">
                <div class="card-header">
                    <h4>Nueva liquidación</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="crearLiquidacion">
                        <div class="card">
                            <div class="row justify-content-center">
                                <!--Clientes-->
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="cliente" class="form-label">Cliente</label>
                                        <select formControlName="cliente" class="form-select" aria-label="Default select example" [ngClass]="{ 'is-invalid border-red': submitted && form.cliente.errors }" (change)="getRendiciones($event.target.value)">
                                            <option selected disabled hidden value="" >Todos</option>
                                            <option *ngFor="let cliente of listaClientes" value="{{ cliente.id }}">{{ cliente.username }} - {{cliente.razon_social }}</option>
                                        </select>
                                        <div *ngIf="submitted && form.cliente.errors" class="invalid-feedback mt-2">
                                            <div *ngIf="form.cliente.errors.required">Seleccione un cliente</div>
                                        </div>
                                    </div>

                                </div>
                                <!--Rendición-->
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="num_rendicion" class="form-label">N° Rendición</label>
                                        <select formControlName="num_rendicion" class="form-select" aria-label="Default select example" [ngClass]="{ 'is-invalid border-red': submitted && form.num_rendicion.errors }" (change)="setImportes(rendicion.value)" #rendicion>
                                            <option selected disabled hidden value="" >Todos</option>
                                            <option *ngFor="let rendicion of listaRendiciones" value="{{ rendicion.id }}">{{ rendicion.id }} - {{ rendicion.estado.estado }} - {{ rendicion.fecha_a_acreditar | date:"ddMM/yyyy" }} {{ rendicion.fecha_acreditado | date:"dd/MM/yyyy" }}</option>
                                        </select>
                                        <div *ngIf="submitted && form.num_rendicion.errors" class="invalid-feedback mt-2">
                                            <div *ngIf="form.num_rendicion.errors.required">Seleccione una rendición</div>
                                        </div>
                                    </div>
                                </div>
                                <!--Importe total-->
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="descripcion" class="form-label">Importe total</label>
                                        <input inputmode="numeric" placeholder="0,00" style="padding-left: 28px;" id="importe_total" formControlName="importe_total" class="input form-control" (keyup)="formatToNumber($event)" autocomplete="off">
                                        <div class="dollar">
                                            <i class="far fa-dollar-sign"></i>
                                        </div>
                                        <div *ngIf="submitted && form.importe_total.errors" class="invalid-feedback mt-2">
                                            <div *ngIf="form.descripcion.errors.required">Debe tener un importe total</div>
                                        </div>
                                    </div>
                                </div>
                                <!--Importe liquidado-->
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="monto" class="form-label">Importe liquidado</label>
                                        <input style="padding-left: 28px;" formControlName="importe_liquidado" id="importe_liquidado" maxlength="10" inputmode="numeric" placeholder="0,00" class="input form-control" (keyup)="formatToNumber($event)" autocomplete="off">
                                        <div class="dollar">
                                            <i class="far fa-dollar-sign"></i>
                                        </div>
                                        <div *ngIf="submitted && form.importe_liquidado.errors" class="invalid-feedback mt-2">
                                            <div *ngIf="form.monto.errors.required">Debe tener importe liquidado </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Importe comisión-->
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="monto" class="form-label">Importe comisión</label>
                                        <input style="padding-left: 28px;" formControlName="importe_comision" id="importe_comision" maxlength="10" inputmode="numeric" placeholder="0,00" class="input form-control" (keyup)="formatToNumber($event)" autocomplete="off">
                                        <div class="dollar">
                                            <i class="far fa-dollar-sign"></i>
                                        </div>
                                        <div *ngIf="submitted && form.importe_comision.errors" class="invalid-feedback  mt-2">
                                            <div *ngIf="form.monto.errors.required">Debe tener importe comisión</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="buttons d-flex justify-content-end">
                            <button type="button" class="btn btn-white" (click)="limpiar()">Limpiar</button>
                            <!-- <button type="button" (click)="buscarConsulta() " class="btn btn-green ml-15 ">Buscar</button> -->
                            <button type="button" (click)="onSubmit(crearLiquidacion.value) " class="btn btn-green ml-15 ">Crear liquidación</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<swal #mensajeSwal title icon confirmButtonText cancelButtonText showCancelButton cancelButtonColor confirmButtonColor confirm></swal>