<div class="mat-container-table">
  <div class="table-content">
    <!-- Contenido -->
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="table-limits"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay"
      >
        <th mat-header-cell *matHeaderCellDef>
          {{ allColumnsObject[column] }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[column] ?? "-" }}</td>
      </ng-container>
      <!-- Expanded column -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="example-element-detail"
            *ngIf="clientDetail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <clientes-card [cliente]="clientDetail"></clientes-card>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="getClientDetail(element)"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
    <!-- Paginacion -->
    <mat-paginator
      class="pagination-limits"
      [pageSizeOptions]="matPaginationSettings.AvailablePaginationRange"
      [length]="matPaginationSettings.TotalElements"
      [pageSize]="matPaginationSettings.PaginationDefault"
      aria-label="Select page of GitHub search results"
      (page)="setterPagination($event)"
    ></mat-paginator>
  </div>
  <div class="table-panel">
    <h3>Campos de busqueda</h3>
    <mat-form-field appearance="standard">
      <mat-label>Busqueda</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        aria-label="Campo de busqueda para filtrado"
        placeholder="ID, nombre, entre otros"
        #input
      />
    </mat-form-field>

    <button mat-button [routerLink]="'/dashboard/clientes/nuevo'">
      Nuevo Cliente
    </button>
  </div>
</div>
