<div class="row justify-content-center animated fadeIn">
    <div class="col-lg-4 col-md-8 col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4>¡Compartí tu link de pago como quieras!</h4>
            </div>
            <div class="card-body">
                <form action="">
                    <div class="row align-items-center">
                        <div class="w-75">
                            <input type="text" class="input form-control" value="{{ link_pago }}">
                        </div>
                        <div class="w-25">
                            <button class="btn btn-green" type="button" (click)="copyMessage(link_pago)">Copiar link</button>
                        </div>

                    </div>
                </form>
                <div class="links">
                    <!--WhastApp-->
                    <a href="{{ link_whatsapp }}" data-action="share/whatsapp/share" target="_blank ">
                        <i class="fab fa-whatsapp"></i>
                    </a>
                    <!--Facebook-->
                    <a href=" ">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-around">
            <a [routerLink]="'../nvo_btn_pago'">Crear uno nuevo</a>

            <a [routerLink]="'../lista_btn_pago'">Ir al listado</a>
        </div>
    </div>
</div>