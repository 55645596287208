<div class="sidebar" id="sidebar">
  <div class="brand">
    <img
      src="../../../assets/img/logo-helipagos.svg"
      alt=""
      [routerLink]="'dashboard/movimientos'"
    />
  </div>
  <div class="sidebar-content">
    <!-- <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#miCuenta" aria-expanded="true" aria-controls="flush-collapseTwo">
            <a routerLinkActive="active" *ngIf="isAuthorized" (click)="cambioUrl('miCuenta')" [routerLink]="['miCuenta']"><i class="fad fa-home-lg-alt"></i> Mi cuenta</a>
        </button> -->
    <!-- <a class="link" routerLinkActive="active" (click)="cambioUrl('miCuenta')" *ngIf="isAuthorized" [routerLink]="['miCuenta']">
            <i class="fad fa-money-bill"></i> Mi cuenta
        </a> -->
    <!-- <div id="miCuenta" class="accordion-collapse collapse"  [ngClass]="{'show': url == 'miCuenta'}" aria-labelledby="flush-headingTwo">
            <a class="link" routerLinkActive="active" (click)="cambioUrl('miCuenta')" *ngIf="isAuthorized" [routerLink]="['saldos']">Saldos</a>
            <a class="link" routerLinkActive="active" (click)="cambioUrl('miCuenta')" *ngIf="isAuthorized" [routerLink]="['movimientos']">Movimientos</a>
        </div>
       -->
    <!-- <a class="link" routerLinkActive="active" (click)="cambioUrl('pagos')" *ngIf="isAuthorized" [routerLink]="['pagos']">
            <i class="fad fa-money-bill"></i>Pagos
        </a> -->

    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/movimientos')"
      [routerLink]="['dashboard/movimientos']"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <i class="fad fa-sort-alt"></i>Movimientos</a
    >

    <button
      class="accordion-button collapsed"
      *ngIf="isAdmin"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#rendiciones"
      aria-expanded="false"
      aria-controls="flush-collapseTwo"
    >
      <a
        routerLinkActive="active"
        *ngIf="isAuthorized"
        (click)="cambioUrl('dashboard/rendiciones')"
        [routerLink]="['dashboard/rendiciones']"
        ><i class="fad fa-file-import"></i> Rendiciones</a
      >
    </button>

    <div
      id="rendiciones"
      class="accordion-collapse collapse"
      [ngClass]="{ show: url == 'rendiciones' }"
      aria-labelledby="flush-headingTwo"
      *ngIf="isAuthorized"
    >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/rendiciones')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/rendiciones/simples']"
        >Simples</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/rendiciones')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/rendiciones/distribuidas']"
        >Distribuidas</a
      >
    </div>

    <button
      class="accordion-button collapsed"
      (click)="cambioUrl('dashboard/liquidacion')"
      *ngIf="isAdmin"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#liquidacion"
      aria-expanded="false"
      aria-controls="flush-collapseTwo"
    >
      <a routerLinkActive="active" *ngIf="isAuthorized">
        <i class="fad fa-money-check-edit-alt"></i>
        Liquidaciones
      </a>
    </button>
    <!-- <div id="liquidacion" class="accordion-collapse collapse" [ngClass]="{'show': url == 'liquidacion'}" aria-labelledby="flush-headingTwo" *ngIf="isAuthorized">
            <a class="link" routerLinkActive="active" (click)="cambioUrl('liquidacion')" *ngIf="isAuthorized" [routerLink]="['liquidacion/consulta']">Consultar liquidaciones</a>
            
        </div> -->
    <!--<a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('transferencia')" [routerLink]="['transferencia']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fas fa-exchange"></i>Transferencia CBU</a> -->

    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/archivo_base')"
      [routerLink]="['dashboard/archivo_base']"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <i class="fad fa-sort-alt"></i>Archivo Base</a
    >

    <button
      class="accordion-button collapsed"
      *ngIf="isAdmin"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#archivoTransmision"
      aria-expanded="false"
      aria-controls="flush-collapseTwo"
    >
      <a
        routerLinkActive="active"
        *ngIf="isAuthorized"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision')"
        [routerLink]="['dashboard/transacciones/archivosTransmision']"
        ><i class="fad fa-file-import"></i> Archivos de transmisión</a
      >
    </button>

    <div
      id="archivoTransmision"
      class="accordion-collapse collapse"
      [ngClass]="{ show: url == 'transacciones' }"
      aria-labelledby="flush-headingTwo"
    >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision/rapipago')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/rapipago']"
        >Rapipago</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision/pagofacil')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/pagofacil']"
        >Pago Fácil</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="
          cambioUrl('dashboard/transacciones/archivosTransmision/provincianet')
        "
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/provincianet']"
        >Provincia Net</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision/multipago')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/multipago']"
        >Multipago</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision/ripsa')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/ripsa']"
        >Ripsa</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision/bicaagil')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/bicaagil']"
        >Bica Ágil</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="
          cambioUrl('dashboard/transacciones/archivosTransmision/cobroexpress')
        "
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/cobroexpress']"
        >Cobro Express</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/transacciones/archivosTransmision/prontopago')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/transacciones/archivosTransmision/prontopago']"
        >Pronto Pago</a
      >
    </div>

    <button
      class="accordion-button collapsed"
      *ngIf="isAdmin"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#conciliaciones"
      aria-expanded="false"
      aria-controls="flush-collapseTwo"
    >
      <a
        routerLinkActive="active"
        *ngIf="isAuthorized"
        (click)="cambioUrl('dashboard/conciliaciones')"
        [routerLink]="['dashboard/conciliaciones/consultaTarjeta']"
        ><i class="fad fa-file-import"></i> Conciliaciones</a
      >
    </button>
    <div
      id="conciliaciones"
      class="accordion-collapse collapse"
      [ngClass]="{ show: url == 'conciliaciones' }"
      aria-labelledby="flush-headingTwo"
      *ngIf="isAuthorized"
    >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/conciliaciones/consultaTarjeta')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/conciliaciones/consultaTarjeta']"
        >Tarjetas</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/conciliaciones/consulta-mpEfectivo')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/conciliaciones/consulta-mpEfectivo']"
        >Efectivo</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/conciliaciones/consulta')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/conciliaciones/consulta']"
        >Consulta efectivo</a
      >
      <a
        class="link"
        routerLinkActive="active"
        (click)="cambioUrl('dashboard/conciliaciones/tarjeta-naranja')"
        *ngIf="isAuthorized"
        [routerLink]="['dashboard/conciliaciones/tarjeta-naranja']"
        >Tarjeta naranja</a
      >
    </div>

    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/usuarios')"
      [routerLink]="['dashboard/usuarios']"
    >
      <i class="fad fa-users"></i>Usuarios</a
    >

    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/clientes')"
      [routerLink]="['dashboard/clientes']"
    >
      <i class="fad fa-users-cog"></i>Clientes</a
    >

    <!-- <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('facturacion')" [routerLink]="['facturacion']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fad fa-file-invoice-dollar"></i>Facturación</a> -->

    <!-- <a class="link" (click)="cambioUrl('informes')" *ngIf="isAdmin">
        <i class="fad fa-file-chart-line"></i>Informes</a> -->
    <!-- <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('lista_btn_pago')" [routerLink]="['lista_btn_pago']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fas fa-share-alt-square"></i> Botón de pago</a> -->
    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/reportes')"
      [routerLink]="['reportes']"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <i class="fad fa-file-alt"></i>Reportes</a
    >

    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/whastsapp')"
      [routerLink]="['whastsapp']"
    >
      <i class="fa fa-whatsapp"></i>Generar reporte Whatsapp</a
    >

    <a
      class="link"
      *ngIf="isAdmin"
      routerLinkActive="active"
      (click)="cambioUrl('dashboard/gestion-reportes')"
      [routerLink]="['gestion-reportes']"
    >
      <i class="fa fa-cogs"></i>Gestión de Reportes</a
    >

    <!-- <a class="link" (click)="cambioUrl('configuracion')" *ngIf="isAuthorized">
            <i class="fad fa-cogs"></i>Configuración</a> -->

    <!-- <a class="link" *ngIf="isAuthorized" routerLinkActive="active" (click)="cambioUrl('contacto')" [routerLink]="['contacto']">
            <i class="fad fa-envelope"></i>Contacto</a> -->

    <!--  <button class="accordion-button collapsed" *ngIf="isAdmin" type="button" data-bs-toggle="collapse" data-bs-target="#cvu" aria-expanded="false" aria-controls="flush-collapseTwo">
                <a routerLinkActive="active" *ngIf="isAuthorized" (click)="cambioUrl('cvu')" [routerLink]="['cvu']"><i class="fas fa-university"></i> Cvu</a>
            </button>
    
            <div id="cvu" class="accordion-collapse collapse" [ngClass]="{'show': url == 'cvu'}" aria-labelledby="flush-headingTwo" *ngIf="isAuthorized">
               <a class="link" routerLinkActive="active" (click)="cambioUrl('cvu')" *ngIf="isAuthorized" [routerLink]="['cvu/consulta-cvu']">Consultar</a>
                <a class="link" routerLinkActive="active" (click)="cambioUrl('cvu')" *ngIf="isAuthorized" [routerLink]="['cvu/alta-cvu']">Nuevo</a>
                
            </div>-->
  </div>
  <div class="version text-center">
    <small>Versión {{ version }}</small>
  </div>
</div>


<div class="sidebar" id="sidebar">
    <div class="brand">
        <img src="../../../assets/img/logo-helipagos.svg" alt="" [routerLink]="'dashboard/movimientos'">
    </div>
    <div class="sidebar-content">

        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/movimientos')" [routerLink]="['dashboard/movimientos']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fad fa-sort-alt"></i>Movimientos</a>

        <button class="accordion-button collapsed" *ngIf="isAdmin" type="button" data-bs-toggle="collapse" data-bs-target="#rendiciones" aria-expanded="false" aria-controls="flush-collapseTwo">
            <a routerLinkActive="active" *ngIf="isAuthorized" (click)="cambioUrl('dashboard/rendiciones')" [routerLink]="['dashboard/rendiciones']"><i class="fad fa-file-import"></i> Rendiciones</a>
        </button>



        <div id="rendiciones" class="accordion-collapse collapse" [ngClass]="{'show': url == 'rendiciones'}" aria-labelledby="flush-headingTwo" *ngIf="isAuthorized">
            <a class="link" routerLinkActive="active" (click)="cambioUrl('dashboard/rendiciones')" *ngIf="isAuthorized" [routerLink]="['dashboard/rendiciones/simples']">Simples</a>
            <a class="link" routerLinkActive="active" (click)="cambioUrl('dashboard/rendiciones')" *ngIf="isAuthorized" [routerLink]="['dashboard/rendiciones/distribuidas']">Distribuidas</a>
        </div>

        <button class="accordion-button collapsed" (click)="cambioUrl('dashboard/liquidacion')" *ngIf="isAdmin" type="button" data-bs-toggle="collapse" data-bs-target="#liquidacion" aria-expanded="false" aria-controls="flush-collapseTwo">
            <a routerLinkActive="active" *ngIf="isAuthorized">
                <i class="fad fa-money-check-edit-alt"></i>
                Liquidaciones
            </a>
        </button>

        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/archivo_base')" [routerLink]="['dashboard/archivo_base']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fad fa-sort-alt"></i>Archivo Base</a>

        <button class="accordion-button collapsed" *ngIf="isAdmin" type="button" data-bs-toggle="collapse" data-bs-target="#archivoTransmision" aria-expanded="false" aria-controls="flush-collapseTwo">
            <a routerLinkActive="active" *ngIf="isAuthorized" (click)="cambioUrl('dashboard/transacciones/archivosTransmision')" [routerLink]="['dashboard/transacciones/archivosTransmision']"><i class="fad fa-file-import"></i> Archivos de transmisión</a>
        </button>

        <div id="archivoTransmision" class="accordion-collapse collapse" [ngClass]="{'show': url == 'transacciones'}" aria-labelledby="flush-headingTwo">
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/rapipago']">Rapipago</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/pagofacil']">Pago Fácil</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/provincianet']">Provincia Net</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/multipago']">Multipago</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/ripsa']">Ripsa</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/bicaagil']">Bica Ágil</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/cobroexpress']">Cobro Express</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['transacciones/archivosTransmision/prontopago']">Pronto Pago</a>
        </div>
      
        <button class="accordion-button collapsed" *ngIf="isAdmin" type="button" data-bs-toggle="collapse" data-bs-target="#conciliaciones" aria-expanded="false" aria-controls="flush-collapseTwo">
            <a routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['conciliaciones/consultaTarjeta']"><i class="fad fa-file-import"></i> Conciliaciones</a>
        </button>
        <div id="conciliaciones" class="accordion-collapse collapse" [ngClass]="{'show': url == 'conciliaciones'}" aria-labelledby="flush-headingTwo" *ngIf="isAuthorized">
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['conciliaciones/consultaTarjeta']">Tarjetas</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['conciliaciones/consulta-mpEfectivo']">Efectivo</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['conciliaciones/consulta']">Consulta efectivo</a>
            <a class="link" routerLinkActive="active" *ngIf="isAuthorized" [routerLink]="['conciliaciones/tarjeta-naranja']">Tarjeta naranja</a>
        </div>

        




        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/usuarios')" [routerLink]="['dashboard/usuarios']">
            <i class="fad fa-users"></i>Usuarios</a>

        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/clientes')" [routerLink]="['dashboard/clientes']">
            <i class="fad fa-users-cog"></i>Clientes</a>



        <!-- <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('facturacion')" [routerLink]="['facturacion']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fad fa-file-invoice-dollar"></i>Facturación</a> -->

        <!-- <a class="link" (click)="cambioUrl('informes')" *ngIf="isAdmin">
        <i class="fad fa-file-chart-line"></i>Informes</a> -->
        <!-- <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('lista_btn_pago')" [routerLink]="['lista_btn_pago']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fas fa-share-alt-square"></i> Botón de pago</a> -->
        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/reportes')" [routerLink]="['reportes']" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fad fa-file-alt"></i>Reportes</a>

        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/whastsapp')" [routerLink]="['whastsapp']">
            <i class="fa fa-whatsapp"></i>Generar reporte Whatsapp</a>
            
        <a class="link" *ngIf="isAdmin" routerLinkActive="active" (click)="cambioUrl('dashboard/gestion-reportes')" [routerLink]="['gestion-reportes']">
            <i class="fa fa-cogs"></i>Gestión de Reportes</a>
           

        <!-- <a class="link" (click)="cambioUrl('configuracion')" *ngIf="isAuthorized">
            <i class="fad fa-cogs"></i>Configuración</a> -->

        <!-- <a class="link" *ngIf="isAuthorized" routerLinkActive="active" (click)="cambioUrl('contacto')" [routerLink]="['contacto']">
            <i class="fad fa-envelope"></i>Contacto</a> -->


           <!--  <button class="accordion-button collapsed" *ngIf="isAdmin" type="button" data-bs-toggle="collapse" data-bs-target="#cvu" aria-expanded="false" aria-controls="flush-collapseTwo">
                <a routerLinkActive="active" *ngIf="isAuthorized" (click)="cambioUrl('cvu')" [routerLink]="['cvu']"><i class="fas fa-university"></i> Cvu</a>
            </button>
    
            <div id="cvu" class="accordion-collapse collapse" [ngClass]="{'show': url == 'cvu'}" aria-labelledby="flush-headingTwo" *ngIf="isAuthorized">
               <a class="link" routerLinkActive="active" (click)="cambioUrl('cvu')" *ngIf="isAuthorized" [routerLink]="['cvu/consulta-cvu']">Consultar</a>
                <a class="link" routerLinkActive="active" (click)="cambioUrl('cvu')" *ngIf="isAuthorized" [routerLink]="['cvu/alta-cvu']">Nuevo</a>
                
            </div>-->
    </div>
    <div class="version text-center">
        <small>Versión 1.21.11</small>
    </div>
</div>