<div class="content animated fadeIn">
    <div class="row  justify-content-center">
        <div class="column  mt-3 col-lg-8">
            <!--Consulta-->
            <div class="card">
                <div class="card-header">
                    <h4>Nuevo botón de pago</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="crearBotonPago">
                        <div class="card">
                            <div class="row justify-content-center">
                                <!--Título-->
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="titulo" class="form-label">Título</label>
                                        <input type="text" formControlName="titulo" placeholder="Título" class="input form-control">
                                    </div>
                                    <div *ngIf="submitted && form.titulo.errors" class="invalid-feedback">
                                        <div *ngIf="form.titulo.errors.required">Debe tener un título</div>
                                    </div>
                                </div>
                                <!--Descripción-->
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="descripcion" class="form-label">Descripción</label>
                                        <input type="text" formControlName="descripcion" placeholder="Descripción" class="input form-control">
                                    </div>
                                    <div *ngIf="submitted && form.descripcion.errors" class="invalid-feedback">
                                        <div *ngIf="form.descripcion.errors.required">Debe tener una descripción</div>
                                    </div>
                                </div>
                                <!--Monto-->
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="monto" class="form-label">Monto</label>
                                        <input type="text" formControlName="monto" digitOnly [decimal]="decimal" maxlength="10" inputmode="numeric" placeholder="$0,00" class="form-control" autocomplete="off">
                                    </div>
                                    <div *ngIf="submitted && form.monto.errors" class="invalid-feedback">
                                        <div *ngIf="form.monto.errors.required">Debe tener un precio</div>
                                    </div>
                                </div>
                                <!--Vencimiento-->
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="descripcion" class="form-label">Vencimiento</label>
                                        <input formControlName="vencimiento" class="input form-control" type="date" [ngClass]="{ 'is-invalid': submitted && form.vencimiento.errors }">
                                        <div *ngIf="submitted && form.vencimiento.errors" class="invalid-feedback">
                                            <div *ngIf="form.vencimiento.errors.required">Este campo es necesario</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="buttons ">
                            <button type="button " class="btn btn-white" (click)="limpiar()">Limpiar</button>
                            <!-- <button type="button" (click)="buscarConsulta() " class="btn btn-green ml-15 ">Buscar</button> -->
                            <button type="button" (click)="onSubmit(crearBotonPago.value) " class="btn btn-green ml-15 ">Crear y compartir</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<swal #mensajeSwal title icon confirmButtonText cancelButtonText showCancelButton cancelButtonColor confirmButtonColor confirm></swal>