import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CommonsService } from 'src/app/core/commons.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { LiquidacionService } from 'src/app/services/liquidacion.service';
import { ClientesService } from '../../../clientes/services/clientes.service';
declare var jQuery: any;
@Component({
  selector: 'consulta',
  templateUrl: './liquidacion-consulta.component.html',
  styleUrls: ['./liquidacion-consulta.component.css'],
})
export class LiquidacionConsultaComponent implements OnInit {
  consultaForm: UntypedFormGroup;
  selectable = true;
  addOnBlur = true;
  removable = true;
  submitted: boolean;
  dateIsSelected: boolean;
  formularioConsulta = true;
  listaClientes;
  // readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Output() submitEmitter = new EventEmitter<any>(); // Please give me a model too

  @ViewChild('inputSimulate') input_simulate_numbers: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private _Clientes: ClientesService,
    private _Commons: CommonsService,
  ) {}

  ngOnInit(): void {
    //Arma el formulario
    this.makeForm();
  }

  makeForm() {
    //Form Builder
    this.consultaForm = this.formBuilder.group({
      num_liquidacion: [''],
      cliente: [''],
      num_rendicion: [''],
      desdeCreacion: new UntypedFormControl(''),
      hastaCreacion: new UntypedFormControl(''),
      distribuida: [''],
    });

    //lista de clientes
    this._Clientes
      .getClientesPromise()
      .then((response) => {
        this.listaClientes = response;
        // this._functionsService.imprimirMensaje(this.listaClientes, "Clientes: ");
      })
      .catch((error: any) => {
        // this._functionsService.imprimirMensaje(error,"error al cargar la lista de clientes");
      });

    //DatePicker max && min
    var today = Date.now();
    var hoy = this.datePipe.transform(today, 'yyyy-MM-dd');
    var datePickers = ['to', 'from'];

    jQuery.each(datePickers, function (ind, elem) {
      jQuery('#' + elem).attr({
        max: hoy,
      });
    });
  }

  //==== Funciones de los formularios ====

  onSubmit(formData) {
    this.submitted = true;
    //Formulario inválido
    if (this.consultaForm.invalid) {
      return;
    }
    this.submitEmitter.emit(this._Commons.parseQueryString(formData));
  }

  limpiar() {
    this.submitted = false;
    this.consultaForm.reset();
    this.dateIsSelected = false;
    this.makeForm();
  }

  //controls
  get form() {
    return this.consultaForm.controls;
  }
  get desdeCreacion() {
    return this.consultaForm.get('desdeCreacion') as UntypedFormControl;
  }
  get hastaCreacion() {
    return this.consultaForm.get('hastaCreacion') as UntypedFormControl;
  }

  //Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
  datepicker() {
    //Toma los datos del formulario
    var dateFrom = this.consultaForm.get('desdeCreacion').value;
    var dateTo = this.consultaForm.get('hastaCreacion').value;

    //Si no está vacío
    if (dateFrom != '') {
      jQuery('#to').attr({
        min: dateFrom,
      });
    }

    if (dateTo != '') {
      jQuery('#from').attr({
        max: dateTo,
      });
    }
  }

  //Rango de fechas (validación)
  dateChange(e) {
    this.dateIsSelected = true;

    if (e.target.value == '') {
      //Remueve la validación
      this.desdeCreacion.clearValidators();
      this.hastaCreacion.clearValidators();
      //Actualiza
      this.desdeCreacion.updateValueAndValidity();
      this.hastaCreacion.updateValueAndValidity();
    } else {
      //Añade validación
      this.desdeCreacion.setValidators([Validators.required]);
      this.hastaCreacion.setValidators([Validators.required]);
      //Actualiza
      this.desdeCreacion.updateValueAndValidity();
      this.hastaCreacion.updateValueAndValidity();
    }
  }
  EnterSubmit(event, form) {
    if (event.keyCode === 13) this.onSubmit(form);
  }
}
