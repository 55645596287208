import { Routes } from '@angular/router';
import {TerminalListComponent} from './terminal-list/terminal-list.component';
import {TerminalService} from './terminal.service';

export const TERMINAL_ROUTES: Routes = [
  { path: '', component: TerminalListComponent },
  { path: 'utilizadas', component: TerminalListComponent },
  { path: 'asociar-con-usuario', component: TerminalListComponent }
];

