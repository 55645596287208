
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserInterface } from 'src/app/models/interfaces/user.interface';
import { Roles } from 'src/app/models/roles.models';
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Cliente } from 'src/app/models/cliente.models';
import { ErrorStateMatcher1 } from 'src/app/error-state-matcher1';
@Component({
  selector: 'app-modal-usuario',
  templateUrl: './modal-usuario.component.html',
  styleUrls: ['./modal-usuario.component.css']
})
export class ModalUsuarioComponent implements OnInit {

  @Input() showHeader = true;
  @Input() btnAcept: string = "Aceptar"
  @Input() usuario;
  @Input() showSpinner: boolean = false;
  @Output() usuarioEditado: EventEmitter<UserInterface>;
  @Output() usuarioCreado: EventEmitter<UserInterface>;
  @Output() mostrarLista: EventEmitter<Boolean>;
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;
  matcher = new ErrorStateMatcher1();
  public idCliente:any;
  hide = true;
  hideConf = true;
  loginForm: UntypedFormGroup;
  placeholder;
  roles;
  listaRoles:any;
  listaClientes: any;
  cliente: Cliente;
  clienteAsignado: any;
  constructor(
    private formBuilder:UntypedFormBuilder,
    private apiService: ApiService,
    private _functionsService: FunctionsService
     ) {
      this.usuarioEditado = new EventEmitter();
      this.mostrarLista = new EventEmitter();
      this.loginForm = this.formBuilder.group({
        nombre: ['', Validators.required],
        username: ['', Validators.required],
        email: ['', Validators.required],
        estado: ['', Validators.required],
        password: ['', Validators.required],
        rol: ['', Validators.required],
        cliente: ['', Validators.required],
      });

     }

  ngOnInit(): void {
    //Obtiene el listado de Clientes
    this.apiService.getClientes()
    .then((response) =>{
      this.listaClientes = response;
    })
    .catch(()=>{
      this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los clientes`, "error", "Aceptar", "", false, "", "");
      this.mensajeSwal.fire();
    });
    this.apiService.getRoles()
    .then((response) =>{
      this.listaRoles = response;
    })
    .catch(()=>{
      this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los distintos roles`, "error", "Aceptar", "", false, "", "");
      this.mensajeSwal.fire();
    });
    if(this.usuario != null){
      this.loginForm = this.formBuilder.group({
        nombre: [this.usuario.nombre, Validators.required],
        username: [this.usuario.username, [Validators.required, Validators.minLength(4), Validators.maxLength(60)]],
        email: [this.usuario.email, [Validators.required, Validators.email]],
        estado: [this.usuario.enabled, Validators.required],
        password: ['', Validators.required],
        rol: [this.usuario.roles_id, [Validators.required, Validators.minLength(9), Validators.maxLength(60)]],
        cliente: [this.usuario.cliente_id, Validators.required],
      });
    }
  }

  get f(){
    return this.loginForm.controls;
  }

  ngOnChanges() {

      this.loginForm = this.formBuilder.group({
        nombre:['', [Validators.required]],
        username: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]],
        email: ['', [Validators.required, Validators.email]],
        estado: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(60)]],
        rol: ['', Validators.required],
        cliente: ['', Validators.required],
        confirm_password: ['', [Validators.required]]
      },{
        validator: ConfirmedValidator('password', 'confirm_password')
      }
      );

     if(this.usuario != null){
      if(this.usuario.cliente != null){
        this.clienteAsignado = this.usuario.cliente.username + " - " + this.usuario.cliente.razon_social;
        this.idCliente = this.usuario.cliente.id;
      }else{
        this.clienteAsignado = "Seleccionar";
      }
      this.placeholder = {};
      var rolUsuario;
      this.placeholder["nombre"] = this.usuario != null? this.usuario.nombre : "Nombre";
      this.placeholder["username"] = this.usuario != null? this.usuario.username : "Username";
      this.placeholder["email"] = this.usuario != null? this.usuario.email : "Email";
      this.placeholder["estado"] = this.usuario != null? this.usuario.enabled : "Seleccionar";
      this.placeholder["cliente"] = this.usuario != null? this.clienteAsignado : "Seleccionar";

      if(this.usuario.roles =="CLIENTE"){
        rolUsuario = "USER"
      }else{
        rolUsuario = this.usuario.roles;
      }
      this.placeholder["rol"] = this.usuario != null? rolUsuario: "Seleccionar";
     }

  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.loginForm.get(nombreControl);
    return control?.hasError(validacion);
  }

  onSubmit() {

    this.usuario = null;
    let userEnvio;
    userEnvio = this.loginForm.value;

    let rol: Roles;
    let rolList: Roles[] = [];
    let estado;

    if(userEnvio['estado'] != "" && userEnvio['estado'] != null){
      estado = userEnvio['estado'] == "Deshabilitado"? false : true;
    }
      this.listaRoles.filter(
        (item)=> {
          if(item.id === Number(userEnvio['rol'])){
            rol = {
              id: item.id,
              nombre: item.nombre
            }
            rolList.push(rol);
          }
          return rolList;
        }
      );

    let clienteid = userEnvio['cliente'];
    for (let i=0; i< this.listaClientes.length;i++){
      if(this.listaClientes[i].id == clienteid){
        this.cliente = this.listaClientes[i];
      }
    }

    let userModificado: UserInterface = {
      username : userEnvio['username'],
      nombre : userEnvio['nombre'],
      email : userEnvio['email'],
      password : userEnvio['password'],
      enabled : estado,
      roles : rolList,
      cliente_create : this.cliente
    }

    this.usuarioEditado.emit(userModificado)
    this.loginForm.reset();

  }


  atras(){
    this.mostrarLista.emit(true);
  }


}

export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
