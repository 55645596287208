<div *ngIf="_data" class="row">
  <!-- Sistema de busqueda y offset -->
  <div class="table-search">
    <app-number-pagination
      (cantidad)="cargarItems($event)"
    ></app-number-pagination>
    <app-buscador (valorBusqueda)="filtrar($event)"></app-buscador>
  </div>
  <!-- Body -->
  <div class="col-12">
    <div class="animated fadeIn" *ngIf="_data.length > 0">
      <!-- Header con mensajes de resultado y cantidad de numeros -->
      <div class="table-header">
        <h6>{{ mensajeResultado || "Cargando..." }}</h6>
        <div
          class="col-lg-2 col-md-2 col-sm-12 mb-lg-0 mb-md-3 mb-sm-3 auto"
          *ngIf="!configTable.buscar"
        >
          <app-number-pagination
            (cantidad)="cargarItems($event)"
          ></app-number-pagination>
        </div>
      </div>
      <!-- Componente tabla como tal -->
      <div class="table-body">
        <table class="">
          <thead>
            <tr>
              <th *ngIf="indiceItems" class="text-wrap text-center" scope="col">
                Fila
              </th>
              <th
                *ngFor="
                  let head of configTable.headers.columns_show;
                  let i = index
                "
                class="text-wrap text-center"
                scope="col"
              >
                <a
                  class="invert"
                  (click)="sort(configTable.headers.columns_search[i])"
                  (click)="asc = !asc"
                >
                  {{ head }}
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faswap-vert.svg" alt="">
                </a>
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.exportToExcel"
                scope="col"
                (click)="emitirExportarExcel()"
              >
                Exportar
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.verDetalles"
                scope="col"
              >
                Detalles
              </th>
              <th
                class="text-wrap text-center"
                *ngIf="functionCliente"
                scope="col"
              >
                Generar token
              </th>
              <th class="text-center" *ngIf="configTable.hasTerminal">
                Agregar terminal
              </th>
              <th class="text-center" *ngIf="configTable.functionUsuarios" scope="col">
                Editar
              </th>
              <th class="text-center" *ngIf="configTable.functionUsuarios" scope="col">
                Borrar
              </th>
              <th class="text-center" *ngIf="configTable.functionBlacklist" scope="col">
                Editar
              </th>
              <th class="text-center" *ngIf="configTable.functionBlacklist" scope="col">
                Borrar
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.tLiquidacion"
                scope="col"
              >
                Caratula
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.tLiquidacion"
                scope="col"
              >
                Detalles
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.functionPaymentFile"
                scope="col"
              >
                Mod. Estado
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.functionPaymentFile"
                scope="col"
              >
                Editar
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.functionPaymentFile"
                scope="col"
              >
                Generar Archivo
              </th>
              <!-- <th class="text-center detalles" *ngIf="configTable.tLiquidacion" scope="col">Transferir</th> -->
              <th
                class="text-center detalles"
                *ngIf="configTable.tconciliacion"
                scope="col"
              >
                Conciliadas
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.tconciliacion"
                scope="col"
              >
                Conciliar
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.tGestion"
                scope="col"
              >
                Gestionar
              </th>

              <!--Detalles Tarjeta -->
              <th
                class="text-center detalles"
                *ngIf="configTable.verDetallesTarjeta"
                scope="col"
              >
                Costo de servicio<a
                  class="invert"
                  (click)="sort(configTable.headers.columns_search[i])"
                  (click)="asc = !asc"
                >
                  {{ head }}
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faswap-vert.svg" alt="">
                </a>
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.verDetallesTarjeta"
                scope="col"
              >
                Impuestos<a
                  class="invert"
                  (click)="sort(configTable.headers.columns_search[i])"
                  (click)="asc = !asc"
                >
                  {{ head }}
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faswap-vert.svg" alt="">
                </a>
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.verDetallesTarjeta"
                scope="col"
              >
                Total Neto<a
                  class="invert"
                  (click)="sort(configTable.headers.columns_search[i])"
                  (click)="asc = !asc"
                >
                  {{ head }}
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faswap-vert.svg" alt="">
                </a>
              </th>
              <th
                class="text-center detalles"
                *ngIf="configTable.verDetallesTarjeta"
                scope="col"
              ></th>
              <th
                class="text-center detalles"
                *ngFor="let button of buttonsPanel"
              >
                {{ button.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let datos of _data | paginate : pagingSettings">
              <td *ngIf="indiceItems">
                <div class="text-center">{{ i + 1 }}</div>
              </td>
              <!-- Items -->
              <td
                *ngFor="
                  let head of configTable.headers.columns_search;
                  let i = index
                "
                mat-ripple
                (click)="clipboard(datos[head])"
                class="animated fadeIn fast"
              >
                <div
                  class="text-center"
                  [ngClass]="{
                    target: head == 'estado',
                    success: datos[head] == 'ACREDITADA',
                    cancel: datos[head] == 'VENCIDA',
                    VERIFICADA: datos[head] == 'VERIFICADA',
                    REVISION: datos[head] == 'REVISION',
                    info: datos[head] == 'DEVUELTA',
                    APROBADA: datos[head] == 'APROBADA',
                    generada: datos[head] == 'GENERADA',
                    procesada: datos[head] == 'PROCESADA',
                    rechazada: datos[head] == 'RECHAZADA',
                    contracargo: datos[head] == 'CONTRACARGO',
                    transferencia: datos[head] == 'TRANSFERIDA',
                    conciliada: datos[head] == 'CONCILIADA',
                    conciliado: datos[head] == 'CONCILIADO',
                    chargeback: datos[head] == 'CHARGEBACK',
                    contracargo_liquidado: datos[head] == 'CONTRACARGO LIQUIDADO',
                    devolucion_proceso: datos[head] == 'DEVOLUCION EN PROCESO',
                    devolucion_conciliado: datos[head] == 'DEVOLUCION CONCILIADO',
                    duplicada_proceso: datos[head] == 'DUPLICADA EN PROCESO',
                    duplicada_conciliado: datos[head] == 'DUPLICADA CONCILIADO',
                    duplicada: datos[head] == 'DUPLICADA',
                    bloqueada: datos[head] == 'BLOQUEADA',
                    'estado_en_proceso': datos[head] == 'EN PROCESO',
                    'estado_valido': datos[head] == 'PROCESADO',
                    'estado_error': datos[head] == 'ERROR'
                  }"
                >
                  <div *ngIf="head == 'tablaOrigen'" class="text-center"  [ngClass]="{
                    target: head == 'tablaOrigen',
                    'generador': datos[head] == 'Generador',
                    'notificacion': datos[head] == 'Notificación de pago' }">{{ datos[head]||"-" }}</div>

                  <div *ngIf="head != 'medio_pago' && head != 'tablaOrigen'" class="target_estado" >{{ datos[head]||"-" }}</div>
                  <div *ngIf="head == 'medio_pago'" [ngSwitch]="datos.medio_pago" class="medioPago">

                      <img *ngSwitchCase="'Visa Debito'" src="../../../../assets/img/visa_debito.svg">
                      <img *ngSwitchCase="'Visa'" src="../../../../assets/img/visa.svg">
                      <img *ngSwitchCase="'Cabal'" src="../../../../assets/img/cabal.svg">
                      <img *ngSwitchCase="'MasterCard'" src="../../../../assets/img/mastercard.svg">
                      <img *ngSwitchCase="'Cabal Debito'" src="../../../../assets/img/cabal_debito.svg">
                      <img *ngSwitchCase="'American Express'" src="../../../../assets/img/american_express.svg">
                      <img style="height: 25px;" *ngSwitchCase="'Tarjeta Naranja'" src="../../../../assets/img/naranja.svg">
                      <img *ngSwitchCase="'MasterCard Debito'" src="../../../../assets/img/mastercard.svg">
                      <img *ngSwitchCase="'MasterCard Prepaga'" src="../../../../assets/img/mastercard.svg">
                      <img *ngSwitchCase="'Visa Prepaga'" src="../../../../assets/img/visa_debito.svg">
                      <img style="height: 30px;" *ngSwitchCase="'Pago Facil'" src="../../../../assets/img/pago_facil.svg">
                      <img *ngSwitchCase="'Rapipago'" src="../../../../assets/img/rapipago.svg">
                      <img *ngSwitchCase="'BICA Agil'" src="../../../../assets/img/bica_agil.svg">
                      <img *ngSwitchCase="'Cobro Express'" src="../../../../assets/img/cobro_express.svg">
                      <img *ngSwitchCase="'Multipago'" src="../../../../assets/img/multipago.svg">
                      <img *ngSwitchCase="'Pronto Pago'" src="../../../../assets/img/pronto_pago.svg">
                      <img *ngSwitchCase="'Provincia NET'" src="../../../../assets/img/provincia_net.svg">
                      <img *ngSwitchCase="'RIPSA'" src="../../../../assets/img/ripsa.svg">
                        <p class="op" *ngSwitchDefault>
                            {{ datos.medio_pago }}
                          </p>
                    </div>

                </div>
                <!-- <img src="" *ngIf="datos[head]['isImage'] === true" alt="" /> -->
              </td>

              <!--Descargar PDF Liquidación-->
              <td
                class="detalles text-center"
                *ngIf="configTable.tLiquidacion"
              >
                <button
                  *ngIf="datos['detalle']"
                  class="btn-pdf"
                  (click)="
                    emitirDescargarPDF(datos['numero'], datos['id_cliente'])
                  "
                  title="Descargar en PDF"
                >
                <img style="width: 15px; height: 15px;" src="../../../../assets/img/fa-file.svg" alt="">
                </button>
              </td>
              <!--Exportar a Excel-->
              <td
                class="detalles text-center"
                *ngIf="configTable.exportToExcel"
              >
                <button
                  class="btn-excel"
                  (click)="emitirExportarExcel(datos['id'])"
                  title="Exportar detalles a excel"
                >
                <img style="width: 15px; height: 15px;" src="../../../../assets/img/faexcel.svg" alt="">
                </button>
              </td>
              <!--Exportar a Excel Liquidación-->
              <td
                class="detalles text-center"
                *ngIf="configTable.tLiquidacion"
              >
                <button
                  *ngIf="datos['detalle']"
                  class="btn-excel"
                  (click)="
                    emitirExportarExcelLiq(datos['numero'], datos['id_cliente'])
                  "
                  title="Exportar detalles a excel"
                >
                <img style="width: 15px; height: 15px;" src="../../../../assets/img/faexcel.svg" alt="">
                </button>
              </td>
              <td class="detalles text-center" *ngIf="configTable.verDetalles">
                <button
                  class="btn-details"
                  (click)="verDetalles(datos['id'])"
                  title="Ver más detalles"
                >
                <img style="width: 15px; height: 15px;" src="../../../../assets/img/fasearch.svg" alt="">
                </button>
              </td>
              <td class="detalles text-center" *ngIf="configTable.verGestion">
                <button
                  class="btn-details"
                  (click)="verDetalles(datos['numero'])"
                  title="Gestionar liquidacion"
                >
                <img style="width: 15px; height: 15px;" src="../../../../assets/img/fasearch.svg" alt="">
                </button>
              </td>
              <!--Detalles Tarjeta -->

              <td
                class="animated fadeIn fast"
                *ngIf="configTable.verDetallesTarjeta"
              >
                <p
                  class="text-center"
                  [ngClass]="{
                    target: head == 'Costo de servicio',
                    valorNegativo: datos.importe_cs
                  }"
                >
                  {{ "-" + datos.importe_cs || "-" }}
                </p>
              </td>
              <td
                class="animated fadeIn fast"
                *ngIf="configTable.verDetallesTarjeta"
              >
                <p
                  class="text-center"
                  [ngClass]="{
                    target: head == 'Impuestos',
                    valorNegativo: datos.importe_ii
                  }"
                >
                  {{ "-" + datos.importe_ii || "-" }}
                </p>
              </td>
              <td
                class="animated fadeIn fast"
                *ngIf="configTable.verDetallesTarjeta"
              >
                <p class="text-center">{{ datos.importe_neto || "-" }}</p>
              </td>
              <td
                class="detalles text-center"
                *ngIf="configTable.verDetallesTarjeta"
              >
                <p
                  class=""
                  (click)="verDetallesTarjeta(datos['id'])"
                  style="color: #53baab; cursor: pointer"
                  title="Ver más detalles"
                >
                  Ver
                </p>
              </td>
              <!-- Cliente -->
              <!--Crear token-->
              <td class="text-center" *ngIf="functionCliente">
                <button
                  class="btn-details"
                  (click)="modalButtonToken(datos['id'])"
                >
                  <i class="fad fa-sync-alt"></i>
                </button>
              </td>
              <!--Usuarios-->
              <!--Editar Terminal de un usuario-->
              <td class="text-center" *ngIf="configTable.functionAddTerminalToUser">
                <button class="btn-details" (click)="callbackManipulation('USER', datos)">
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faedit-white.svg" alt="">
                </button>
              </td>
              <!--Editar Usuario-->
              <td class="text-center" *ngIf="configTable.functionUsuarios">
                <button class="btn-details" (click)="modalEdit(datos['id'])">
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faedit-white.svg" alt="">
                </button>
              </td>
              <!--Eliminar Usuario-->
              <td class="text-center" *ngIf="configTable.functionUsuarios">
                <button class="btn-delete" (click)="modalDelete(datos['id'])">
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/fadelete-white.svg" alt="">
                </button>
              </td>

              <!--Acciones blacklist-->
              <!--Editar-->
              <td class="text-center" *ngIf="configTable.functionBlacklist">
                <button class="btn-details" (click)="modalEdit(datos['id'])">
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/faedit-white.svg" alt="">
                </button>
              </td>
              <!--Eliminar-->
               <td class="text-center" *ngIf="configTable.functionBlacklist">
                <button class="btn-delete" (click)="modalDelete(datos['id'])">
                  <img style="width: 15px; height: 15px;" src="../../../../assets/img/fatrash-white.svg" alt="">
                </button>
              </td>
               <!--Acciones functionPaymentFile-->
               <td class="text-center" *ngIf="configTable.functionPaymentFile">
                  <button class="btn-deshabilitar" *ngIf="datos.enabled=='Habilitado'"  (click)="enableClientFile(datos.id, datos.enabled,datos.razon_social )" matTooltip="Deshabilitar cliente"> 
                    <img src="../../../assets/img/enabled_false_cleinte.svg">
                  </button>
                  <button class="btn-habilitar" *ngIf="datos.enabled=='Deshabilitado'"  (click)="enableClientFile(datos.id, datos.enabled,datos.razon_social)" matTooltip="Habilitar cliente"> 
                    <img  src="../../../assets/img/enabled_true_cliente.svg">
                  </button>
                </td>
                <td class="text-center" *ngIf="configTable.functionPaymentFile">
                  <button class="btn-habilitar"  matTooltip="Editar" (click)="openDialogEdit(datos.id)" > 
                    <img  src="../../../assets/img/editar_datos.svg">
                  </button>
                </td>
                <td class="text-center" *ngIf="configTable.functionPaymentFile">
                  <button [disabled]="datos.enabled === 'Deshabilitado'"  [ngClass]="{'btn-archivo-generar': (datos.enabled=='Habilitado'),'btn-archivo-bloquear': (datos.enabled=='Deshabilitado')}"  (click)="openDialogGenerar(datos.razon_social,datos.id)" matTooltip="Generar archivo"> 
                    <img  src="../../../assets/img/generar_archivo.svg">
                  </button>
                </td>
              <td class="text-center" *ngIf="configTable.tconciliacion">
                <p
                  [ngClass]="{
                    ceroConciliacion: datos.conciliadas == 0,
                    cienConciliacion: datos.conciliadas == 100,
                    porcentaje: datos.conciliadas > 0 && datos.conciliadas < 100
                  }"
                >
                  {{ datos.conciliadas + "%" || "-" }}
                </p>
              </td>
              <!--Conciliar Efectivo-->
              <td class="text-center" *ngIf="configTable.tconciliacion">
                <p (click)="conciliar(datos['numero'], datos['estado'])">
                  Conciliar
                </p>
              </td>
              <td
                class="text-center detalles"
                *ngFor="let button of buttonsPanel"
              >
                <button
                  *ngIf="!button.iconStyle"
                  style="
                    background-color: #53baab;
                    color: #fff;
                    cursor: pointer;
                    border-radius: 5px;
                  "
                  (click)="emitirClickPanel(datos, button.id)"
                >
                  <img *ngIf="button.icon" [src]="button.icon" alt="svg_icon" [ngClass]="{'color-rojo': true}">
                  {{ button.columnTitle ?? button.title }}
                </button>
                <div
                  *ngIf="button.iconStyle"
                  style="
                    cursor: grab;
                    border-radius: 5px;
                  "
                  (click)="emitirClickPanel(datos, button.id)"
                >
                  <img *ngIf="button.icon" [src]="button.icon" alt="svg_icon" [ngClass]="{'color-rojo': true}">
                  {{ button.columnTitle ?? button.title }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Table footer w/ pagination -->
      <div id="table-footer" class="table-footer">
        <button
          *ngIf="!needsBackButton"
          aria-label="Volver a la pagina anterior"
          class="volver"
          mat-button
          (click)="Volver()"
        >
          Volver
        </button>
        <pagination-controls
          maxSize="10"
          previousLabel="Anterior"
          nextLabel="Siguiente"
          [autoHide]="true"
          [responsive]="true"
          (pageChange)="nextOrPreviousPage($event)"
          id="server"
          class="my-pagination"
        ></pagination-controls>
      </div>
    </div>
    <!--TABLA ends-->
    <div
      class="d-flex justify-content-center mt-4"
      *ngIf="_data.length > 0"
    ></div>

    <!--No se han encontrado resultados-->
    <div
      *ngIf="!loading && _data.length == 0"
      class="row justify-content-center animated fadeIn mt-5"
    >
      <div class="col-xxl-4 col-xl-5 col-lg-8 col-md-6 col-sm-10">
        <div class="card not-found text-center">
          <div class="card-body">
            <img style="width: 30px; height: 30px;" src="../../../../assets/img/fasearch.svg" alt="">
            <h5>No se encontraron resultados</h5>
            <button
              class="btn btn-green mt-3 mb-3"
              (click)="emitNewSearch($event)"
            >
              Realizar otra consulta
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mensajes -->
<!-- <swal #cancelSwal title="Cargar un archivo para subir." icon="warning" confirmButtonText="Aceptar" confirmButtonColor="#53BAAB"></swal>
<swal #okSwal title="Archivo enviado correctamente." icon="success" confirmButtonText="Aceptar" confirmButtonColor="#53BAAB"></swal> -->
<swal
  #failSwal
  title="Error inesperado"
  icon="warning"
  confirmButtonText="Volver atrás"
  confirmButtonColor="#53BAAB"
></swal>
<swal
  #sesionSwal
  title="Sesión expirada."
  icon="warning"
  confirmButtonText="Aceptar"
  confirmButtonColor="#53BAAB"
></swal>
