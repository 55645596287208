<app-navbar (menuClickEmitter)="onToggleSidebarVisible()"></app-navbar>

<main>
  <section class="sidebar-navigation">
    <!-- Main navigation section - Main nodes -->
    <nav class="">
      <ul>
        <li
          [@changeWidth]="widthState"
          *ngFor="let items of navigationSidebarList"
          (click)="navigateTo(items.href)"
        >
          <div mat-ripple>
            <!-- Icons -->
            <img class="icon" [src]="items.icon" [alt]="items.title" />
            <h4>{{ items.title }}</h4>
          </div>
        </li>
        <li [@changeWidth]="widthState">
          <button class="logoff-button" (click)="logOut()">
            Cerrar sesion
          </button>
        </li>
      </ul>

      <button class="toggler-button" (click)="onToggleSidebarVisible()">
        <img [src]="reviewDirection()" alt="Toggle" />
      </button>
    </nav>
    <!-- Appears when a node is clicked and have children nodes to show -->
    <!-- Don't have icons to show -->
    <article @onOff class="detail-navigator" *ngIf="navigationDetailSidebar">
      <h3>
        {{ navigationDetailTitle }}
      </h3>
      <ul>
        <button
          (click)="navigateTo(children.href)"
          *ngFor="let children of navigationDetailSidebar"
          class="log-container"
        >
          <h4 mat-ripple>
            {{ children.title }}
          </h4>
        </button>
      </ul>
    </article>
  </section>
  <div class="router-outlet-content">
    <ng-content></ng-content>
  </div>
</main>
