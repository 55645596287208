import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';

// External imports
declare var XLSX: any;
declare let jQuery: any;

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  
  public consultaForm: UntypedFormGroup;
  public mediosPago:any;
  //Interacciones
  public submitted:boolean = false;
  
  public loading:boolean = false;

  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;

  constructor(    
    private apiService:ApiService,
    private _functionsService: FunctionsService,    
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe ) { }

  ngOnInit(): void {

      this.makeForm();
  }

  makeForm(){
  
    //Form Builder
    this.consultaForm = this.formBuilder.group({
        medio_pago: [''],
        desdeAcreditacion: ['', Validators.required],
        hastaAcreditacion: ['', Validators.required],
     })
     //Medios de pago
     this.apiService.getMedioPago()
     .then((response) =>{
       this.mediosPago = response;
       //ordena alfabéticamente
       this.mediosPago.sort((a, b) => {
           let fa = a.marca.toLowerCase(),
               fb = b.marca.toLowerCase();
           if (fa < fb) {
               return -1;
           }
           if (fa > fb) {
               return 1;
           }
           return 0;
       });
      //  this._functionsService.imprimirMensaje(this.mediosPago, "Medio pagos: ");
     })

     .catch((error)=>{
       this._functionsService.imprimirMensaje(error, "Error en medio pagos: ");
       this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los medios de pagos`, "error", "Aceptar", "", false, "", "");
       this.mensajeSwal.fire();
     });
  
  }

  //Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
  datepicker(){
    //Toma los datos del formulario
      var dateFrom = this.consultaForm.get('desdeAcreditacion').value;
      var dateTo = this.consultaForm.get('hastaAcreditacion').value;

      //Si no está vacío
      if(dateFrom != ""){
        jQuery("#to").attr({   
              "min" : dateFrom
            });
      }

      if(dateTo != ""){
            jQuery("#from").attr({   
            "max" : dateTo,
          });
      }      
    }

  limpiar(){
    this.submitted = false;
    this.consultaForm.reset();
    this.makeForm();
  }

  //Controls
  get form(){
    return this.consultaForm.controls;
  }

  onSubmit(formData){

    this.submitted = true;
    //Formulario inválido
    if(this.consultaForm.invalid){
      return;
    }  
    this.loading = true;

    //petición al apiService
    this.apiService.reportesMediosPago( formData ) 
    .then( (response:any) => {
        const nodisponible = "No disponible";
        
        //crea el archivo excel
        var dataFormated = Array<any>();
        let dataAux:any;
        
        if(response[0] == null) {

          dataAux = {
            "Medio pago": response.medioPago  != null? response.medioPago: nodisponible,	
            "Acreditación desde": this.datePipe.transform(formData.desdeAcreditacion, "dd-MM-yyyy") != null? this.datePipe.transform(formData.desdeAcreditacion, "dd-MM-yyyy"): nodisponible,	
            "Acreditación hasta": this.datePipe.transform(formData.hastaAcreditacion, "dd-MM-yyyy") != null? this.datePipe.transform(formData.hastaAcreditacion, "dd-MM-yyyy"): nodisponible,
            "Importe bruto": response.totalBruto != null? response.totalBruto/100: nodisponible,	
            "Importe neto": response.totalNeto != null? response.totalNeto/100: nodisponible,	
            "Importe comisión": response.totalGastos != null? response.totalGastos/100: nodisponible,	
          }
          dataFormated.push(dataAux);
          
        }else{
          response.forEach(element => {
              dataAux = {
                "Medio pago": element.medioPago  != null? element.medioPago: nodisponible,	
                "Acreditación desde": this.datePipe.transform(formData.desdeAcreditacion, "dd-MM-yyyy") != null? this.datePipe.transform(formData.desdeAcreditacion, "dd-MM-yyyy"): nodisponible,	
                "Acreditación hasta": this.datePipe.transform(formData.hastaAcreditacion, "dd-MM-yyyy") != null? this.datePipe.transform(formData.hastaAcreditacion, "dd-MM-yyyy"): nodisponible,
                "Importe bruto": element.totalBruto != null? element.totalBruto/100: nodisponible,	
                "Importe neto": element.totalNeto != null? element.totalNeto/100: nodisponible,	
                "Importe comisión": element.totalGastos != null? element.totalGastos/100: nodisponible,	
              }
            dataFormated.push(dataAux);
          })
        }

        /* generate worksheet */
        let ws =  XLSX.WorkSheet;
        ws = XLSX.utils.json_to_sheet(dataFormated);
        let wb = XLSX.WorkBook;
        wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja');
        XLSX.writeFile(wb, "reporte-medios-de-pago.xlsx");
    })
    .catch(error => { 
        this._functionsService.configSwal(this.mensajeSwal, `No se encontraron resultados de su búsqueda`, "info", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire();
        //Limpia la consola
        console.clear();
        return;
    })
    .finally( () => {
        this.loading = false;
        this.consultaForm.reset();
        this.submitted = false;
        this.makeForm();
    })      
  }

  EnterSubmit(event, form) {
      if (event.keyCode === 13)  this.onSubmit(form) 
  }


}
