<div class="card">
  <div class="card-body">
    <form
      [formGroup]="consultaForm"
      (keydown)="EnterSubmit($event, consultaForm.value)"
    >
      <div class="card">
        <div class="row">
          <!--Número de liquidación-->
          <div class="col-12">
            <div class="mb-3">
              <label for="num_liquidacion" class="form-label"
                >N° Liquidación</label
              >
              <input
                type="text"
                placeholder="Ingrese un número de liquidación"
                class="form-"
                digitOnly
                formControlName="num_liquidacion"
                class="input form-control"
              />
            </div>
          </div>
          <!--Clientes-->
          <div class="col-6">
            <div class="mb-3">
              <label for="cliente" class="form-label">Cliente</label>
              <select
                formControlName="cliente"
                class="form-select"
                aria-label="Default select example"
                [ngClass]="{
                  'is-invalid border-red': submitted && form.cliente.errors
                }"
              >
                <option selected disabled hidden value="">Todos</option>
                <option
                  *ngFor="let cliente of listaClientes"
                  value="{{ cliente.id }}"
                >
                  {{ cliente.username }} - {{ cliente.razon_social }}
                </option>
              </select>
            </div>
          </div>
          <!--Rendición-->
          <div class="col-6">
            <div class="mb-3">
              <label for="cliente" class="form-label">N° Rendición</label>
              <input
                type="number"
                placeholder="Ingrese un número de rendición"
                formControlName="num_rendicion"
                class="input form-control"
              />
            </div>
          </div>
          <!--Fecha -->
          <div class="col-lg-6 col-sm-12">
            <div class="mb-3">
              <div class="form-group row">
                <label class="form-label">Desde la fecha</label>
                <div class="col-12">
                  <input
                    (change)="dateChange($event)"
                    (focus)="datepicker()"
                    (blur)="datepicker()"
                    id="from"
                    formControlName="desdeCreacion"
                    class="input form-control"
                    type="date"
                    [ngClass]="{
                      'is-invalid': submitted && form.desdeCreacion.errors
                    }"
                  />
                  <div
                    *ngIf="
                      submitted && dateIsSelected && form.desdeCreacion.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.desdeCreacion.errors.required">
                      Este campo es necesario
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="mb-3">
              <div class="form-group row">
                <label class="form-label">Hasta la fecha</label>
                <div class="col-12">
                  <input
                    (change)="dateChange($event)"
                    (blur)="datepicker()"
                    id="to"
                    formControlName="hastaCreacion"
                    class="input form-control"
                    type="date"
                    [ngClass]="{
                      'is-invalid': submitted && form.hastaCreacion.errors
                    }"
                  />
                  <div
                    *ngIf="
                      submitted && dateIsSelected && form.hastaCreacion.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.hastaCreacion.errors.required">
                      Este campo es necesario
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Distribuida-->
          <div class="col-lg-6 col-sm-12">
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                formControlName="distribuida"
                class="form-check-input"
              />
              <label class="form-check-label">Distribuida</label>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <button type="button " class="btn btn-white" (click)="limpiar()">
          Limpiar
        </button>
        <button
          type="button"
          (click)="onSubmit(consultaForm.value)"
          class="btn btn-green ml-15"
        >
          Buscar
        </button>
      </div>
    </form>
  </div>
</div>
