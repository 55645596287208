import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { FunctionsService } from './functions.service';
import { consultaRendiciones } from '../models/rendiciones/consultaRendiciones';
import { consultaTransacciones } from '../models/movimientos/consultaTransacciones';
import { consultaLiquidaciones } from '../models/liquidacion/consultaLiquidaciones';

import { Cvu } from '../models/Cvu';
import { ConsultaConciliaciones } from '../models/consultaConciliacion';
import { tarjetaConsulta } from '../models/tarjetaConsulta';
import { IClientesResponse } from '../dashboard/clientes/types/clientes.type';
import { Cobranza } from '../models/cobranza';
import { BaseHttpService } from '../core/base-http.service';
import { PageableRequestInterface } from '../core/interfaces/pageable.interface';
import { UserInterface } from '../models/interfaces/user.interface';
import { NewUserRequestInterface } from '../models/new-user-request.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseHttpService {
  private url: string;
  private urlLogin: string;
  private urlSolicitud: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _functionsService: FunctionsService
  ) {
    super();
    this.urlLogin = environment.endpointLogin + '/api/auth/login';
    this.urlSolicitud =
      environment.endpoint + '/api/solicitud_pago/get_solicitud';
    this.url = environment.endpoint;
  }

  private cabeceras: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  getLogin(username: string, password: string) {
    var data = new FormData();
    data.append('username', username);
    data.append('password', password);
    return this.http.post(this.urlLogin, data).toPromise();
  }

  errorPeticion() {
    this.router.navigate(['login']);
  }

  getClientes() {
    return this.http
      .post<IClientesResponse>(
        this.getUri({}, 'cliente', 'proceso', 'get_clientes'),
        null
      )
      .toPromise();
  }
  getClient() {
    return this.http.post<IClientesResponse>(
      this.getUri({}, 'cliente', 'proceso', 'get_clientes'),
      null
    );
  }
  getClienteID(id: any) {
    return this.http
      .post(this.getUri({ id }, 'cliente', 'proceso', 'get_cliente_by_id'), {
        id,
      })
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  getAllRespuestaSolicitudPago() {
    return this.http.get(this.urlSolicitud).toPromise();
  }

  //Devuelve listado de rendiciones con los filtros aplicados
  getRespuestaRendiciones(
    page: number,
    pageSize: string,
    filtros: consultaRendiciones
  ) {
    let paramss = new HttpParams();
    //Filtros
    //paginación
    paramss = paramss.set('pageNumber', page.toString());
    paramss = paramss.set('pageSize', pageSize);
    paramss = paramss.set('sortDirection', 'DESC');
    //búsqueda
    paramss = paramss.set('numero', filtros.numero.toString());
    paramss = paramss.set('estado', filtros.estado.toString());
    paramss = paramss.set('distribuida', filtros.distribuida.toString());
    paramss = paramss.set('cliente', filtros.cliente.toString());
    paramss = paramss.set('desdeCreacion', filtros.desdeCreacion);
    paramss = paramss.set(
      'hastaCreacion',
      filtros.hastaCreacion != '' ? filtros.hastaCreacion + ' 23:59:59' : ''
    );
    paramss = paramss.set(
      'desdeAcreditacion',
      filtros.desdeAcreditacion != null ? filtros.desdeAcreditacion : ''
    );
    paramss = paramss.set(
      'hastaAcreditacion',
      filtros.hastaAcreditacion != null ? filtros.hastaAcreditacion : ''
    );

    return this.http
      .get(this.url + '/api/cuenta_virtual/proceso/get_rendiciones', {
        params: paramss,
      })
      .toPromise();
  }
  //Devuelve datos unicamente de la rendicion (importe, estado, fecha_acreditado, etc) por número o id
  getDetallesRendicionPorNumero(idx: number) {
    var paramss = new HttpParams();
    paramss = paramss.set('numero', idx.toString());

    return this.http
      .get(this.url + '/api/cuenta_virtual/proceso/get_rendiciones', {
        params: paramss,
      })
      .toPromise();
  }

  /////////////////////////      NUEVA CONSULTA RENDICIONES    //////////////////////////////
  get_rendicion_detalle(id) {
    return this.http
      .get(this.url + '/api/cuenta_virtual/proceso/rendicion_detalle/' + id)
      .toPromise();
  }

  /////////////////////////      NUEVA CONSULTA MOVIMIENTOS   //////////////////////////////
  getListaMovimientos(
    pageNumber: number,
    pageSize: string,
    filtros: consultaTransacciones
  ) {
    let paramss = new HttpParams();
    localStorage.setItem('pageNumberC', JSON.stringify(pageNumber));
    localStorage.setItem('pageSizeC', JSON.stringify(pageSize));

    //Filtros
    //rendicion
    paramss = paramss.set(
      'rendicion',
      filtros.id_rendicion != null ? filtros.id_rendicion.toString() : ''
    );
    //paginación
    paramss = paramss.set('pageNumber', pageNumber.toString());
    paramss = paramss.set('pageSize', pageSize);
    paramss = paramss.set('sortDirection', 'DESC');
    //búsqueda
    paramss = paramss.set(
      'cliente',
      filtros.cliente != null ? filtros.cliente.toString() : ''
    );
    paramss = paramss.set(
      'numero',
      filtros.numero != null ? filtros.numero.toString() : ''
    );
    paramss = paramss.set(
      'estado',
      filtros.estado != null ? filtros.estado.toString() : ''
    );
    paramss = paramss.set(
      'medioPago',
      filtros.medioPago != null ? filtros.medioPago : ''
    );
    paramss = paramss.set(
      'referencia_externa',
      filtros.ref_externa != null ? filtros.ref_externa : ''
    );
    paramss = paramss.set(
      'codigo_barra',
      filtros.codigo_barras != null ? filtros.codigo_barras : ''
    );
    paramss = paramss.set(
      'desdeCreacion',
      filtros.desdeCreacion != null ? filtros.desdeCreacion : ''
    );
    if (
      filtros.hastaCreacion != '' &&
      filtros.hastaCreacion != null &&
      filtros.hastaCreacion != undefined
    ) {
      paramss = paramss.set(
        'hastaCreacion',
        filtros.hastaCreacion + ' 23:59:59'
      );
    } else {
      paramss = paramss.set('hastaCreacion', '');
    }
    paramss = paramss.set(
      'desdeImporte',
      filtros.desdePago != null ? filtros.desdePago : ''
    );
    if (
      filtros.hastaPago != '' &&
      filtros.hastaPago != null &&
      filtros.hastaPago != undefined
    ) {
      paramss = paramss.set('hastaImporte', filtros.hastaPago + ' 23:59:59');
    } else {
      paramss = paramss.set('hastaImporte', '');
    }
    paramss = paramss.set(
      'desdeAcreditacion',
      filtros.desdeAcreditacion != null ? filtros.desdeAcreditacion : ''
    );
    paramss = paramss.set(
      'hastaAcreditacion',
      filtros.hastaAcreditacion != null ? filtros.hastaAcreditacion : ''
    );

    paramss = paramss.set(
      'card_authorization_code',
      filtros.codigoAutorizacion != null ? filtros.codigoAutorizacion : ''
    );
    paramss = paramss.set(
      'id_transaccion',
      filtros.numeroTransaccion != null ? filtros.numeroTransaccion : ''
    );

    paramss = paramss.set('BIN', filtros.BIN != null ? filtros.BIN : '');
    paramss = paramss.set(
      'debin_id',
      filtros.debin_id != null ? filtros.debin_id : ''
    );

    paramss = paramss.set('pos', filtros.pos != null ? filtros.pos : '');
    paramss = paramss.set(
      'terminal_number',
      filtros.terminal_number != null ? filtros.terminal_number : ''
    );

    paramss = paramss.set('l4d', filtros.l4d != null ? filtros.l4d : '');
    paramss = paramss.set(
      'ticket',
      filtros.ticket != null ? filtros.ticket : ''
    );
    paramss = paramss.set(
      'coelsa_id',
      filtros.coelsa_id != null ? filtros.coelsa_id : ''
    );
    return this.http.get(this.urlSolicitud, { params: paramss }).toPromise();
  }
  /////////////////////////      NUEVA CONSULTA MOVIMIENTOS EXCEL   //////////////////////////////

  generarReporteMovimientos(filtros: any, email: String, user: any) {
    let paramss = new HttpParams();
    //búsqueda
    paramss = paramss.set(
      'cliente',
      filtros.cliente != null ? filtros.cliente.toString() : ''
    );
    paramss = paramss.set(
      'numero',
      filtros.numero != null ? filtros.numero.toString() : ''
    );
    paramss = paramss.set(
      'estado',
      filtros.estado != null ? filtros.estado.toString() : ''
    );
    paramss = paramss.set(
      'medioPago',
      filtros.medioPago != null ? filtros.medioPago : ''
    );
    //paramss = paramss.set('referencia_externa', filtros.ref_externa != null?  filtros.ref_externa : "");
    paramss = paramss.set(
      'codigo_barra',
      filtros.codigo_barras != null ? filtros.codigo_barras : ''
    );
    paramss = paramss.set(
      'desdeCreacion',
      filtros.desdeCreacion != null ? filtros.desdeCreacion : ''
    );
    if (
      filtros.hastaCreacion != '' &&
      filtros.hastaCreacion != null &&
      filtros.hastaCreacion != undefined
    ) {
      paramss = paramss.set('hastaCreacion', filtros.hastaCreacion);
    } else {
      paramss = paramss.set('hastaCreacion', '');
    }
    paramss = paramss.set(
      'desdeImporte',
      filtros.desdePago != null ? filtros.desdePago : ''
    );
    if (
      filtros.hastaPago != '' &&
      filtros.hastaPago != null &&
      filtros.hastaPago != undefined
    ) {
      paramss = paramss.set('hastaImporte', filtros.hastaPago);
    } else {
      paramss = paramss.set('hastaImporte', '');
    }
    paramss = paramss.set(
      'desdeAcreditacion',
      filtros.desdeAcreditacion != null ? filtros.desdeAcreditacion : ''
    );
    paramss = paramss.set(
      'hastaAcreditacion',
      filtros.hastaAcreditacion != null ? filtros.hastaAcreditacion : ''
    );

    paramss = paramss.set(
      'card_authorization_code',
      filtros.codigoAutorizacion != null ? filtros.codigoAutorizacion : ''
    );
    paramss = paramss.set(
      'id_transaccion',
      filtros.numeroTransaccion != null ? filtros.numeroTransaccion : ''
    );
    paramss = paramss.set('email', email.toString());
    paramss = paramss.set(
      'referencia_externa',
      filtros.ref_externa != null ? filtros.ref_externa : ''
    );
    paramss = paramss.set('user', user);

    return this.http
      .get(this.url + '/api/solicitud_pago/get_solicitudV2' + '?' + paramss, {})
      .toPromise();
  }
  /* getOneSolicitudPago(id:number){
    console.log("llega id");
    console.log(id);
    var url = this.urlSolicitud+"_id/"+id;
    return this.http.get(url).toPromise();
  }*/

  // LIQUIDACIONES //////////////////////////////////

  getRendicionesByIdCliente(clienteID: number) {
    return this.http
      .get(
        this.url +
        '/api/cuenta_virtual/proceso/get_rendicion_by_id_list/' +
        clienteID
      )
      .toPromise();
  }

  crearLiquidacion(data): Promise<any> {
    var json: any = JSON.stringify(data);

    return this.http
      .post(this.url + '/api/liquidacion/proceso/liquidacion', data)
      .toPromise();
  }

  getLiquidaciones(
    page: number,
    pageSize: string,
    filtros: consultaLiquidaciones
  ) {
    let paramss = new HttpParams();
    //Filtros
    //paginación
    paramss = paramss.set('pageNumber', page.toString());
    paramss = paramss.set('pageSize', pageSize.toString());
    paramss = paramss.set('sortDirection', 'DESC');
    //búsqueda
    paramss = paramss.set(
      'id',
      filtros.num_liquidacion != null ? filtros.num_liquidacion.toString() : ''
    );
    paramss = paramss.set(
      'cliente',
      filtros.cliente != null ? filtros.cliente.toString() : ''
    );
    paramss = paramss.set(
      'rendicion',
      filtros.num_rendicion != null ? filtros.num_rendicion.toString() : ''
    );
    paramss = paramss.set(
      'desdeCreacion',
      filtros.desdeCreacion != null ? filtros.desdeCreacion.toString() : ''
    );
    if (
      filtros.hastaCreacion != '' &&
      filtros.hastaCreacion != null &&
      filtros.hastaCreacion != undefined
    ) {
      paramss = paramss.set(
        'hastaCreacion',
        filtros.hastaCreacion + ' 23:59:59'
      );
    } else {
      paramss = paramss.set('hastaCreacion', '');
    }
    //paramss = paramss.set('distribuida', filtros.distribuida != null? filtros.distribuida.toString(): "");
    paramss = paramss.set('distribuida', 'false');
    return this.http.get(
      this.url + '/api/liquidacion/proceso/get_liquidaciones',
      { params: paramss }
    );
  }

  getLiquidacionesCliente(id: number, idCliente: number) {
    let formData = new FormData();
    //console.log("numero Liq " +id);
    if (id != null) formData.append('idCliente', idCliente.toString());
    if (id != null) formData.append('idLiquidacion', id.toString());
    return this.http
      .post(
        this.url + '/api/liquidacion/proceso/get_liquidacion_PorCliente',
        formData
      )
      .toPromise();
  }

  //NUEVA LIQUIDACION
  liquidacion_detalle(id: number) {
    return this.http
      .get(this.url + '/api/cuenta_virtual/proceso/liquidacion_detalle/' + id)
      .toPromise();
  }

  getListaRendiciones(id: number, idCliente: number) {
    var url = `${this.url}/api/solicitud_pago/proceso/get_rendiciones_liquidacion?idCliente=${idCliente}&liquidacion=${id}`;
    return this.http.get(url).toPromise();
  }

  getDetallesDebin(id: number) {
    var url = `${this.url}/api/solicitud_pago/get_solicitud_id_debin?id=${id}`;
    return this.http.get(url).toPromise();
  }

  // REPORTES ////////////////////////////////

  reportesMediosPago(data) {
    const formData = new FormData();

    formData.append('fechaDesde', data.desdeAcreditacion.toString());
    formData.append('fechaHasta', data.hastaAcreditacion.toString());
    formData.append('medioPago', data.medio_pago.toString());

    return this.http
      .post(this.url + '/api/cliente/get_filtro', formData)
      .toPromise();
  }

  // CONCIACIONES  //////////////////////////////////

  getConciliaciones(
    page: number,
    pageSize: string,
    filtros: any,
    limit?: number
  ) {
    // consultaConciliaciones

    return new Promise((resolve, reject) => {
      var array = Array<any>();

      if (!limit) limit = 50;

      for (let i = 1; i <= limit; i++) {
        const item = {
          id: i,
          estado: 'GENERADA',
          nombre_archivo: 'archivo.txt',
          medio_pago: 'RAPIPAGO',
          fecha_creacion: '15-08-2021',
          fecha_acreditacion: '19-08-2021',
          importe_bruto: '4050000',
          importe_neto: null,
        };
        array.push(item);
      }

      const _JSON = {
        content: array,
        pageSize: 10,
        pageNumber: 0,
        totalElements: 50,
      };
      resolve(_JSON);
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  enviarArchivo(archivoTransimision: File): Promise<any> {
    const formData = new FormData();
    formData.append('archivoTransmision', archivoTransimision);
    this._functionsService.imprimirMensaje(
      formData.get('archivoTransmision'),
      '-- El archivo transmisión a enviar'
    );
    return this.http
      .post(
        this.url + '/api/archivo_transmision/proceso_archivo_transmision',
        formData
      )
      .toPromise();
  }

  guardarArchivo(json): Observable<any> {
    return this.http.post(
      this.url + '/api/archivo_transmision/guardar_archivo_transmision',
      json
    );
  }
  /////////////////////////////////////////////////////////////////////////////

  descargarArchivo(data) {
    const formData = new FormData();

    formData.append('fecha_inicio', data.desdeCreacion);
    formData.append('fecha_fin', data.hastaCreacion);

    return this.http
      .post(this.url + '/api/archivo_transmision/crear_archivo_base', formData)
      .toPromise();
    // this._functionsService.imprimirMensaje(formData.get('archivoTransmision'), "-- El archivo transmisión a enviar");
    // return this.http.post(this.url+'api/archivo_transmision/crear_archivo_base', formData).toPromise();
  }

  /////////////////////////////////// USUARIOS //////////////////////////////////////////
  // consultas usuarios

  getUsers() {
    return this.http.get(this.url + '/api/usuarios/proceso/get_user_paginado');
  }

  /**
   * Get a list of all users with pagination
   */
  getUsersList(
    page: number,
    pageSize: number
  ): Observable<PageableRequestInterface<UserInterface>> {
    let paramss = new HttpParams();
    paramss = paramss.set('page', page.toString());
    paramss = paramss.set('size', pageSize.toString());
    return this.http.get<PageableRequestInterface<UserInterface>>(
      this.url + '/api/usuarios/proceso/get_user_paginado?' + paramss
    );
  }

  createUser(user: UserInterface) {
    return this.http
      .post(this.url + '/api/usuarios/proceso/guardar_usuario', user)
      .toPromise();
  }

  createUserObservable(
    user: NewUserRequestInterface
  ): Observable<{ id: number; message: string }> {
    return this.http.post<{ id: number; message: string }>(
      this.url + '/api/usuarios/proceso/save',
      user
    );
  }

  editUser(userId, user) {
    var json = {};

    for (var key in user) {
      if (user[key] != null && user[key] != '') {
        json[key] = user[key];
      }
    }
    var url = `${this.url}/api/usuarios/proceso/actualizar_usuario?id=${userId}`;
    return this.http.post(url, json).toPromise();
  }

  disableUser(userId) {
    var json = {
      id: userId,
    };
    var url = `${this.url}/api/usuarios/proceso/disable_user?id=${userId}`;
    return this.http.post(url, json).toPromise();
  }

  getUsuario(username: string) {
    let paramss = new HttpParams();
    paramss = paramss.set('username', username);

    return this.http
      .post(
        this.url + '/api/usuarios/proceso/find_by_username' + '?' + paramss,
        {}
      )
      .toPromise();
  }

  getRoles() {
    var url = `${this.url}/api/usuarios/proceso/get_roles`;
    return this.http.post(url, null).toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  //consultas clientez

  /////////////////////////////////////////////////////////////////////////////
  //consultas medio pago

  getMedioPago() {
    return this.http.get(this.url + '/api/medio_pago/medios_pagos').toPromise();
  }
  //medio pago Efectivo
  getMedioPagoEfectivo(medioPago) {
    let paramss = new HttpParams();
    paramss = paramss.set('tipo', medioPago);
    return this.http
      .get(this.url + '/api/medio_pago/medios_pagos', { params: paramss })
      .toPromise();
  }

  createMedioPago(medioPago) {
    return this.http
      .post(this.url + '/api/medio_pago/crear_medio_pago', medioPago)
      .toPromise();
  }

  editMedioPago(medioPagoId, medioPago) {
    var json = {
      id: medioPagoId,
    };
    for (var key in medioPago) {
      if (medioPago[key] != null && medioPago[key] != '') {
        json[key] = medioPago[key];
      }
    }
    var url = `${this.url}/api/medio_pago/actualizar_medio_pago`;
    return this.http.patch(url, json).toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  //ESTADOS EFECTIVO
  getEstado() {
    return this.http
      .get(
        this.url + '/api/archivo_transmision/proceso/get_recaudaciones_estado'
      )
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  enviarMotivo(json) {
    var url = `${this.url}/api/transaccion_simple/solicitud_devolucion_total`;
    return this.http.post(url, json).toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////     CVU     //////////////////////////////////////
  //*** ALTA ***
  crearCvu(data): Promise<any> {
    var json: any = JSON.stringify(data);
    return this.http
      .post(this.url + '/api/cvu/proceso/alta_cvu', data)
      .toPromise();
  }

  //*** CONSULTA ***
  getlistfiltradaCvu(filtros: Cvu): Promise<any> {
    let paramss = new HttpParams();
    //búsqueda
    if (filtros.cliente) {
      paramss = paramss.set('cliente_id', filtros.cliente);
    }
    if (filtros.cvu) {
      paramss = paramss.set('cvu', filtros.cvu);
    }
    if (filtros.cuit) {
      paramss = paramss.set('cuit', filtros.cuit);
    }
    if (filtros.alias) {
      paramss = paramss.set('alias', filtros.alias);
    }
    //console.log(this.http.get(this.url + '/api/cvu/proceso/get_cvu', { params: paramss}).toPromise());
    return this.http
      .get(this.url + '/api/cvu/proceso/get_cvu', { params: paramss })
      .toPromise();
  }
  //*** DETALLE ***

  getCvuDetalles(id: number) {
    localStorage.removeItem('resp_cvu');
    //console.log(this.http.get(this.url+'/api/cvu/proceso/get_cvu_detalle/'+id).toPromise())
    return this.http
      .get(this.url + '/api/cvu/proceso/get_cvu_detalle/' + id)
      .toPromise();
  }

  //*** MODIFICAR ***
  editarAlias(json): Promise<any> {
    //console.log(this.http.post(this.url+'/api/cvu/proceso/modificar_cvu',json,{headers:this.cabeceras}).toPromise());
    return this.http
      .post(this.url + '/api/cvu/proceso/modificar_cvu', json, {
        headers: this.cabeceras,
      })
      .toPromise();
  }

  //*** ELIMINAR ***
  eliminarCvu(idx: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: idx,
      },
    };

    return this.http
      .delete(this.url + '/api/cvu/proceso/eliminar_cvu', options)
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////      RECAUDACIONES    //////////////////////////////
  /////////////////////////      EFECTIVO   //////////////////////////////

  get_operacionesXmp(medioPago) {
    let paramss = new HttpParams();
    paramss = paramss.set('medioPago', medioPago.toString());
    return this.http
      .get(
        this.url +
        '/api/archivo_transmision/proceso/get_operaciones_por_medio_pago_api',
        { params: paramss }
      )
      .toPromise();
  }

  get_conciliacionesXMP(medioPago) {
    let paramss = new HttpParams();
    paramss = paramss.set('medioPago', medioPago.toString());
    return this.http
      .get(
        this.url +
        '/api/archivo_transmision/proceso/get_conciliaciones_por_medio_pago_api',
        { params: paramss }
      )
      .toPromise();
  }

  get_conciliacion(id) {
    let paramss = new HttpParams();
    paramss = paramss.set('id', id.toString());
    return this.http
      .get(this.url + '/api/archivo_transmision/proceso/get_conciliacion', {
        params: paramss,
      })
      .toPromise();
  }

  getRecaudaciones(
    page: number,
    pageSize: string,
    consulta: ConsultaConciliaciones
  ) {
    let paramss = new HttpParams();
    //Filtros
    //paginación
    paramss = paramss.set('pageNumber', page.toString());
    paramss = paramss.set('pageSize', pageSize.toString());
    paramss = paramss.set('sortDirection', 'DESC');
    paramss = paramss.set(
      'numero',
      consulta.num_conciliacion != null
        ? consulta.num_conciliacion.toString()
        : ''
    );
    paramss = paramss.set(
      'archivo',
      consulta.nom_archivo != null ? consulta.nom_archivo.toString() : ''
    );
    paramss = paramss.set(
      'estado',
      consulta.estado != null ? consulta.estado.toString() : ''
    );
    paramss = paramss.set(
      'medioPago',
      consulta.medio_pago != null ? consulta.medio_pago.toString() : ''
    );
    paramss = paramss.set(
      'desdeCreacion',
      consulta.desdeCreacion != null ? consulta.desdeCreacion.toString() : ''
    );
    paramss = paramss.set(
      'hastaCreacion',
      consulta.hastaCreacion != null ? consulta.hastaCreacion.toString() : ''
    );
    return this.http
      .get(
        this.url + '/api/archivo_transmision/proceso/get_recaudaciones_api',
        { params: paramss }
      )
      .toPromise();
  }

  getRecaudacion_efectivo(id: number) {
    let paramss = new HttpParams();
    paramss = paramss.set('id', id != null ? id.toString() : '');
    return this.http
      .get(
        this.url +
        '/api/archivo_transmision/proceso/get_recaudacion_efectivo_api',
        { params: paramss }
      )
      .toPromise();
  }

  getConciliacion_tipo() {
    return this.http
      .get(
        this.url +
        '/api/archivo_transmision/proceso/get_recaudaciones_efectivo_conciliacion_tipo_api'
      )
      .toPromise();
  }

  guardar_conciliacion(datoCabecera, dataSource, dataSource1, cantReg) {
    for (let i = cantReg; i < dataSource1.length; i++) {
      dataSource1[i].id = 0;
      dataSource1[i].importe = parseInt(
        dataSource1[i].importe.replace(/\D/g, '')
      );
    }

    let json = {
      conciliacion: datoCabecera,
      items: dataSource,
      conciliaciones: dataSource1,
    };

    // console.log("Tabla json");
    // console.log(json);

    //console.log(json);
    return this.http
      .post(
        this.url + '/api/archivo_transmision/proceso/guardar_conciliacion',
        json
      )
      .toPromise();
  }

  /////////////////////////      TARJETA    //////////////////////////////

  getTarjeta(page: number, pageSize: string, consulta: tarjetaConsulta) {
    let paramss = new HttpParams();
    //Filtros
    //paginación

    paramss = paramss.set('pageNumber', page.toString());
    paramss = paramss.set('pageSize', pageSize.toString());
    paramss = paramss.set('sortDirection', 'DESC');
    if (consulta.num_id != '') {
      paramss = paramss.set('id', consulta.num_id);
    }
    if (consulta.desdeCreacion != '') {
      paramss = paramss.set('desdePeriodo', consulta.desdeCreacion);
    }
    if (consulta.hastaCreacion != '') {
      paramss = paramss.set('hastaPeriodo', consulta.hastaCreacion);
    }

    return this.http
      .get(this.url + '/api/centralpos/proceso/get_liquidaciones_tarjeta', {
        params: paramss,
      })
      .toPromise();
  }

  get_liquidaciones_tarjeta_detalle(id: number) {
    return this.http
      .get(
        this.url +
        '/api/centralpos/proceso/get_liquidaciones_tarjeta_detalle/' +
        id
      )
      .toPromise();
  }

  get_resumen_tarjeta(id: number) {
    return this.http
      .get(this.url + '/api/centralpos/proceso/get_resumen_tarjeta/' + id)
      .toPromise();
  }

  conciliar_tarjeta(datoConciliar) {
    //console.log(datoConciliar);
    var liq = JSON.parse(localStorage.getItem('liq'));
    var res = JSON.parse(localStorage.getItem('res'));
    let paramss = new HttpParams();
    paramss = paramss.set('liq', liq);
    paramss = paramss.set('res', res);
    return this.http
      .post(
        this.url + '/api/centralpos/proceso/conciliar_tarjeta',
        datoConciliar,
        { params: paramss }
      )
      .toPromise();
  }

  /////////////////////////      WHASTSAPP    //////////////////////////////

  reporteWhasp(consultaForm, user_id) {
    let paramss = new HttpParams();
    paramss = paramss.set('desde', consultaForm.desdeCreacion);
    paramss = paramss.set('hasta', consultaForm.hastaCreacion);
    paramss = paramss.set('email', consultaForm.email.toString());
    paramss = paramss.set('usuario', user_id);
    return this.http
      .get(this.url + '/api/whatsapp/whatsapp/find_all_between_dates', {
        params: paramss,
      })
      .toPromise();
    //return this.http.get(this.url+'/api/cliente/clientes').toPromise();
  }

  /////////////////////////      CONTRACARGO    //////////////////////////////

  contracargo(contracargoForm) {
    let paramss = new HttpParams();
    paramss = paramss.set('id', contracargoForm.id.toString());
    paramss = paramss.set(
      'fecha_contracargo',
      contracargoForm.desdeCreacion.toString()
    );

    return this.http
      .post(
        this.url +
        '/api/archivo_transmision/proceso/contracargo_efectivo' +
        '?' +
        paramss,
        {}
      )
      .toPromise();
  }

  /////////////////////////   ARCHIVO TARJETA NARANJA    //////////////////////////////
  enviarArchivoNaranja(archivoTransimision: File): Promise<any> {
    const formData = new FormData();
    formData.append('archivoLiquidacion', archivoTransimision);
    this._functionsService.imprimirMensaje(
      formData.get('archivoLiquidacion'),
      '-- El archivo transmisión a enviar'
    );
    return this.http
      .post(this.url + '/api/centralpos/proceso/archivo_naranja', formData)
      .toPromise();
  }

  //////////////////////////////////  REPORTES   //////////////////////////////////////
  get_reportes(id: number) {
    return this.http
      .get(this.url + '/api/usuarios/proceso/get_reportes' + '?id=' + id)
      .toPromise();
  }

  //////////////////////////////////  BLACKLIST   //////////////////////////////////////
  ////////// CREAR
  crearBlacklist(form: any) {
    let json = {
      tarjeta: form.tarjeta,
      email: form.email,
      dni: form.dni,
      domicilio: form.direccion,
      telefono: form.telefono,
      name: form.nombre,
    };
    return this.http.post(
      this.url + '/api/payway/blacklist/proceso/create_blacklist',
      json
    );
  }

  //////// LISTAR
  getListaBlacklist(page: number, pageSize: number) {
    let paramss = new HttpParams();
    paramss = paramss.set('page', page.toString());
    paramss = paramss.set('size', pageSize.toString());
    paramss = paramss.set('sort', 'id');
    return this.http.get(
      this.url + '/api/payway/blacklist/proceso/get_blacklist?' + paramss
    );
  }
  /////// BUSCAR PO ID
  getBalcklistById(id: number) {
    return this.http.get(
      this.url +
      '/api/payway/blacklist/proceso/get_blacklist_by_id' +
      '?id=' +
      id
    );
  }

  ////// MODIFICAR
  modificarBacklist(form, id) {
    let json = {
      id: id,
      tarjeta: form.tarjeta,
      email: form.email,
      dni: form.dni,
      domicilio: form.domicilio,
      telefono: form.telefono,
      name: form.name,
    };
    var url = `${this.url}/api/payway/blacklist/proceso/update_blacklist/` + id;
    return this.http.put(url, json);
  }

  /////ELIMINAR
  eliminarBlackList(id: number) {
    var url = `${this.url}/api/payway/blacklist/proceso/delete_blacklist/` + id;
    return this.http.delete(url);
  }

  //////////////////////////////////  KEY BYPASS  //////////////////////////////////////
  habilitarPago(id: number): Observable<object> {
    const url =
      `${this.url}/api/solicitud_pago/proceso/update_key_bypass/` + id;
    return this.http.put(url, null);
  }

  //////////////////////////////////  ARCHIVOS //////////////////////////////////////
  getlistaArchivos(
    page: number,
    pageSize: number,
    form: any
  ): Observable<object> {
    let paramss = new HttpParams();
    paramss = paramss.set('pageNumber', page.toString());
    paramss = paramss.set('pageSize', pageSize.toString());
    if (form.value.cliente != null && form.value.cliente !== '') {
      paramss = paramss.set('cliente', form.value.cliente);
    }
    if (form.value.tipo_archivo != null && form.value.tipo_archivo !== '') {
      paramss = paramss.set('tipo_archivo', form.value.tipo_archivo);
    }

    return this.http.get(
      `${this.url}/api/serviciopublico/proceso/get_archivos?` + paramss
    );
  }

  //////////////////////////////////  COBRANZA //////////////////////////////////////
  getDatosCobranza(cobranza: Cobranza): Observable<object> {
    return this.http.post(
      `${this.url}/api/erp/ventas/erp-cobranzas/admin/get-by-filter`,
      cobranza
    );
  }

  getCobranzas(page: number, pageSize: number, form: any): Observable<object> {
    let paramss = new HttpParams();
    paramss = paramss.set('page', page.toString());
    paramss = paramss.set('size', pageSize.toString());

    let body = {
      numeroFactura: form.value.numeroFactura,
      numeroDocumento: form.value.numeroDocumento,
      idCliente: form.value.cliente,
    };

    return this.http.post(
      `${this.url}/api/erp/ventas/erp-cobranzas/get-all-cobranza?` + paramss,
      body
    );
  }
}
