import {Component, inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'shared-redirection-card',
  templateUrl: './shared-redirection-card.component.html',
  styleUrls: ['./shared-redirection-card.component.scss']
})
export class SharedRedirectionCardComponent implements OnInit {

  private readonly _router = inject(Router);

  @Input() title = '';
  @Input() description = '';
  @Input() routelink  = '';

  constructor() { }

  ngOnInit(): void {
  }

  redirectToRouteLink(): void{
    this._router.navigate([this.routelink]);
  }

}
