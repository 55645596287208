import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../core/base-http.service';
import { HttpClient } from '@angular/common/http';
import { PageableRequestInterface } from '../../core/interfaces/pageable.interface';
import { TerminalListItemInterface } from './interfaces/terminal-list-item.interface';
import { Observable, from } from 'rxjs';
import { TerminalRelatedItemInterface } from './interfaces/terminal-related-item.interface';
import UserTerminalRequestModel from './models/user-terminal-request.model';
import NewTerminalRequestModel from './models/new-terminal-request.model';
import { PosnetTerminalInterface } from './interfaces/posnet-terminal.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewTerminalComponent } from './components/dialog-new-terminal/dialog-new-terminal.component';
import Swal from 'sweetalert2';
import ErrorStrings from 'src/app/constants/ErrorStrings';
import { switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class TerminalService extends BaseHttpService {
  /**
   * Terminal service
   *
   * NOTES:
   * - Http requests which has typedef as Observable<object> means the returned data is not important
   * - All http requests beigns with 'http'
   */
  constructor(private _httpClient: HttpClient, private dialog: MatDialog) {
    super();
  }

  /**
   * Get a list of all available posnets
   */
  httpGetEnabledPosnetList(): Observable<
    PageableRequestInterface<TerminalListItemInterface>
  > {
    return this._httpClient.get<
      PageableRequestInterface<TerminalListItemInterface>
    >(this.getUri({}, 'pos', 'proceso', 'terminales_habilitados'));
  }

  /**
   *  Get a list of all related posnets with their respective users
   */
  httpGetRelatedPosnetList(): Observable<
    PageableRequestInterface<TerminalRelatedItemInterface>
  > {
    return this._httpClient.get<
      PageableRequestInterface<TerminalRelatedItemInterface>
    >(this.getUri({}, 'pos', 'proceso', 'terminales_asociados'));
  }

  /**
   * Connects a new user with the related terminal
   * @param data - UserTerminalRequestModel with terminal_id & user_id
   */
  httpSetPosnetWithUser(data: UserTerminalRequestModel): Observable<string> {
    return this._httpClient.post<string>(
      this.getUri({}, 'pos', 'proceso', 'alta_user_terminal'),
      { ...data }
    );
  }

  /**
   * Deselect the terminal for the related user
   * @param userId - User related to the terminal
   */
  httpDeleteUserTerminals(userId: number): Observable<string> {
    return this._httpClient.post<string>(
      this.getUri(
        {},
        'pos',
        'proceso',
        'deshabilitar_terminal_user',
        userId.toString()
      ),
      {}
    );
  }

  /**
   * Updates the terminal of a user
   * @description Return data not important, only a string
   * @param data - Model statement of UserTerminalRequestModel with user_id & terminal_id
   * @return Observable of api process.
   */
  httpUpdateUserTerminal(data: UserTerminalRequestModel): Observable<object> {
    return this._httpClient.put(
      this.getUri({}, 'pos', 'proceso', 'alta_user_terminal'),
      { ...data }
    );
  }

  /**
   * Create a new POSNET TERMINAL
   * @param {NewTerminalRequestModel} data
   */
  httpCreateNewTerminal(data: NewTerminalRequestModel): Observable<string> {
    return this._httpClient.post<string>(
      this.getUri({}, 'pos', 'proceso', 'terminal'),
      { ...data }
    );
  }

  /**
   * List all the POSNET Terminals
   * @param data
   */
  httpListPosnetTerminals(
    data: Partial<NewTerminalRequestModel>
  ): Observable<PageableRequestInterface<PosnetTerminalInterface>> {
    return this._httpClient.get<
      PageableRequestInterface<PosnetTerminalInterface>
    >(this.getUri({ ...data }, 'pos', 'proceso', 'terminal'));
  }

  /**
   *
   *
   *
   */
  launchDialogAddNewTerminal(): Observable<string> {
    return new Observable((observer) => {
      const dialogRef = this.dialog.open(DialogNewTerminalComponent, {
        width: '500px',
        data: null,
      });

      dialogRef
        .afterClosed()
        .pipe(
          switchMap((result?: NewTerminalRequestModel) => {
            if (result) {
              return this.httpCreateNewTerminal(result);
            } else {
              // Si no hay resultado, retornamos un observable vacío
              return from([]);
            }
          })
        )
        .subscribe({
          next: (data: string): void => {
            observer.next(data);
            observer.complete();
            Swal.fire('OK', data, 'success');
          },
          error: (error: any) => {
            observer.error(error);
            Swal.fire('ERROR', ErrorStrings.UNEXPECTED_ERROR, 'error');
          },
        });
    });
  }
}
