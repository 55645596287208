<h1 mat-dialog-title>Deshabilitacion del cliente: {{ clientId }}</h1>
<mat-divider></mat-divider>
<div class="content" mat-dialog-content>
  <p>
    <span>Esta seguro de deshabilitar este cliente?</span> <br />
    <strong>Esta accion no se puede deshacer</strong>
  </p>
  <button mat-raised-buton color="primary" mat-button mat-dialog-close>
    Cancelar
  </button>
  <button
    mat-raised-buton
    color="warn"
    mat-button
    mat-dialog-close
    (click)="deleteClient(clientId)"
    cdkFocusInitial
  >
    Aceptar
  </button>
</div>
