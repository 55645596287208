import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/core/base-http.service';

@Injectable({
  providedIn: 'root',
})
export class LiquidacionExtrasService extends BaseHttpService {
  constructor(private _http: HttpClient) {
    super();
  }

  // TODO: VERIFICADO
  // TODO: APROBADO
  // TODO: EN REVISION
  // TODO: Cuando esta EN REVISION, puede volverse a VERIFICAR

  verificar(id: string) {
    return this._http.get(
      this.getUri({}, 'cuenta_virtual', 'proceco', 'verificar_liquidacion', id)
    );
  }

  aprobar(id: string) {
    return this._http.get(
      this.getUri({}, 'cuenta_virtual', 'proceco', 'verificar_aprobar', id)
    );
  }

  revision(id: string) {
    return this._http.get(
      this.getUri({}, 'cuenta_virtual', 'proceco', 'verificar_revision', id)
    );
  }

  generarLiquidacion(id:number, banco:number){
    
    return this._http.post(
      this.getUri({}, 'cuenta_virtual', 'proceso', `generar_liquidacion_cte/${id}/${banco}`),null);
  }

  getBancks(){
    return this._http.get(
      this.getUri({}, 'cuenta_virtual', 'proceso', 'getBancks'));
  }
}
