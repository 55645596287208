import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multiple-search',
  templateUrl: './multiple-search.component.html',
  styleUrls: ['./multiple-search.component.scss']
})
export class MultipleSearchComponent implements OnInit {

  @ViewChild('trigger', { static: true }) trigger: ElementRef;
  isTooltipVisible = false;

  showTooltip() {
    this.isTooltipVisible = true;
  }

  hideTooltip() {
    this.isTooltipVisible = false;
  }

  @Output() valorBusqueda: EventEmitter<{ textTerms: string[], numberTerms: string[], dateTerms: string[], fullDateTerms: string[] }>;
  constructor() { 
    this.valorBusqueda = new EventEmitter();
  }

  ngOnInit(): void {
  }

  buscarDato(input: string): void {
    const terms = input.split(',').map(term => term.trim());

    const textTerms = [];
    const numberTerms = [];
    const dateTerms = [];
    const fullDateTerms = [];

    const numberRegex = /^\d+$/;
    const dateRegex = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])$/;
    const fullDateRegex = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/;

   /* for (const term of terms) {
      if (numberRegex.test(term)) {
        numberTerms.push(term);
      } else if (dateRegex.test(term)) {
        dateTerms.push(term);
      } else {
        textTerms.push(term);
      }
    }*/

    for (const term of terms) {
      if (numberRegex.test(term)) {
        numberTerms.push(term);
      } else if (fullDateRegex.test(term)) {
        fullDateTerms.push(term);
      } else if (dateRegex.test(term)) {
        dateTerms.push(term);
      } else {
        textTerms.push(term);
      }
    }

    this.valorBusqueda.emit({
      textTerms: textTerms,
      numberTerms: numberTerms,
      dateTerms: dateTerms,
      fullDateTerms: fullDateTerms
    });
  }

}
