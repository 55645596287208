import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IClienteFormDetails,
  IClientesExpanded,
  IClientPersonalData,
  ideable,
} from '../../types/clientes.type';
import { Router } from '@angular/router';
import { ClientCrudService } from '../../services/client-crud.service';
import { Storage } from 'src/app/core/decorators/storage.decorator';
import { ClientPersonalData } from '../../models/ClientPersonalData.model';
import { ClientRegulaciones } from '../../models/Regulaciones.model';

@Component({
  selector: 'clientes-card',
  templateUrl: './cliente-card.component.html',
  styleUrls: ['./cliente-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClienteCardComponent {
  @Input() cliente: (IClientesExpanded & ideable) | null = null;
  possibleRedirections = CardRedirections;

  @Storage('clientPersonalData')
  clientPersonalData: IClientPersonalData | null;

  @Storage('regulacionesData')
  regulacionesData: IClienteFormDetails | null;
  /**
   * Solamente obtiene el detalle del cliente, no se procesa nada en particular
   * Debe mostrar los datos de clientPersonalData.model para reconocimiento
   * del cliente en cuestion, ademas de mostrar panel de accion para modificacion
   * o administracion del cliente
   */
  constructor(private router: Router, private _CrudClient: ClientCrudService) {}

  redirectTo(id: number, where: CardRedirections) {
    this.clientPersonalData = new ClientPersonalData(this.cliente);
    this.regulacionesData = new ClientRegulaciones(this.cliente);

    this.router.navigate([`/dashboard/clientes/${id}/${where}`]);
  }

  /**
   * Una vez el usuario haga click en el button, delegamos el
   * proceso de deleteButton al servicio de crudClient
   * @param id
   */
  deleteAction(id: number) {
    this._CrudClient.beignDeleteProcess(id);
  }
}

enum CardRedirections {
  DETALLE = 'detalle',
  EDITAR = 'editar',
  COMISIONES = 'comisiones',
  ELIMINAR = 'eliminar',
}
