<div class="form-container">
    <button mat-raised-button color="primary" (click)="openDialogAlta()">
      Alta Archivo Pago
    </button>
  </div>
<section class="list-panel">
    <!-- Swith case to match if we need to show terminal with users or only terminals -->
    <div>
  <!--   <app-tabla-resultados
        [data]="dataSource"
        [totalElements]="totalElements"
        [pageNumber]="pageNumberMov"
        (eventGetPage)="getNewPageRequest($event)"
        [configTable]="configTable"
        (emitEnableClientFile)="enableClientFile($event)"
        (emitGenerarFile)="openDialogGenerar($event)"
        (emitGenerarFileEdit)="openDialogEdit($event)"
      ></app-tabla-resultados> 
     <div class="table-container">
        <table class="table">
            <thead>
                <tr>
                    <th> ID Cliente</th>
                    <th> Cliente</th>
                    <th> Estado</th>
                    <th> Archivo Caja</th>
                    <th> Archivo Pago</th>
                    <th> Liquidación</th>
                    <th> Rendición</th>
                    <th> Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataSource | paginate: pagingSettings ">
                    <td>{{ item.id }}</td>
                    <td>{{ item.razon_social }}</td>
                    <td>{{ item.enabled }}</td>
                    <td>{{ item.archivo_caja }}</td>
                    <td>{{ item.archivo_pago }}</td>
                    <td>{{ item.liquidacion }}</td>
                    <td>{{ item.rendicion }}</td>
                    <td>
                      <button *ngIf="item.enabled=='Habilitado'"  (click)="enableClientFile(item.id, item.enabled,item.razon_social )" matTooltip="Deshabilitar cliente"> 
                        <img  src="../../../assets/img/enabled_false_cleinte.svg">
                      </button>
                      <button *ngIf="item.enabled=='Deshabilitado'"  (click)="enableClientFile(item.id, item.enabled,item.razon_social)" matTooltip="Habilitar cliente"> 
                        <img  src="../../../assets/img/enabled_true_cliente.svg">
                      </button>
                      <button  (click)="openDialog(item.razon_social,item.id,item.razon_social)" matTooltip="Generar archivo"> 
                        <img  src="../../../assets/img/generar_archivo.svg">
                      </button>
                      <button  matTooltip="Modificar" (click)="openDialog(item.razon_social,item.id,item.razon_social)" > 
                        <img  src="../../../assets/img/editar_datos.svg">
                      </button>
                    </td>
                    
                 
                   
                    <td>
                        <button class="btn-green-secondary " (click)="verDetallesMovimiento(item.id)"> 
                            <div *ngIf="isDesktopScreen ">Detalles</div>
                            <i *ngIf="!isDesktopScreen " class="fas fa-ellipsis-v "></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <pagination-controls maxSize=10 previousLabel="Anterior" nextLabel="Siguiente" [autoHide]="true" [responsive]="true" (pageChange)="nextOrPreviousPage($event)" id="server" class="my-pagination"></pagination-controls>
   </div>--> 
   <div class="mat-container-table">
    <div class="table-content">
      <!-- Contenido -->
      <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        class="table-limits"
      >
        <ng-container
          matColumnDef="{{ column }}"
          *ngFor="let column of columnsToDisplay"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ allColumnsObject[column] }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element[column] ?? "-" }}</td>
        </ng-container>
        <!-- Expanded column -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnsToDisplay.length"
          >
            <div
              class="example-element-detail"
              *ngIf="clientDetail"
              [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <app-payment-file-card [cliente]="clientDetail"></app-payment-file-card>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplay"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="getClientDetail(element)"
          (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>
      </table>
      <!-- Paginacion -->
      <mat-paginator
        class="pagination-limits"
        [pageSizeOptions]="matPaginationSettings.AvailablePaginationRange"
        [length]="matPaginationSettings.TotalElements"
        [pageSize]="matPaginationSettings.PaginationDefault"
        aria-label="Select page of GitHub search results"
        (page)="setterPagination($event)"
      ></mat-paginator>
    </div>
  </div>
  </div>
 
</section>

