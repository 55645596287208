import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tranferencia-resultado',
  templateUrl: './tranferencia-resultado.component.html',
  styleUrls: ['./tranferencia-resultado.component.css']
})
export class TranferenciaResultadoComponent implements OnInit {

  public dataSource: any;
  public pageNumberLiq:any;
  public totalElements:number;
  public configTable: any;
  loading:boolean=true;
  constructor() { }

  ngOnInit(): void {
  }

  nuevaSolicitud(page:any){
    //this.getLiquidaciones(page.pageNumber, page.pageSize);
  }

}
