import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiquidacionConsultaComponent } from './features/consulta-liquidacion/liquidacion-consulta.component';
import { NvaLiquidacionComponent } from './features/nva-liquidacion/nva-liquidacion.component';
import { LiquidacionComponent } from './liquidacion/liquidacion.component';
import { LiquidacionResultadosComponent } from './features/liquidacion-resultados/liquidacion-resultados.component';
import { TranferenciaResultadoComponent } from './transferencia/tranferencia-resultado/tranferencia-resultado.component';
import { TranferenciaConsultaComponent } from './transferencia/tranferencia-consulta/tranferencia-consulta.component';
import { DetalleLiquidacionComponent } from './features/detalle/detalle.component';
import { GenerarLiquidacionComponent } from './features/generar-liquidacion/generar-liquidacion.component';

const routes: Routes = [
  {
    path: '',
    component: LiquidacionComponent,
    children: [
      { path: 'consulta', component: LiquidacionConsultaComponent },
      { path: 'resultados', component: LiquidacionResultadosComponent },
      { path: 'nueva', component: NvaLiquidacionComponent },
      {
        path: 'transferencia',
        component: TranferenciaConsultaComponent,
      },
      {
        path: 'transferencia-resultado',
        component: TranferenciaResultadoComponent,
      },
    ],
  },
  {
    path: 's/:id/ver',
    component: DetalleLiquidacionComponent,
  },
  { path: 'generar-liquidacion', component: GenerarLiquidacionComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LiquidacionRoutingModule {}
