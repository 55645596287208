import { Injectable } from '@angular/core';
import { NavigationListWithBaseURL } from '../constants/navigation-list';

@Injectable({
  providedIn: 'root',
})
export class SidebarHandlerService {
  public static SidebarNavigationList = NavigationListWithBaseURL;

  /**
   * Manejador estatico, debido a que la mayoria son implementaciones
   * y no hay necesidades de constructores o dependencias internas
   */
  constructor() {}

  /**
   * Maneja el comportamiento del reset de la animacion en caso
   * de que algun dispositivo 'agrande su ancho'
   * @param viewPortState
   * @returns
   */
  static handleAnimationReset(viewPortState: viewPortMode): {widthState: viewPortMode, viewportState: viewPortMode} {
    // Si el ancho es menor a 800...
    if (window.innerWidth < 800) {
      /**
       * Reset al estado inicial mobile, y cambiamos el viewport state
       */
      return {
        widthState: this.widthStates()[0], // El estado inicial, reseteado
        viewportState: viewPortMode.MOBILE,
      };
    } else {
      /**
       * Sino, verificamos si antes era mobile para resetear el status
       */
      if (viewPortState === viewPortMode.MOBILE)
        return {
          widthState: this.widthStates()[0],
          viewportState: viewPortMode.DESKTOP,
        };

      return {
        widthState: this.widthStates()[0],
        viewportState: viewPortMode.MOBILE,
      };
    }
  }

  /**
   * Maneja con que estado inicial comenzara el componente
   * @returns
   */
  static setInitialViewState = () =>
    window.innerWidth > 800 ? viewPortMode.DESKTOP : viewPortMode.MOBILE;

  /**
   * Maneja con que estado se manejara segun el ancho de pantalla
   * @returns
   */
  static widthStates = () =>
    window.innerWidth > 800
      ? [viewPortMode.DESKTOP, viewPortMode.FULLFILLED]
      : [viewPortMode.MOBILE, viewPortMode.FULLFILLED];
}

export enum viewPortMode {
  MOBILE = 'initial:responsive',
  DESKTOP = 'initial',
  FULLFILLED = 'fullfilled',
}

export enum iconDirection {
  LEFT = 'left',
  RIGHT = 'right',
}
