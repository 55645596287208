<div class="content animated fadeIn">
    <div class="row  justify-content-center">
        <div class="column  mt-3 col-lg-8">
            <!--Consulta-->
            <div class="card">
                <div class="card-header">
                    <h4>Consulta</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="consultaForm">
                        <div class="card">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-3">
                                        <label for="cliente" class="form-label">Cliente</label>
                                        <select formControlName="cliente" class="form-select" aria-label="Default select example" [ngClass]="{ 'is-invalid border-red': submitted && form.cliente.errors }">
                                            <option selected disabled hidden value="" >Todos</option>
                                            <option *ngFor="let cliente of listaClientes" value="{{ cliente.id }}">{{ cliente.username }} - {{cliente.razon_social }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="mb-3">
                                        <label for="numero" class="form-label">Número</label>
                                        <input type="text" formControlName="numero" digitOnly decimal="decimal" inputmode="numeric" placeholder="Número de la consulta" class="form-control" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="mb-3">
                                        <label for="estado" class="form-label">Estado</label>
                                        <select formControlName="estado" class="form-select" aria-label="Default select example" (change)="changeEstado($event.target.value)">
                                            <option selected disabled hidden value="" >Todos</option>
                                            <option *ngFor="let estado of estados" value="{{ estado.id }}">{{ estado.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="mb-3">
                                        <label for="medioPago" class="form-label">Medio de pago</label>
                                        <select formControlName="medioPago" class="form-select" aria-label="Default select example">
                                            <option selected disabled hidden value="" >Todos</option>
                                            <option *ngFor="let pago of mediosPago"  value="{{ pago.id }}">{{ pago.marca }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="mb-3">
                                        <label for="estado" class="form-label">Referencia externa</label>
                                        <input type="text" formControlName="ref_externa" placeholder="Referencia externa" class="form-control" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="mb-3">
                                        <label for="estado" class="form-label">Código de barras</label>
                                        <input type="text" formControlName="codigo_barras" digitOnly decimal="decimal" inputmode="numeric" placeholder="Ej: 00230028304001000078000000200000100021000009" class="form-control" autocomplete="off">
                                    </div>
                                </div>
                                <!--Creación-->
                                <div class="subtitle mt-2">
                                    <h6>Creación</h6>
                                </div>
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Desde la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'creacion')" (focus)="datepicker('creacion')" (blur)="datepicker('creacion')" id="fromCreation" formControlName="desdeCreacion" class="input form-control" type="date" [ngClass]="{ 'is-invalid': submitted && form.desdeCreacion.errors }">
                                                <div *ngIf="submitted && dateCreacionIsSelected && form.desdeCreacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.desdeCreacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Hasta la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'creacion')" (blur)="datepicker('creacion')" id="toCreation" formControlName="hastaCreacion" class="input form-control " type="date" [ngClass]="{ 'is-invalid': submitted && form.hastaCreacion.errors }">
                                                <div *ngIf="submitted && dateCreacionIsSelected && form.hastaCreacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.hastaCreacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Pago-->
                                <div class="subtitle mt-2">
                                    <h6>Pago</h6>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Desde la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'pago')" (focus)="datepicker('pago')" (blur)="datepicker('pago')" id="fromPayment" formControlName="desdePago" class="input form-control" type="date" [ngClass]="{ 'is-invalid': submitted && form.desdePago.errors }">
                                                <div *ngIf="submitted && datePaymentIsSelected && form.desdePago.errors" class="invalid-feedback">
                                                    <div *ngIf="form.desdePago.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Hasta la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'pago')" (blur)="datepicker('pago')" id="toPayment" formControlName="hastaPago" class="input form-control " type="date" [ngClass]="{ 'is-invalid': submitted && form.hastaPago.errors }">
                                                <div *ngIf="submitted && datePaymentIsSelected && form.hastaPago.errors" class="invalid-feedback">
                                                    <div *ngIf="form.hastaPago.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Acreditacion-->
                                <div class="subtitle mt-2">
                                    <h6>Acreditación</h6>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Desde la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'acreditacion')" (focus)="datepicker('acreditacion')" (blur)="datepicker('acreditacion')" id="fromAccreditation" formControlName="desdeAcreditacion" class="input form-control" type="date" [ngClass]="{ 'is-invalid': submitted && form.desdeAcreditacion.errors }">
                                                <div *ngIf="submitted && dateAcreditacionIsSelected && form.desdeAcreditacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.desdeAcreditacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 ">
                                    <div class="mb-3 ">
                                        <div class="form-group row ">
                                            <label class="form-label ">Hasta la fecha</label>
                                            <div class="col-12 ">
                                                <input (change)="dateChange($event, 'acreditacion')" (blur)="datepicker('acreditacion')" id="toAccreditation" formControlName="hastaAcreditacion" class="input form-control " type="date" [ngClass]="{ 'is-invalid': submitted && form.hastaAcreditacion.errors }">
                                                <div *ngIf="submitted && dateAcreditacionIsSelected && form.hastaAcreditacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.hastaAcreditacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="buttons ">
                            <button type="button " class="btn btn-white" (click)="limpiar()">Limpiar</button>
                            <button type="button" (click)="onSubmit(consultaForm.value) " class="btn btn-green ml-15 ">Buscar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<swal #mensajeSwal title icon confirmButtonText cancelButtonText showCancelButton cancelButtonColor confirmButtonColor confirm></swal>