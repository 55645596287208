import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './admin-routing.module';

import { ConsultaComponent } from './archivo-base-folder/consulta/consulta.component';
import { DetallesTransaccionComponent } from './archivo-base-folder/detalles-transaccion/detalles-transaccion.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { FacturacionConsultaComponent } from './facturacion-folder/facturacion-consulta/facturacion-consulta.component';
import { FacturacionResultadosComponent } from './facturacion-folder/facturacion-resultados/facturacion-resultados.component';
import { BtnPagoComponent } from './btn-pago-folder/btn-pago/btn-pago.component';
import { ModalShareBtnPagoComponent } from './btn-pago-folder/modal-share-btn-pago/modal-share-btn-pago.component';
import { ListadoBtnPagoComponent } from './btn-pago-folder/listado-btn-pago/listado-btn-pago.component';
import { ReportesComponent } from './reportes/reportes.component';

import { TransferirComponent } from './transferir/transferir.component';
import { TranferenciaConsultaComponent } from './liquidacion-folder/transferencia/tranferencia-consulta/tranferencia-consulta.component';
import { TranferenciaResultadoComponent } from './liquidacion-folder/transferencia/tranferencia-resultado/tranferencia-resultado.component';
import { WhastsappComponent } from './whastsapp/whastsapp.component';
import { GestionReportesComponent } from './gestion-reportes/gestion-reportes.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { LiquidacionModule } from './liquidacion-folder/liquidacion.module';
import { ClientesModule } from './clientes/clientes.module';
import { MaterialModule } from 'src/app/material/material.module';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../components/shared/shared.module';
import { SidebarComponent } from '../components/shared/deprecated-sidebar/sidebar.component';
import { SideNavigationModule } from '../components/shared/side-navigation/side-navigation.module';
import { BlacklistComponent } from './blacklist/blacklist.component';
import { BlacklistListaComponent } from './blacklist/blacklist-lista/blacklist-lista.component';
import { BlacklistNewComponent } from './blacklist/blacklist-new/blacklist-new.component';
import { BlacklistUpdateComponent } from './blacklist/blacklist-update/blacklist-update.component';
import {NgxMaskModule, IConfig } from 'ngx-mask';
import { ArchivosProcesadosComponent } from './archivos-procesados/archivos-procesados.component';
import { CobranzasComponent } from './cobranzas/cobranzas.component';
import {TerminalModule} from './terminals/terminal.module';
import { PaymentFileComponent } from './payment-file/payment-file.component';
import { DialogTriggerFileComponent } from './payment-file/dialog-trigger-file/dialog-trigger-file.component';
import { DialogPaymentFileComponent } from './payment-file/dialog-payment-file/dialog-payment-file.component';
import { PaymentFileCardComponent } from './payment-file/payment-file-card/payment-file-card.component';
import { DialogLiquidacionComponent, MY_DATE_FORMATS } from './payment-file/dialog-liquidacion/dialog-liquidacion.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    FacturacionConsultaComponent,
    ConsultaComponent,
    DetallesTransaccionComponent,
    UsuariosComponent,
    SidebarComponent,
    EstadisticasComponent,
    FacturacionResultadosComponent,
    BtnPagoComponent,
    ModalShareBtnPagoComponent,
    ListadoBtnPagoComponent,
    ReportesComponent,
    TransferirComponent,
    TranferenciaConsultaComponent,
    TranferenciaResultadoComponent,
    WhastsappComponent,
    GestionReportesComponent,
    DashboardComponent,
    BlacklistComponent,
    BlacklistListaComponent,
    BlacklistNewComponent,
    BlacklistUpdateComponent,
    ArchivosProcesadosComponent,
    CobranzasComponent,
    PaymentFileComponent,
    DialogTriggerFileComponent,
    DialogPaymentFileComponent,
    PaymentFileCardComponent,
    DialogLiquidacionComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    LiquidacionModule,
    ClientesModule,
    RouterModule.forChild(APP_ROUTES),
    SideNavigationModule,
    TerminalModule,
    SharedModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
})
export class DashboardModule {}
