class NewTerminalRequestModel {
  sn: string;
  prisma_id: string;
  marca: string;
  modelo: string;
  enabled: boolean;
  estado_descripcion: string;

  constructor(serialNumber: string, prismaId: string, marca: string, modelo: string, estadoDescripcion: string) {
    this.sn = serialNumber;
    this.prisma_id = prismaId;
    this.marca = marca;
    this.modelo = modelo;
    this.estado_descripcion = estadoDescripcion;
    this.enabled = true;
  }
}

export default NewTerminalRequestModel;
