<article *ngIf="cliente">
  <!-- TODO: listado-cliente-detalle -->
  <mat-form-field>
    <mat-label>Nombre legal</mat-label>
    <input matInput type="text" [value]="cliente.nombre_legal" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Razon social</mat-label>
    <input matInput type="text" [value]="cliente.razon_social ?? ''" readonly />
  </mat-form-field>
  <!-- <mat-form-field class="example-form-field">
    <mat-label>Nombre de usuario</mat-label>
    <input matInput type="text" [value]="cliente.username" readonly>
  </mat-form-field> -->
  <mat-form-field>
    <mat-label>Nro de documento</mat-label>
    <input matInput type="text" [value]="cliente.documento" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Fecha de registro</mat-label>
    <input matInput type="text" [value]="cliente.create_at | date" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Semana de liquidacion</mat-label>
    <input
      matInput
      type="number"
      [value]="cliente.semana_liquidacion"
      readonly
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Sitio web</mat-label>
    <input matInput type="text" [value]="cliente.web" readonly />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email | Correo electronico</mat-label>
    <input matInput type="text" [value]="cliente.email" readonly />
  </mat-form-field>
</article>

<div class="detail-panel">
  <button
    mat-raised-button
    color="accent"
    (click)="redirectTo(cliente.id, possibleRedirections.DETALLE)"
  >
    Detalle
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="redirectTo(cliente.id, possibleRedirections.EDITAR)"
  >
    Editar
  </button>
  <button
    mat-raised-button
    color="warn"
    (click)="redirectTo(cliente.id, possibleRedirections.COMISIONES)"
  >
    Comisiones
  </button>
  <button mat-raised-button color="error" (click)="deleteAction(cliente.id)">
    Deshabilitar
  </button>
</div>
