import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IComisionesCliente,
  IComisionesClientePost,
} from '../../types/comisiones-cliente.type';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ComisionesClientePost } from '../../models/ComisionesClientePost.model';

@Component({
  selector: 'cliente-comision-card',
  templateUrl: './comision-card.component.html',
  styleUrls: ['./comision-card.component.scss'],
})
export class ComisionCardComponent implements OnInit {
  /**
   * Responsable de mostrar los datos de la comision y de
   * permitirle al usuario editarlos o eliminarlos si es el caso.
   *
   * Al editar y guardar cambios, este delegara la accion al comision-cliente
   */

  @Input() comision: IComisionesCliente | null = null;
  @Output() changesEmitter: EventEmitter<IComisionesClientePost> | null = null;

  comisionClienteFormGroup: FormGroup;
  isDisabled = true; // Refiriendose a si es editable o no

  constructor(private _formBuilder: FormBuilder) {
    this.changesEmitter = new EventEmitter<IComisionesClientePost>();
    this.comisionClienteFormGroup = this.makeFormGroup(this.comision);
  }

  ngOnInit(): void {
    this.comisionClienteFormGroup = this.makeFormGroup(this.comision);
  }

  /**
   * Emite la accion y delega la responsabilidad al componente padre
   * @param form
   */
  saveNewData(form: FormGroup) {
    // Tomamos los datos anteriores y luego sobreescribimos los nuevos
    this.changesEmitter.emit(
      new ComisionesClientePost({
        ...form.value,
        medio_pago: this.comision.medio_pago,
      })
    );
    this.toggleEdit();
  }

  /**
   * Segun si el formulario es editable o no, el habilitara los campos
   */
  toggleEdit() {
    this.isDisabled = !this.isDisabled;
    const propertValue = this.isDisabled ? 'disable' : 'enable';

    // Segun si esta habilitado o no, ejecutara .enable() o .disable()
    this.form_medio_pago[propertValue]();
    this.form_comision[propertValue]();
    this.form_gasto[propertValue]();
  }

  private makeFormGroup(data: IComisionesClientePost | null = null) {
    const formData = new ComisionesClientePost(data);

    return this._formBuilder.group({
      medio_pago: this.makeFormField(formData.medio_pago, this.isDisabled),
      comision: this.makeFormField(formData.comision, this.isDisabled),
      gasto: this.makeFormField(formData.gasto, this.isDisabled),
    });
  }

  private makeFormField<Type>(value: Type | any, disabled: boolean) {
    return new FormControl({ value, disabled });
  }

  get form_medio_pago() {
    return this.comisionClienteFormGroup?.get('medio_pago');
  }
  get form_comision() {
    return this.comisionClienteFormGroup?.get('comision');
  }
  get form_gasto() {
    return this.comisionClienteFormGroup?.get('gasto');
  }
}
