import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DatePipe, Location, CurrencyPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeARS from '@angular/common/locales/es-AR';
import { TransaccionesService } from 'src/app/services/transacciones.service';
import { SolicitudPago } from 'src/app/models/solicitud_pago.models';
import { ApiService } from 'src/app/services/api.service';
import { RespuestaSolicitudPago } from 'src/app/models/respuesta_solicitud_pago.models';
import { FunctionsService } from 'src/app/services/functions.service';

@Component({
  selector: 'app-detalles-transaccion',
  templateUrl: './detalles-transaccion.component.html',
  styleUrls: ['./detalles-transaccion.component.css']
})
export class DetallesTransaccionComponent implements OnInit {

  displayedColumns =
  ['Nombre del cliente', 'Descripción', 'Fecha de creación',
   'Fecha de vencimiento','2° fecha de vencimiento',
   'Importe', 'Fecha de actualización','URL ID', 'Cliente',
   'Tipo de pago','Referencia externa','Fecha del importe',
   'Importe abonado', 'Importe comisión','Código de barras'];

  public dataSource = [];

  id: number;
  detalle: any;
  // detalle: SolicitudPago;
  @Output() loading: EventEmitter<boolean>;

  constructor( private location: Location,
    private _transaccionesService: TransaccionesService,
    private _apiService: ApiService,
    private _functionsService: FunctionsService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe ) {
      this.loading = new EventEmitter();
    }

  ngOnInit(): void {
    
    registerLocaleData(localeARS, 'es-ARS');
    this.id = +this._transaccionesService.getIdDetalle();
    this._functionsService.imprimirMensaje(this.id, "Mi id detalle transaccion");
    //this.detalle = this._transaccionesService.getSolicitudPago(this.id)[0];


    if(this.detalle == null || this.detalle.length == 0) {
     /* this._apiService.getOneSolicitudPago(this.id)
      .then((response:any) => {

        // this._transaccionesService.setRespuestaSolicitudPago(listaDatos.content);
        this.detalle = response;
        this.cargarTabla();
        // this.loading = false;
        this.loading.emit(false);
      })
      .catch(error => {
        this._functionsService.imprimirMensaje(error, "Error en respuesta solicitud pago con filtro");
        // this.loading = false;
        this.loading.emit(false);
      });*/
    }else{
      this._functionsService.imprimirMensaje(this.detalle, "Mi detalle en detalle transaccion");
      this.cargarTabla();
    }
  }

  Volver(){
    this.location.back();
    localStorage.removeItem('idxTransaccion');
  }

  cargarTabla(){

    this.dataSource = [];
    var nodisponible = "(no disponible)";

    if(this.detalle != null){
      this.dataSource.push(
        [this.detalle.cliente != null? this.detalle.cliente.nombre_legal: nodisponible],
        [this.detalle.descripcion != null? this.detalle.descripcion: nodisponible],
        [this.detalle.create_at != null? this.datePipe.transform(this.detalle.create_at, "dd-MM-yyyy HH:mm:ss"): nodisponible],
        [this.detalle.fecha_vto != null? this.datePipe.transform(this.detalle.fecha_vto, "dd-MM-yyyy"): nodisponible],
        [this.detalle.fecha_segundo_vto != null? this.datePipe.transform(this.detalle.fecha_segundo_vto, "dd-MM-yyyy"): nodisponible],
        [this.detalle.importe != null? this.currencyPipe.transform((this.detalle.importe/100), 'CAD', '$', '2.2-2', 'es-ARS'): nodisponible],
        [this.detalle.updated_at != null? this.datePipe.transform(this.detalle.updated_at, "dd-MM-yyyy HH:mm:ss"): nodisponible],
        [this.detalle.url_id != null? this.detalle.url_id: nodisponible],
        [this.detalle.cliente != null? this.detalle.cliente.razon_social: nodisponible],
        [this.detalle.medio_pago != null? this.detalle.medio_pago.forma_pago.tipo: nodisponible],
        [this.detalle.referencia_externa != null? this.detalle.referencia_externa: nodisponible],
        [this.detalle.fecha_importe != null? this.datePipe.transform(this.detalle.fecha_importe, "dd-MM-yyyy HH:mm:ss"): nodisponible],
        [this.detalle.importe_bonificado != null? this.currencyPipe.transform((this.detalle.importe_bonificado/100), 'CAD', '$', '2.2-2', 'es-ARS'): nodisponible],
        [this.detalle.importe_comision =  this.detalle.importe_comision != null? this.currencyPipe.transform((this.detalle.importe_comision/100), 'CAD', '$', '1.2-2', 'es-ARS'): nodisponible],
        [this.detalle.codigo_barra != null? this.detalle.codigo_barra['codigo_barra']: nodisponible]);
    }

  }

}
interface detalleTransaccion{
  nombre: string;
  descripcion: string;
  fechaCreacion: Date;
  fechaVencimiento: Date;
  importe: number;
  fechaActualizacion: Date;
  idURL: string;
  clienteID: number;
  tipoPago: string;
  referenciaExterna: string;
  fechaImporte: Date;
  importeBonificado: string;
  codigoBarras:string;
}
