import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { FechaPipe } from 'src/app/pipes/fecha.pipe';


@Component({
  selector: 'app-gestion-reportes',
  templateUrl: './gestion-reportes.component.html',
  styleUrls: ['./gestion-reportes.component.css']
})
export class GestionReportesComponent implements OnInit {
  
  public loading:boolean;
  public configTable:any;
  public datos: any[]; 
  public dataSource: any[]; 
  public dataSource2: any; 
  public userReporte_id:any;
  public contenidoUrl:boolean=false;
  public sinContenidoUrl:boolean=false;
  public reportesVacio:boolean=false;
  public reportes:boolean=false;
  pages: number = 1;
  public arrayAux: any = []; 
  StackMoment!:string
  stacktConvert!:string;
  stack!:string;
  stackDateString!:string;
  stackDate!:Date;
 
  constructor(private _api:ApiService,private datePipe:DatePipe) { }

  ngOnInit(): void {
    const headers = {
      columns_show:  ['Nº','Fecha de creación','Tipo de reporte','Filtros usados'],
    }
    this.configTable = {
      headers,
      
    }
    

    let user = JSON.parse(localStorage.getItem("userReporte"));
    this._api.getUsuario(user)
    .then((response) => {
      this.userReporte_id = response
      this._api.get_reportes(this.userReporte_id.id)
      .then( (response:any) =>{   
        this.datos = response;
        this.generarTabla();
      })
      .catch(error => {
        console.log("ERROR en resultados ", error);
      });
     
    })
    .catch(
      (error)=>{
        console.log(error);
    });

   
  }

  


  generarTabla(){
    var array = Array<any>();
    var urlReporte;
    if(this.datos.length != 0){
      this.reportes = true;
      this.reportesVacio = false;
      
      for (let i = 0; i < this.datos.length; i++) {
        this.StackMoment=new Date(this.datos[i].create_at).toISOString();
        this.stacktConvert=moment( this.StackMoment).format("DD-MM-yyyy HH:mm")
        var dato: any;
            dato = {
              idReporte:this.datos[i].idReporte,
              //create_at:this.datePipe.transform(this.datos[i].create_at, "dd-MM-yyyy") != null? this.datePipe.transform(this.datos[i].create_at, "dd-MM-yyyy"): "No disponible",
              create_at:this.stacktConvert,
              descripcionReporte:this.datos[i].descripcionReporte,
              filtrosConsulta:this.datos[i].filtrosConsulta,
              urlReporte:this.datos[i].urlReporte,
              contenido: this.contenidoUrl
            }
        array.push(dato);
       }
      this.dataSource = array;
     

      this.dataSource2 = new MatTableDataSource(this.dataSource);
    }else{
      console.log("no tiene datos"); 
      this.reportes = false;
      this.reportesVacio = true;
    }
   
      
      
    
    
    //console.log("dataSource"); 
    //console.log(this.dataSource);
  }


  filtrar(dato: any){
    if(dato != null){  
      if(this.arrayAux == "" || this.arrayAux == null){
        this.arrayAux = this.dataSource; //guarda el dataSource completo
      }
      var filtro = new  FechaPipe();
      this.dataSource = filtro.transform(this.arrayAux, dato);
      if(dato == ""){
        this.dataSource = this.arrayAux;
      }
    }
      
  }

  emitirExportarExcel(id,indice){
   let url = this.dataSource[indice].urlReporte;
   console.log(url);
   

  }



}
