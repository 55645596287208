
   <section>
    <div class="search mb-3">
        <img class="search-img" src="../../../../assets/img/search.svg">
        <input matInput  (keyup)="buscarDato(buscarTexto.value)" (search)="buscarDato(buscarTexto.value)" type="search"
         #buscarTexto placeholder="Buscar" #trigger (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()">
    </div>
   </section>
   <ng-template #tooltipContent>
        <div class="text-label">
            <label> <b>Ejemplos de busqueda: </b></label>
            <p>Filtrar por la fecha del nombre de archivo: dd-mm, dd-mm, dd-mm, dd-mm</p>
            <p>Filtrar por otros campos:</p>
            <p>Por fecha completa dd-mm-aaaa busca por los campos de las columnas.</p>
            <p>Por Texto.</p>
        </div>
   </ng-template>
   <ng-template cdkConnectedOverlay 
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayOpen]="isTooltipVisible">
  <div class="custom-tooltip-content">
    <ng-container *ngTemplateOutlet="tooltipContent"></ng-container>
  </div>
</ng-template>

