import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {
  IClientPersonalData,
  IClienteFormDetails,
} from '../types/clientes.type';
import { ClientPersonalData } from '../models/ClientPersonalData.model';
import { ClientRegulaciones } from '../models/Regulaciones.model';
import { Cliente } from '../models/clientes.model';
import { ManageClientService } from '../services/manage-client.service';
import { ActivatedRoute } from '@angular/router';
import { ClientesService } from '../services/clientes.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.css'],
})
export class NuevoClienteComponent implements OnInit, OnDestroy {
  /**
   * Responsabilidad:
   * Controlar como contenedor de formularios de manera visual
   * - Encargarse de que al final del proceso, los datos lleguen
   * al servicio correspondiente
   * TODO: En caso de que la URL maneje un id, debera de hacer la peticion
   * y guardar los datos necesarios en los objetos de sessionStorage
   *
   * FIXME: Doble click para manipulacion de datos
   * FIXME: Cuando vuelvo para tras y luego vuelvo adelante no actualiza correctamente
   */

  id: number | null = null;

  /**
   * Manejador del stepper
   */
  currentStepIndex = 0;
  clientPersonalData: IClientPersonalData | null = null;
  regulacionesData: IClienteFormDetails | null = null;

  isValid = {
    clientPersonalData: false,
    regulacionesData: false,
  };

  constructor(
    private _NewClient: ManageClientService,
    private _ActivatedRoute: ActivatedRoute,
    private _Clientes: ClientesService
  ) {}


  ngOnInit(): void {
    this.id = this._ActivatedRoute.snapshot.params.id;
    this.getClientByIdParam();

    this._NewClient.clientPersonalData.subscribe((newValue): void => {
      this.clientPersonalData = newValue;
    });
    this._NewClient.regulacionesData.subscribe((newValue): void => {
      this.regulacionesData = newValue;
    });
  }

  ngOnDestroy(): void {
    this._NewClient.regulacionesData.next(null);
    this._NewClient.clientPersonalData.next(null);
  }

  nextMatStep() {
    this.currentStepIndex = this.currentStepIndex++;
  }
  prevMatStep() {
    this.currentStepIndex = this.currentStepIndex--;
  }

  /**
   * Obtiene todos los datos del componente datos-personales
   * Luego, los guarda en la variable clientPersonalData
   * @param client
   */
  personalDataFormSuccessHandler(client: IClientPersonalData): void {
    this._NewClient.clientPersonalData.next(new ClientPersonalData(client));
    this.isValid.clientPersonalData = true;
    this.nextMatStep();
  }

  /**
   * Obtiene todos los datos del componente form-regulaciones
   * Luego, los guarda en la variable regulacionesData
   * @param regulacionesConfig
   */
  clientRegulacionesFormSuccessHandler(
    regulacionesConfig: IClienteFormDetails
  ): void {
    this._NewClient.regulacionesData.next(new ClientRegulaciones(regulacionesConfig));
    this.isValid.regulacionesData = true;
    this.nextMatStep();
  }

  /**
   * Una vez obtenidos todos los datos, comenzara el proceso de guardado
   * y generacion de credenciales por el servicio intermedio
   */
  postNewClientHandler(): void {
    const formatedClient = new Cliente({
      ...this.clientPersonalData,
      ...this.regulacionesData,
    });
    this._NewClient.makeNewClientProcess(formatedClient, this.id);
  }

  getClientByIdParam(): void {
    if (this.id) {
      this._Clientes.getClienteID(this.id).subscribe({
        next: (response) => {
          this._NewClient.clientPersonalData.next(new ClientPersonalData(response));
          this._NewClient.regulacionesData.next(new ClientRegulaciones(response));
        },
        error: (error): void => {
          Swal.fire(
            'ERROR',
            'Ocurrio un error cargando el cliente, por favor intentelo nuevamente mas tarde',
            'error'
          );
        },
      });
    }
  }

}
