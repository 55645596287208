<h1 mat-dialog-title>Cliente creado exitosamente</h1>
<div mat-dialog-content *ngIf="bearerCredential && webHookCredential">
  <mat-divider></mat-divider>
  <p>
    Credencial Bearer: <br />
    {{ bearerCredential }}
    <button
      (click)="copyValue(bearerCredential)"
      mat-raised-button
      color="accent"
    >
    <mat-icon>file_copy</mat-icon>
    </button>
  </p>
  <p>
    Credencial Webhook: <br />
    {{ webHookCredential }}
    <button
      (click)="copyValue(webHookCredential)"
      mat-raised-button
      color="accent"
    >
    <mat-icon>file_copy</mat-icon>
    </button>
  </p>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-buton
    color="primary"
    mat-button
    mat-dialog-close
    cdkFocusInitial 
    (click)="volver()"
  >
    Aceptar
  </button>
</div>
