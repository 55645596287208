import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listado-btn-pago',
  templateUrl: './listado-btn-pago.component.html',
  styleUrls: ['./listado-btn-pago.component.css']
})
export class ListadoBtnPagoComponent implements OnInit {

  public valorBusquedaTermino:any;
  items= [2,2,2,2,2,2,2,2];
  
  constructor( private router: Router) { }

  ngOnInit(): void {
  }

  nuevoBotonPago(){
    this.router.navigate(['dashboard/nvo_btn_pago']);
  }
  filtrar(termino:string){
    // console.clear();
    this.valorBusquedaTermino = termino;
  }
}
