import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-share',
  templateUrl: './modal-share-btn-pago.component.html',
  styleUrls: ['./modal-share-btn-pago.component.css']
})
export class ModalShareBtnPagoComponent implements OnInit {

  link_pago:string ="https://helipagos.com";
  isMobile:boolean =false;
  link_whatsapp = "whatsapp://send?text=Ingresá al link, elegí cómo pagar, ¡y listo!";
  
  constructor( private deviceService: DeviceDetectorService ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

    if(this.isMobile){
        this.link_whatsapp = `https://api.whatsapp.com/send?text=Ingresá al link, elegí cómo pagar, ¡y listo! ${this.link_pago}`;
      }else{
        this.link_whatsapp = `https://web.whatsapp.com/send?text=Ingresá al link, elegí cómo pagar, ¡y listo! ${this.link_pago}`;
      }
  }
  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
