import { Injectable } from '@angular/core';
import { NavigationListWithBaseURL } from '../constants/navigation-list';
import {
  INavigationNode,
  INavigationNodeWithChildren,
} from '../types/nav-node.type';

@Injectable({
  providedIn: 'root',
})
export class NavigationHandlerService {
  public SidebarNavigationList = NavigationListWithBaseURL;

  constructor() {}

  static BASEURL(item: INavigationNode, href: string): INavigationNode {
    return {
      ...item,
      href: `${href}/${item.href}`,
    };
  }

  static URL(...args: string[]): string {
    return `dashboard/${args.join('/')}`;
  }

  static CHILDREN_URL(...args: string[]): string {
    return `${args.join('/')}`;
  }

  findNodeByHref(
    root: INavigationNodeWithChildren[],
    href: string
  ): INavigationNode | null {
    for (const node of root) {
      if (node.href === href) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundInChild = this.findNodeByHref(node.children, href);
        if (foundInChild) {
          return foundInChild;
        }
      }
    }
    return null;
  }
}
