import { Cliente } from 'src/app/models/cliente.models';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ArchivoProcesadoService } from 'src/app/services/archivo-procesado.service';
import { ListaCliente } from '../movimientos/consulta/consulta.component';
import { Observable } from 'rxjs';
import { FunctionsService } from 'src/app/services/functions.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-archivos-procesados',
  templateUrl: './archivos-procesados.component.html',
  styleUrls: ['./archivos-procesados.component.scss']
})
export class ArchivosProcesadosComponent implements OnInit {

  public loading:boolean;
  public configTable: any;
  dataSource:any[];
  public pageNumberMov:any;
  public totalElements:number;
  totalPages:number;
  public responseArchivos:any[];
  consultaForm!: FormGroup;
  public listaClientes;
  array = Array<any>();
  related:any;
  clientes$: Observable<ListaCliente[]>;
  relatedCities:any;
  countries$: Observable<ListaCliente[]>;
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;
  constructor( private archivoService: ArchivoProcesadoService,private datePipe:DatePipe,
                private formBuilder: FormBuilder,private apiService: ApiService,
                private _functionsService: FunctionsService,private currencyPipe: CurrencyPipe) {
    this.consultaForm = this.formBuilder.group({
      cliente: [''],
      tipo_archivo: ['']
    });
   }

  async ngOnInit(): Promise<void> {
    
    let headers = {
      columns_show:  ['Id','Cliente','Tipo de archivo','Nombre del archivo','Servicio','Fecha de presentación','Período','Fecha proceso','Cantidad de registros','Total 1º vto.','Total 2º vto.','Estado'],
      columns_search:  ['id', "nombre_legal","tablaOrigen","nombre","servicio","fechaPresentacion","periodo","fechaProceso","cantidadRegistro","totalPrimerVencimineto","TotalSegundoVencimiento","estado"],
    };
    this.configTable = {
      headers: headers,
      exportToExcel: false,
      verDetallesTarjeta:false,
      buscar:true,
      verDetalles:false,
      functionUsuarios:false,
      functionBlacklist:false,
    }

    this.loading = true;
    this.pageNumberMov = 1;
    this.cargarTabla(this.pageNumberMov,10);

    await this.apiService.getClientes()
      .then((response) =>{
        this.listaClientes = response;
        
        for (let i = 0; i < this.listaClientes.length; i++) {
            let dato: any = {
              id:this.listaClientes[i].id,
              name:this.listaClientes[i].razon_social
            }
            this.array.push(dato);
        }
      
        //console.log(response);
        // this._functionsService.imprimirMensaje(this.listaClientes, "Clientes: ");
      })
      .catch(()=>{
        this._functionsService.configSwal(this.mensajeSwal, `Error al intentar cargar los clientes`, "error", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire();
      });
      this.clientes$ = this.consultaForm.controls.cliente.valueChanges.pipe(
        startWith(null),
        map(
          text => text? 
            this.array.filter(
              country => 
                String(country.name).toLowerCase().includes(text.toLowerCase())
            ) :
            this.array
        )
      );

  }

  cargarTabla(page: number, pageSize: number){
    this.apiService.getlistaArchivos(page-1,pageSize,this.consultaForm).subscribe((response : any) => {
      this.responseArchivos = response?.content;
      this.totalElements = response.totalElements;
      this.totalPages = response.totalPages;
      this.preparardataSource();
      this.loading = false;
    },

    
    (error)=>{
      this.dataSource = error;
      this.loading = false;
      console.log("ERROR EN TABLA ARCHIVOS PROCESADOS", error);
      //Limpia la consola
      console.clear();
       
    });
  }

  preparardataSource(){
    var array = Array<any>();
    var nodisponible = "No disponible";
   
    for (let i = 0; i < this.responseArchivos.length; i++) {
     
      var dato: any;
          dato = {
            id: this.responseArchivos[i].id_archivo_proceso  != null? this.responseArchivos[i].id_archivo_proceso: 0,
            nombre_legal:this.responseArchivos[i].nombreLegal  != null? this.responseArchivos[i].nombreLegal: "-",
            tablaOrigen:this.responseArchivos[i].tablaOrigen  != null? this.responseArchivos[i].tablaOrigen: "-",
            nombre: this.responseArchivos[i].nombreArchivo != null? this.responseArchivos[i].nombreArchivo: nodisponible,
            servicio: this.responseArchivos[i].servicio != null? this.responseArchivos[i].servicio: nodisponible,
            fechaPresentacion:this.responseArchivos[i].fechaPresentacion != null? this.datePipe.transform(this.responseArchivos[i].fechaPresentacion, "dd-MM-yyyy") : nodisponible,
            periodo:this.responseArchivos[i].periodo != null? this.responseArchivos[i].periodo: "-",
            fechaProceso:this.responseArchivos[i].fechaProceso != null? this.datePipe.transform(this.responseArchivos[i].fechaProceso, "dd-MM-yyyy HH:mm"): "-",
            cantidadRegistro:this.responseArchivos[i].cantidadRegistro != null? this.responseArchivos[i].cantidadRegistro: "-",
            totalPrimerVencimineto:this.responseArchivos[i].totalPrimerVencimiento != null? this.currencyPipe.transform((this.responseArchivos[i].totalPrimerVencimiento), 'CAD', '$', '2.2-2', 'es-ARS'): "-",
            TotalSegundoVencimiento:this.responseArchivos[i].totalSegundoVencimiento != null?this.currencyPipe.transform((this.responseArchivos[i].totalSegundoVencimiento), 'CAD', '$', '2.2-2', 'es-ARS'): "-",
            estado:this.responseArchivos[i].estado != null? this.responseArchivos[i].estado: "-"
          }
      array.push(dato);
    }
    
    this.dataSource = array;
  }

  nuevaSolicitud(page:any){
    this.pageNumberMov = page.pageNumber;
    this.cargarTabla(page.pageNumber, page.pageSize);
  }
  
  select(selected: any) {
    this.pageNumberMov = 1;
    this.related = this.array.filter(cliente => cliente.name == selected.option.value);
    this.consultaForm.value.cliente = this.related[0].id;
    this.cargarTabla(1,10);
   }

   selectArchivo(){
     if(this.related){
      this.consultaForm.value.cliente = this.related[0].id;
     }
    this.cargarTabla(1,10);
   }

}
