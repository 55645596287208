
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  title = 'HelipagosDashboard';
  @ViewChild('sesionSwal') mensajeSwal!: SwalComponent
  constructor( ) { }

  ngOnInit(): void {
    
    
    // this.timer = new IdleTimer({
    //   //tiempo en segundos
    //   timeout: 1200, //expira despues de 20 minutos
    //   onTimeout: () => {
    //     this._functionsService.imprimirMensaje("nada", "Sesión cerrada por inactividad");
    //     this.TokenService.logOut()
    //     this._functionsService.configSwal(this.mensajeSwal, `Sesion Caducada`, "error", "Aceptar", "", false, "", "");
    //     this.mensajeSwal.fire();
    //   }
    // });
  }
  ngOnDestroy() {
    // this.timer.clear();
  }

}
