<app-spinner  *ngIf="loading"></app-spinner>
    <div class="title">
        <h1>Reportes</h1>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12" *ngIf="reportes">
        <app-buscador (valorBusqueda)="filtrar($event)"></app-buscador>
    </div>
    

    <div class="divTable" *ngIf="reportes">
        <div class="col-12">
            <!--TABLA starts-->
             <div class="table-container animated fadeIn">
                <div class="table-body">
                   
                     <table class="table table-fixed">
                            <thead>
                                <tr>
                                    <th *ngFor="let head of configTable.headers.columns_show; let i = index" class="text-wrap text-center">
                                        <a class="invert" (click)="sort(_configTable.headers.columns_search[i])" (click)="asc = !asc">
                                        {{ head }}
                                        </a>
                                    </th>
                                    <th  class="text-wrap text-center">
                                        Descarga
                                    </th>
                                </tr>
                            </thead>
                            <tbody #tableData>
                                <tr *ngFor=" let dato of dataSource  | paginate: { itemsPerPage: 10, currentPage: pages }; let i = index"  >
                                    <td>
                                        <div class="text-center">{{i+1}}</div>
                                    </td>
                                    <td >
                                        <div class="text-center">{{dato.create_at}}</div>
                                    </td>
                                    <td >
                                        <div class="text-center">{{dato.descripcionReporte}}</div>
                                    </td>
                                    <td >
                                        <div class="text-center">{{dato.filtrosConsulta}}</div>
                                    </td>
                                    <td >
                                        <button class="btn-excel" title='Exportar detalles a excel'>
                                            <a href="{{dato.urlReporte}}"><i class="fas fa-file-excel"></i></a>
                                        </button>
                                    </td>
                                   
                                </tr>
                            </tbody>
                        </table> 
                        <pagination-controls  previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="pages = $event" class="my-pagination"></pagination-controls>
                       
                    
                 
                </div>
                
            </div>
           
           
        </div>
        <swal #failSwal title="Error inesperado" icon="warning" confirmButtonText="Volver atrás" confirmButtonColor="#53BAAB"></swal>
        <swal #sesionSwal title="Sesión expirada." icon="warning" confirmButtonText="Aceptar" confirmButtonColor="#53BAAB"></swal>
        
    </div>
   

 <!--No se han encontrado resultados-->
 <div *ngIf="reportesVacio" class="row justify-content-center animated fadeIn mt-5">        
    <div class="col-xxl-4 col-xl-5 col-lg-8 col-md-6 col-sm-10">
        <div class="card not-found text-center">
            <div class="card-body">
                <i class="fa fa-times-circle-o"></i>
                <h5 class="mt-2">No se encontraron resultados de reportes generados</h5>
               
            </div>
        </div>
    </div>
</div>
 