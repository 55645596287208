/**
 * Icons List to use in all the app
 * @example
 * // HOW TO USE ME:
 * import { Icon } from 'src/app/constants/Icons';
 *
 * class MyComponent {
 *
 * // In template.html, use this props as img src or [attr.xlink:href]="svgPath#mi-icono"
 * specificIcon = ICON.INICIO;
 * iconDB = ICON;
 *
 * }
 *
 *
 * TODO: Make an icon library for helipagos
 */

export enum ICON {
    INICIO = '/assets/img/ic_inicio.svg',
    ARCHIVO_BASE = '/assets/img/ic_archivo_base.svg',
    ARCHIVOS_TRANSMISION = '/assets/img/ic_archivos_transmision.svg',
    MENU_BURGER = '/assets/img/ic_burger.svg',
    CALENDAR = '/assets/img/ic_calendar.svg',
    CERRAR = '/assets/img/ic_cerrar.svg',
    CHEVRON_DOWN = '/assets/img/ic_chevron_down.svg',
    CHEVRON_LEFT = '/assets/img/ic_chevron_left.svg',
    CHEVRON_RIGHT = '/assets/img/ic_chevron_right.svg',
    CHEVRON_UP = '/assets/img/ic_chevron_up.svg',
    CLIENTES = '/assets/img/ic_clientes.svg',
    COMPARTIR = '/assets/img/ic_compartir.svg',
    CONCILIACIONES = '/assets/img/ic_conciliaciones.svg',
    BORRAR = '/assets/img/ic_delete.svg',
    GESTION_REPORTES = '/assets/img/ic_gestion_reportes.svg',
    LIQUIDACIONES = '/assets/img/ic_liquidaciones.svg',
    MOVIMIENTOS = '/assets/img/ic_movimiento.svg',
    RENDICIONES = '/assets/img/ic_rendiciones.svg',
    REPORTES = '/assets/img/ic_reportes.svg',
    SALIR = '/assets/img/ic_salir.svg',
    USUARIOS = '/assets/img/ic_usuarios.svg',
    WHATSAPP = '/assets/img/ic_whatsapp.svg',
    BLACKLIST = '/assets/img/list.svg',
    BARCODE_READER_WHITE = '/assets/img/ic_barcode_reader_white.svg',
    POS = '/assets/img/ic_commerce.svg'
}
