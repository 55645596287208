import { Cliente } from '../cliente.models';
import { Roles } from '../roles.models';

export interface UserInterface {
  username?: string;
  nombre?: string;
  password?: string;
  enabled?: boolean;
  email?: string;
  roles?: Roles[];
  id?: number;
  cliente_create?: Cliente;
  cliente?: Cliente;
  cliente_id?: number;
  roles_id?: number;
}

export interface UserInterfaceShowed
  extends Omit<UserInterface, 'enabled' | 'roles'> {
  enabled: string;
  roles?: string | Roles[];
}

export interface UserMinimalInterface
  extends Omit<
    UserInterface,
    'cliente_create' | 'cliente' | 'cliente_id' | 'roles_id' | 'id'
  > {
  cliente_create_id: number;
}
