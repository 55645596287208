import { ListPageSize } from '../types/SortEmitter.type';

export class MatPaginationSettings {
  private _pageSize: ListPageSize;
  private _totalElements: number;
  private _pageNumber: number;
  constructor(
    pageSize: ListPageSize = 10,
    totalElements: number = 0,
    pageNumber: number = 0
  ) {
    this._pageSize = pageSize;
    this._totalElements = totalElements;
    this._pageNumber = pageNumber;
  }

  public set PageSize(value: ListPageSize) {
    this._pageSize = value ?? 10;
  }
  public get PageSize() {
    return this._pageSize;
  }

  public set PageNumber(value: number) {
    this._pageNumber = value ?? 0;
  }

  public get PageNumber() {
    return this._pageNumber;
  }

  public set TotalElements(value: number) {
    this._totalElements = value ?? 0;
  }

  public get TotalElements() {
    return this._totalElements;
  }

  public get AvailablePaginationRange() {
    return [2,5, 10, 25, 50, 75, 100];
  }

  public get PaginationDefault() {
    return 10;
  }
}
