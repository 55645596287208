<div class="loader" *ngIf="loading">
  <mat-spinner></mat-spinner>
  <h4>Cargando datos</h4>
</div>

<section class="main-container" *ngIf="!loading && liquidacion">
  <article class="main-data">
    <div class="extra-data">
      <p class="gris">Cliente:</p>
      <h2>
        {{ liquidacion.cliente.nombre_legal }}
      </h2>
    </div>
    <div class="box-container">
      <div class="coin-container">
        <h2>
          <span>ID</span>
          {{ liquidacion.id }}
          <span>{{ liquidacion.estado.estado }}</span>
        </h2>
      </div>
    </div>
    <div class="button-section">
      <button
        class="button-status"
        mat-button
        (click)="
          handleLiqStatus(liquidacion.estado.estado).action(
            liquidacion.id,
            handleLiqStatus(liquidacion.estado.estado).id
          )
        "
      >
        {{ handleLiqStatus(liquidacion.estado.estado).title }}
      </button>
      <div class="button-panel">
        <button
          class="pdf"
          title="Descargar PDF con los detalles de la liquidacion"
          (click)="makePDF(liquidacion)"
        >
          PDF</button
        ><button
          class="excel"
          (click)="makeExcel(liquidacion)"
          title="Descargar excel con los detalles de la liquidacion"
        >
          Excel
        </button>
        <button
          title="Editar importes de la liquidacion"
          class="edition"
          *ngIf="!isFormVisible && liquidacion.estado.estado !== 'APROBADA'"
          (click)="openForm()"
        >
          Editar
        </button>
        <button
          title="La liquidacion no puede ser editada ya que ha sido aprobada"
          class="edition"
          *ngIf="!isFormVisible && liquidacion.estado.estado === 'APROBADA'"
        >
          N/A
        </button>
        <button class="edition" *ngIf="isFormVisible" (click)="openForm()">
          Cancelar
        </button>
      </div>
    </div>
  </article>
  <article class="main-data">
    <div class="white-bkg">
      <h2 class="header colored">Detalles de la liquidación</h2>
      <p class="liquidacion-detalle">
        <span>Importe Neto</span>
        <strong class="bigger">{{
          liquidacion.importe_liquidado / 100
            | currency : "CAD" : "" : "2.2-2" : "es-ARS"
        }}</strong>
        <span>fecha</span
        ><strong
          >{{ liquidacion.created_at | date : "dd:MM:yy - HH:ss" }}hs</strong
        >
        <span>Comision</span>
        <strong>{{
          liquidacion.importe_comision / 100
            | currency : "CAD" : "" : "2.2-2" : "es-ARS"
        }}</strong>
        <span>Gastos</span>
        <strong>{{
          liquidacion.importe_gasto_comision / 100
            | currency : "CAD" : "" : "2.2-2" : "es-ARS"
        }}</strong>
        <span>Contracargo</span>
        <strong>{{
          liquidacion.importe_contracargo / 100
            | currency : "CAD" : "" : "2.2-2" : "es-ARS"
        }}</strong>
      </p>
      <br />
      <h2 class="header blank">Datos asociados</h2>
      <p *ngIf="liquidacion && detalles" class="liquidacion-detalle">
        <span>Numero</span><strong>{{ detalles.id_sp }}</strong>
        <span>Descripcion</span><strong>{{ detalles.descripcion }}</strong>
        <span>Referencia externa</span
        ><strong>{{ detalles.referencia_externa }}</strong>
        <span>Medio de pago</span>
        <strong>{{ detalles.medio_pago }}</strong>
        <span>ID Rendicion asociada</span>
        <strong>{{ detalles.id_rendicion }}</strong>
        <span>Fecha vencimiento</span>
        <strong
          >{{ detalles.fecha_vencimiento | date : "dd:MM:yy - HH:ss" }}hs</strong
        >
        <span>2da Fecha vencimiento</span>
        <strong
          >{{
            detalles.segunda_fecha_vencimiento | date : "dd:MM:yy - HH:ss"
          }}hs</strong
        >
      </p>
    </div>
  </article>
  <aside @onOff *ngIf="isFormVisible" class="main-data header-formulario">
    <div class="white-bkg form-container">
      <form [formGroup]="editionForm">
        <label for="">
          <span>Importe Liquidacion</span>
          <input
            type="number"
            name="importe_total"
            id="importe_total"
            formControlName="importe_total"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe Liquidado</span>
          <input
            type="number"
            name="importe_liquidado"
            id="importe_liquidado"
            formControlName="importe_liquidado"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe Comision</span>
          <input
            type="number"
            name="importe_comision"
            id="importe_comision"
            formControlName="importe_comision"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe Gasto Comision</span>
          <input
            type="number"
            name="importe_gasto_comision"
            id="importe_gasto_comision"
            formControlName="importe_gasto_comision"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe Contracargo</span>
          <input
            type="number"
            name="importe_contracargo"
            id="importe_contracargo"
            formControlName="importe_contracargo"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe SIRTAC</span>
          <input
            type="number"
            name="importe_sirtac"
            id="importe_sirtac"
            formControlName="importe_sirtac"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe IIBB Misiones</span>
          <input
            type="number"
            name="importe_iibb_misiones"
            id="importe_iibb_misiones"
            formControlName="importe_iibb_misiones"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe IVA</span>
          <input
            type="number"
            name="importe_iva"
            id="importe_iva"
            formControlName="importe_iva"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe retenciones de IVA</span>
          <input
            type="number"
            name="importe_ret_iva"
            id="importe_ret_iva"
            formControlName="importe_ret_iva"
            step="0.01"
          />
        </label>
        <label for="">
          <span>Importe retenciones de ganancias</span>
          <input
            type="number"
            name="importe_ret_ganancia"
            id="importe_ret_ganancia"
            formControlName="importe_ret_ganancia"
            step="0.01"
          />
        </label>
        <button
          class="button-status"
          (click)="submitImportesForm(editionForm.value)"
        >
          Enviar
        </button>
      </form>
    </div>
  </aside>
</section>
