<div class="card animated fadeIn">
    <div class="card-header text-center">
        <h4>{{ Title }}</h4>
    </div>
    <div class="card-body "> 
        <div class="text-center">
            <p>{{ text }}</p>
            <h1>{{ h1Text }}</h1>
            <h2>{{ h2Text }}</h2>
            <h3>{{ h3Text }}</h3>
            <h3>{{ h4Text }}</h3>
            <h3>{{ h5Text }}</h3>
            <h3>{{ h6Text }}</h3>
        </div>
        <div class="text-center mt-2" *ngIf="twoColums">
            <div class="row">
                <div class="col-6">
                    <a href="{{ linkColumnOne }}">{{ columnOne | uppercase }}</a>
                </div>
                <div class="col-6">
                    <a  href="{{ linkColumnTwo }}">{{ columnTwo | uppercase }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer" *ngIf="showButtons">
        <button class="btn buttons btn-red" data-bs-dismiss="modal" aria-label="Close">Cancelar</button>
        <button class="btn buttons btn-green" data-bs-dismiss="modal" aria-label="Close" (click)="accionAceptar()">Aceptar</button>
    </div>
    <div class="card-footer" *ngIf="btnRendicion">
        <button class="btn-accion" (click)="accionAceptar()">Generar Rendición</button> 
    </div>
</div>