<section [formGroup]="clientesExpandedFormGroup">
  <article class="personal-data" *ngIf="clientPersonalData">
    <h3>Datos personales del cliente</h3>
    <div class="content">
      <mat-form-field>
        <input
          matInput
          placeholder="Razon social"
          formControlName="razon_social"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Nombre legal del cliente"
          required
          formControlName="nombre_legal"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder=" Email | Correo elecrtonico"
          required
          formControlName="email"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Direccion | Domicilio"
          required
          formControlName="domicilio"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder=" Plataforma o sitio web"
          required
          formControlName="web"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Nro. de documento | CUIT/CUIL/DNI"
          formControlName="documento"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <!-- TODO: Falta algun campo:? -->
        <input
          matInput
          placeholder="Localidad"
          required
          formControlName="localidad"
        />
      </mat-form-field>
    </div>
  </article>
  <aside>
    <h3>Regulaciones / Datos financieros</h3>
    <div class="content">
      <div class="check-container">
        <mat-checkbox color="accent" formControlName="agente_ret_iva">
          Soy un agente de retencion de IVA
        </mat-checkbox>
       
        <!-- Input numerico -->
        <mat-form-field appearance="fill">
          <mat-label>Semanas de liquidacion desde el inicio de mes</mat-label>
          <input
            matInput
            type="number"
            formControlName="semana_liquidacion"
            placeholder="Ej: 1,2,3"
            aria-label="Semanas pasadas luego del inicio de mes requeridas para la liquidacion"
          />
          <label for="" matSuffix>Semanas</label>
          <mat-hint>Semana/s de liquidacion</mat-hint>
        </mat-form-field>
         <!-- Input numerico -->
         <mat-form-field appearance="fill">
          <mat-label>Día de liquidación del mes</mat-label>
          <input
            matInput
            type="number"
            formControlName="dia_liquidacion"
            placeholder="Ej: 1,2,3"
            aria-label="Día del mes requerida para la liquidacion"
          />
          <label for="" matSuffix>Día</label>
          <mat-hint>Semana/s de liquidacion</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Costo por uso de plataforma + IVA</mat-label>
          <input
            matInput
            type="text"
            placeholder="Costo de plataforma + IVA"
            formControlName="cost_platform_use"
            aria-label="Costo por uso de plataforma + IVA"
          />
          <mat-hint>Costo por uso de plataforma + IVA</mat-hint>
        </mat-form-field>


        <mat-label>QR</mat-label>
        <mat-radio-group aria-label="Select an option" formControlName="qr_bind_enable">
          <mat-radio-button value="true">QR BIND</mat-radio-button>
          <mat-radio-button value="false">QR Naranja</mat-radio-button>
        </mat-radio-group>
        <mat-checkbox color="accent" formControlName="checkoutInternacional">
          Terminal ECR (Habilitacion de POS)
        </mat-checkbox>
        <mat-checkbox color="accent" formControlName="checkoutInternacional">
          Internacional
        </mat-checkbox>
      </div>
      <div class="check-container">
        <mat-checkbox color="accent" formControlName="ret_iva">
          Ret. IVA Habilitada
        </mat-checkbox>
        <mat-form-field appearance="fill" *ngIf="ret_iva_disabled">
          <mat-label>Debito</mat-label>
          <!-- TODO: ngIf para los inputs, solo deben ser visibles en caso de que el check sea true -->
          <input
            matInput
            type="text"
            placeholder="Alicuota"
            formControlName="alicuota_ret_iva"
            aria-label="Alicuota que sera usada para las retenciones del IVA al debito"
          />
          <mat-hint>Alicuota al debito</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="ret_iva_disabled">
          <mat-label>Credito</mat-label>
          <input
            matInput
            type="text"
            placeholder="Alicuota"
            formControlName="alicuota_ret_iva_credito"
            aria-label="Alicuota que sera usada para las retenciones del IVA al credito"
          />
          <mat-hint>Alicuota al credito</mat-hint>
        </mat-form-field>
      </div>
      <div class="check-container">
        <mat-checkbox color="accent" formControlName="ret_ganancia">
          Ret. Ganancias habilitada
        </mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>Debito</mat-label>
          <input
            matInput
            placeholder="Alicuota"
            type="text"
            formControlName="alicuota_ret_ganancia"
            aria-label="Alicuota que sera usada para las retenciones de anancias al debito"
          />
          <mat-hint>Alicuota al debito</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Credito</mat-label>
          <input
            matInput
            type="text"
            placeholder="Alicuota"
            formControlName="alicuota_ret_ganancia_credito"
            aria-label="Alicuota que sera usada para las retenciones de anancias al credito"
          />
          <mat-hint>Alicuota al credito</mat-hint>
        </mat-form-field>
      </div>
      <div class="check-container">
        <mat-checkbox color="accent" formControlName="iibb_sirtac">
          IIBB SIRTAC
        </mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>Valor numerico</mat-label>
          <input
            matInput
            type="text"
            placeholder="Alicuota"
            formControlName="alicuota_sirtac"
            aria-label="Alicuota de cobro para SIRTAC"
          />
          <mat-hint>Alicuota SIRTAC</mat-hint>
        </mat-form-field>
      </div>
      <div class="check-container">
        <mat-checkbox color="accent" formControlName="iibb_misiones">
          IIBB Misiones
        </mat-checkbox>
      </div>
    </div>
  </aside>
</section>

<div class="panel">
  <button mat-button class="final-btn" color="accent" matStepperPrevious>
    Volver
  </button>

  <button mat-button class="final-btn alt" color="accent" (click)="successConfirmAction()">
    Cargar Datos
  </button>
</div>
