import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var jQuery: any;
@Component({
  selector: 'app-tranferencia-consulta',
  templateUrl: './tranferencia-consulta.component.html',
  styleUrls: ['./tranferencia-consulta.component.css']
})
export class TranferenciaConsultaComponent implements OnInit {

  dateIsSelected:boolean;
  consultaForm: UntypedFormGroup;
  submitted:boolean;
  tablaTransfer:boolean=false;
  
  constructor(private formBuilder: UntypedFormBuilder, 
    private datePipe: DatePipe,private route: Router) { }


  ngOnInit(): void {

    this.makeForm();
  }

  onSubmit(formData){
    this.tablaTransfer=true;
    this.submitted = true;
    //Formulario inválido 
      if(this.consultaForm.invalid){
        return;
      } 
    //guarda en liquidacion service los filtros de búsqueda
    //this.liquidacionService.setBusquedaLiquidacion(formData);
      // console.log(formData);
    // this.formularioConsulta = false;
    //this.route.navigate(['dashboard/liquidacion/transferencia-resultado'])
  }

  makeForm(){
    //Form Builder
    this.consultaForm = this.formBuilder.group({
        num_liquidacion: [''],
        desdeCreacion: new UntypedFormControl(''),
        hastaCreacion: new UntypedFormControl(''),
    })
    //DatePicker max && min
    var today = Date.now();
    var hoy = this.datePipe.transform(today,"yyyy-MM-dd");
    var datePickers = ['to', 'from']; 

    jQuery.each(datePickers, function (ind, elem) { 
      jQuery("#"+elem).attr({
        "max" : hoy
      })
    });    

  }

//controls
get form(){
  return this.consultaForm.controls;
}
get desdeCreacion() {
  return this.consultaForm.get('desdeCreacion') as UntypedFormControl;
}
get hastaCreacion() {
  return this.consultaForm.get('hastaCreacion') as UntypedFormControl;
}

//Rango de fechas (disponibilidad de fechas) //Ej. si selecciono 'hasta' el día 5, no puedo seleccionar, 'desde' el día 10.
datepicker(){
  //Toma los datos del formulario
    var dateFrom = this.consultaForm.get('desdeCreacion').value;
    var dateTo = this.consultaForm.get('hastaCreacion').value;

    //Si no está vacío
    if(dateFrom != ""){
      jQuery("#to").attr({   
            "min" : dateFrom
          });
    }

    if(dateTo != ""){
          jQuery("#from").attr({   
          "max" : dateTo,
        });
    }      
  }



   //Rango de fechas (validación)
   dateChange(e){
    this.dateIsSelected = true;
    
    if(e.target.value == ""){

        //Remueve la validación
        this.desdeCreacion.clearValidators();
        this.hastaCreacion.clearValidators();
        //Actualiza
        this.desdeCreacion.updateValueAndValidity();
        this.hastaCreacion.updateValueAndValidity();
    }else{
      //Añade validación
      this.desdeCreacion.setValidators([Validators.required]);
      this.hastaCreacion.setValidators([Validators.required]);
      //Actualiza
      this.desdeCreacion.updateValueAndValidity();
      this.hastaCreacion.updateValueAndValidity();
    }  
  }


  EnterSubmit(event, form) {
    if (event.keyCode === 13)  this.onSubmit(form) 
 }


 limpiar(){
  this.submitted = false;
  this.consultaForm.reset();
  this.dateIsSelected = false;
  //Remueve la validación
  // this.desdeCreacion.clearValidators();
  // this.hastaCreacion.clearValidators();
  //Actualiza
  // this.desdeCreacion.updateValueAndValidity();
  // this.hastaCreacion.updateValueAndValidity();
  this.makeForm();
}


}
