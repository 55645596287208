import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() Title:string;
  @Input() text:string;
  @Input() h1Text:string;
  @Input() h2Text:string;
  @Input() h3Text:string;
  @Input() h4Text:string;
  @Input() h5Text:string;
  @Input() h6Text:string;
  //
  @Input() twoColums:boolean;  
  @Input() columnOne:any;  
  @Input() columnTwo:any;
  public linkColumnOne:string;  
  public linkColumnTwo:string;  
  //

  @Input() showButtons:boolean = false;
  @Input() btnRendicion:boolean = false;
  @Output() aceptar: EventEmitter<boolean>
  
  ///
  
  constructor( private router: Router) { 
    this.aceptar = new EventEmitter()
  }

  ngOnInit(): void {
    if(!this.Title){
        this.Title = "Seccion";
    }
    //Rutas de los links de las columnas
    var ruta:string = this.router.url;
    this.linkColumnOne = "#"+ ruta + "/" + this.columnOne;
    this.linkColumnTwo = "#"+ ruta + "/" + this.columnTwo;
  }

  accionAceptar(){
    this.aceptar.emit(true);
  }


}
