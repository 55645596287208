<div class="form-container">
  <button mat-raised-button color="primary" (click)="openDialogAddTerminalToUser()">
    Asociar terminal
  </button>
  <button mat-raised-button color="primary" (click)="openDialogAddNewTerminal()">
    Alta terminal
  </button>
</div>
<section class="list-panel">
  <!-- Swith case to match if we need to show terminal with users or only terminals -->
  <div>
    <app-tabla-resultados
      [data]="dataSource"
      [configTable]="configTable"
      (buttonPanelEmitter)="handleButtonPanelEmitter($event)"
      [totalElements]="totalElements"
      [pageNumber]="pageNumberMov"
      (eventGetPage)="getNewPageRequest($event)"
      [buttonsPanel]="TERMINAL_CONFIG_TABLE.buttonsPanel"
    ></app-tabla-resultados>
  </div>
</section>
