import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeARS from '@angular/common/locales/es-AR';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ApiService } from 'src/app/services/api.service';
import { FunctionsService } from 'src/app/services/functions.service';
// declare var SimpleMaskMoney :any;
import SimpleMaskMoney from 'simple-mask-money'; // import mask
import { ClientesService } from '../../../clientes/services/clientes.service';
@Component({
  selector: 'app-nva-liquidacion',
  templateUrl: './nva-liquidacion.component.html',
  styleUrls: ['./nva-liquidacion.component.css']
})
export class NvaLiquidacionComponent implements OnInit {

  crearLiquidacion: UntypedFormGroup;
  submitted:boolean = false;
  listaClientes;
  listaRendiciones;
  //rendicion
  rendicion:any;

  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent

  constructor(    
    private _router:Router,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private currencyPipe: CurrencyPipe,
    private _Clientes: ClientesService,
    private _functionsService: FunctionsService) { }

  ngOnInit(): void {
    registerLocaleData(localeARS, 'es-ARS');
    //Arma el formulario
    this.makeForm();
    //================prueba
      // Default configuration  
    const args = {
      allowNegative: false,
      negativeSignAfter: false,
      prefix: '',
      suffix: '',
      fixed: true,
      fractionDigits: 2,
      decimalSeparator: ',',
      thousandsSeparator: '.',
      cursor: 'move'
    };

    // set mask on your input you can pass a querySelector or your input element and options
    SimpleMaskMoney.setMask('#importe_total', args);
    SimpleMaskMoney.setMask('#importe_liquidado', args);
    SimpleMaskMoney.setMask('#importe_comision', args);
    //================prueba
  }
  makeForm(){
    //Form Builder
    this.crearLiquidacion = this.formBuilder.group({
        cliente: ['', Validators.required],
        num_rendicion: ['', Validators.required],
        importe_total: ['', Validators.required],
        importe_liquidado: ['', Validators.required],
        importe_comision: ['', Validators.required]
      
    })
    //lista de clientes
    this._Clientes.getClientesPromise()
    .then((response) =>{
      this.listaClientes = response;    
      // this._functionsService.imprimirMensaje(this.listaClientes, "Clientes: ");
    })
    .catch((error: any) =>{
      this._functionsService.imprimirMensaje(error,"error al cargar la lista de clientes");
     
    });
     //Deshabilita los demás campos
   this.deshabilitaNumRendicion();
   this.deshabilitaImportes();
  }

  getRendiciones(num_cliente:number){ 
      //Api Service: obtiene rendiciones por cliente
      this.apiService.getRendicionesByIdCliente(num_cliente)
        .then( (response:any) => {
          //Si la respuesta no está vacía
          if (response[0] != undefined || response[0] != null) {
            this.habilitaNumRendicion();
          }else {
            this.listaRendiciones = null;
            this.deshabilitaNumRendicion();
            return;
          }
          //guarda el listado de rendiciones
          this.listaRendiciones = response;
          //console.log(this.listaRendiciones);
        })
        .catch( (error) =>{
          console.log("error al traer rendiciones por cliente", error);
        })
  }

  nuevaLiquidacion(formData){
      var data = null; 
      
      //busca el cliente
      var cliente = null;
      for (let i = 0; i < this.listaClientes.length; i++) {    
        if(this.listaClientes[i].id == formData.cliente){
          cliente = this.listaClientes[i];
        }
      }

      //limpia los importes
        var importe_total = formData.importe_total.replaceAll(",", '').replaceAll(".", '');
        var importe_liquidado = formData.importe_liquidado.replaceAll(",", '').replaceAll(".", '');
        var importe_comision = formData.importe_comision.replaceAll(",", '').replaceAll(".", '');
      //  console.log(cliente);
       data = {
         "cliente": cliente,
         "distribuida": false,
         "importe_total": importe_total,
         "importe_liquidado": importe_liquidado,
         "importe_comision": importe_comision,
         "rendicion": this.rendicion
       }
      this.apiService.crearLiquidacion(data) 
      .then( (response:any) => {
        if(response == undefined || response == null){
          //muestra mensaje de creación de liquidación
          this._functionsService.configSwal(this.mensajeSwal, `¡Liquidación creada con éxito!`, "success", "Aceptar", "", false, "", "");
          this.mensajeSwal.fire().finally(()=> {
            //Redirecciona a la vista liquidación
            this._router.navigate(['dashboard/liquidacion']);
        });
        }
      })
      .catch( (error) =>{
        console.log("error al crear liquidacion", error);
      })
        
  }
 
   //==== Funciones de los formularios ====
   onSubmit(formData){
    this.submitted = true;
    //Formulario inválido 
      if(this.crearLiquidacion.invalid){
        return;
      } 
      this.nuevaLiquidacion(formData);
 
  } 
  
  limpiar(){
    this.crearLiquidacion.reset();
    this.submitted = false;
    //Deshabilita todos los campos
    this.deshabilitaNumRendicion();
    this.deshabilitaImportes();
  }

  //Controls
  get form(){
    return this.crearLiquidacion.controls;
  }

  //Disabled
  deshabilitaNumRendicion(){
    this.crearLiquidacion.controls['num_rendicion'].disable(); 
  }
  deshabilitaImportes(){
    this.crearLiquidacion.controls['importe_total'].disable();
    this.crearLiquidacion.controls['importe_liquidado'].disable();
    this.crearLiquidacion.controls['importe_comision'].disable();    
  }
  //Enabled
  habilitaNumRendicion(){
    this.crearLiquidacion.controls['num_rendicion'].enable();   
  }
  habilitaImportes(){
    this.crearLiquidacion.controls['importe_total'].enable();
    this.crearLiquidacion.controls['importe_liquidado'].enable();
    this.crearLiquidacion.controls['importe_comision'].enable();    
 }

 formatToNumber( e){ 
    if (e.key !== 'Enter') return;
  }

  setImportes(num_rendicion:number){
        //busca la rendición seleccionada
       for (let i = 0; i < this.listaRendiciones.length; i++) {    
          if(this.listaRendiciones[i].id == num_rendicion){
            this.rendicion = this.listaRendiciones[i];
          }
       }
    //Habilita los importes
    this.habilitaImportes();
    //Importe total
    this.crearLiquidacion.controls['importe_total'].setValue(this.currencyPipe.transform(this.rendicion.importe/100, 'CAD', '', '2.2-2', 'es-ARS'));
     //Importe liquidado
    this.crearLiquidacion.controls['importe_liquidado'].setValue(this.currencyPipe.transform(this.rendicion.importe_a_acreditar/100, 'CAD', '', '2.2-2', 'es-ARS'));
     //Importe comisión
    this.crearLiquidacion.controls['importe_comision'].setValue(this.currencyPipe.transform((this.rendicion.importe - this.rendicion.importe_a_acreditar)/100, 'CAD', '', '2.2-2', 'es-ARS'));

  }
  

}
