// @ts-nocheck
// FIXME: I have errors :c
import {
  Component,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TokenService } from '../../../../../services/token.service';
import { LOGOS } from 'src/app/constants/Logos';
import { ICON } from 'src/app/constants/Icons';

declare var jQuery: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  // LOGOS
  public LogoHelipagos = LOGOS.HELIPAGOS_LARGE;
  public toggleIcon: ICON = ICON.MENU_BURGER;

  public header: string;
  public avisos: number;

  // Component communication
  @Output() menuClickEmitter = new EventEmitter<void>();

  constructor(private router: Router, private _tokenService: TokenService) {
    //Si cambia de ruta el menú desplegable se cierra
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
      }
    });
  }

  ngOnInit(): void {
    this.name = this._tokenService.getUserName();
    this.header = `Bienvenido, ${this.name}`;
  }

  logOut() {
    this._tokenService.logOut();
  }

  showToggleHandler() {
    this.menuClickEmitter.emit();
  }
}
