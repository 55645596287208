import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientesRoutingModule } from './clientes-routing.module';
import { ClientesComponent } from './clientes.component';

import { NuevoClienteComponent } from './nuevo-cliente/nuevo-cliente.component';
import { ListadoClienteComponent } from './listado-cliente/listado-cliente.component';
import { MaterialModule } from 'src/app/material/material.module';
import { DatosPersonalesComponent } from './components/form-datos-personales/datos-personales.component';
import { DetalleClienteComponent } from './detalle-cliente/detalle-cliente.component';
import { ClienteCardComponent } from './components/cliente-card/cliente-card.component';
import { FormUsuarioComponent } from './components/form-usuario/form-usuario.component';
import { FormRegulacionesComponent } from './components/form-regulaciones/form-regulaciones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClienteFormDataReviewComponent } from './components/cliente-form-data-review/cliente-form-data-review.component';
import { ComisionesClienteComponent } from './comisiones-cliente/comisiones-cliente.component';
import { ComisionCardComponent } from './components/comision-card/comision-card.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { CdkStepper } from '@angular/cdk/stepper';

@NgModule({
  declarations: [
    ClientesComponent,
    NuevoClienteComponent,
    ListadoClienteComponent,
    DatosPersonalesComponent,
    DetalleClienteComponent,
    ClienteCardComponent,
    DatosPersonalesComponent,
    FormUsuarioComponent,
    FormRegulacionesComponent,
    ClienteFormDataReviewComponent,
    ComisionesClienteComponent,
    ComisionCardComponent,
  ],
  imports: [
    CommonModule,
    ClientesRoutingModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [CdkStepper],
})
export class ClientesModule { }
