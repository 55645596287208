import { Component, OnInit, ViewChild } from '@angular/core';
import {UserInterface, UserInterfaceShowed} from 'src/app/models/interfaces/user.interface';
import { ApiService } from 'src/app/services/api.service';
import { of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TokenService } from 'src/app/services/token.service';
import { FunctionsService } from 'src/app/services/functions.service';
import {ActionButton, ProductionConfigTableInterface} from '../../components/shared/tabla-resultados/production-config-table.interface';
import {ICON} from '../../constants/Icons';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  @ViewChild('mensajeSwal') mensajeSwal: SwalComponent;

  // Tabla Usuarios
  displayedColumns = ['Nombre', 'Username', 'Email', 'Roles', 'Estado'];
  displayedColumnsSearch = ['nombre', 'username', 'email', 'roles', 'enabled'];
  dataSource: UserInterfaceShowed[];
  listaUser: any[];
  // Texto Delete Model
  DeleteTitle = 'Eliminar';
  DeleteText = '¿Desea eliminar el usuario?';

  // Filtro
  filtroText: string;
  searching: boolean;

  // Editar usuario
  userEdit;
  mensajeResultado;

  loading: boolean;
  editar: boolean;
  crear: boolean;

  public totalElements: number; // cantidad de elementos totales de la tabla
  public configTable: Partial<ProductionConfigTableInterface>;
  public pageNumberMov: any;

  constructor(
    private _apiService: ApiService,
    private _tokenService: TokenService,
    private _functionService: FunctionsService) { }

  ngOnInit(): void {
    const headers = {
      columns_show:  ['Cliente', 'Nombre', 'Username', 'Email', 'Rol', 'Estado'],
      columns_search:  ['cliente', 'nombre', 'username', 'email', 'roles', 'enabled'],
    };
    this.configTable = {
      headers,
      exportToExcel: false,
      verDetallesTarjeta: false,
      buscar: true,
      verDetalles: false,
      functionUsuarios: true
    };
    this.loading = true;
    this.pageNumberMov = 1;
    this.cargarTabla(this.pageNumberMov, 10);

  }

  cargarTabla(page: number, pageSize: number): void{
    this._apiService.getUsersList(page - 1, pageSize).subscribe((response : any) => {
      this.listaUser = response.content;
      this.totalElements = response.totalElements;
     // this.pageNumberMov = response.totalPages-1;
      this.dataSource = this.listaUser.map((user: UserInterface) => {
        const { id, email, enabled, nombre, roles, username, cliente, cliente_id, roles_id } = user;

        return {
          id,
          email,
          enabled: enabled ? 'Habilitado' : 'Deshabilitado',
          nombre,
          roles: roles != null ? user.roles : 'no disponible',
          username,
          cliente,
          cliente_id,
          roles_id
        };

      });
      this.mensajeResultado = 'Se encontraron ' + this.listaUser.length + ' resultados.';
      this.loading = false;
    },


    (error) => {
      this._functionService.imprimirMensaje(error, '-- Error en usuarios ');
      if (error.status === 403){
        this._functionService.configSwal(this.mensajeSwal, 'Sesión expirada', "info", "Login", "", false, "#53BAAB", "")
        this.mensajeSwal.fire().finally(() => {
          this._tokenService.logOut();
        });
      }else{
        this._functionService.configSwal(this.mensajeSwal, "Error de conexión", "warning", "Reintentar", "Cancelar", true, "#53BAAB", "");
        this.mensajeSwal.fire().then((resolve)=>{
          if(resolve.isConfirmed){
            this.ngOnInit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
      }
    });
  }

  nuevaSolicitud(page:any){
    console.log(page);
    this.cargarTabla(page.pageNumber, page.pageSize);
  }
  modalEditUsuario(idx:number){
    this.editar = true;
    this.obtenerUsuarioDeListado(idx);
  }

  nuevoUsuario(){
    this.crear = true;
  }
  handleButtonPanelEmitter(data: any): void{
    console.log(data);
  }


  modalDeleteUsuario(idx:number){
    document.getElementById("btn-delete-user").click();
    this.obtenerUsuarioDeListado(idx);
  }


  obtenerUsuarioDeListado(idx:number){
    const listUsers = of(...this.dataSource);
    const filtroUser = filter((user: UserInterfaceShowed) => user.id === idx);
    const filtrado = filtroUser(listUsers);
    filtrado.subscribe((item) =>{
      this.userEdit = item;

    });

  }


  filtrar(termino:string){
      if (termino.length >= 3) {
        this.searching = true;
      } else {
        this.searching = false;
      }

    this.filtroText = termino;
  }


  crearUsuario(user: UserInterface){

    this._apiService.createUser(user)
      .then(() =>{
        this._functionService.configSwal(this.mensajeSwal, `El usuario ${user.username} fue creado correctamente.`, "success", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire().finally(()=> {
          this.ngOnInit();
          this.mostrarLista();
        });
      })
      .catch(()=>{
        this._functionService.configSwal(this.mensajeSwal, `Error al intentar crear el usuario ${user.username}`, "error", "Aceptar", "", false, "", "");
        this.mensajeSwal.fire();
      });

  }

  modificarUsuario(user){

    this._apiService.editUser(this.userEdit.id, user)
    .then(() =>{
      this._functionService.configSwal(this.mensajeSwal, `El usuario ${user.username || user.nombre} fue modificado correctamente.`, "success", "Aceptar", "", false, "", "");
      this.mensajeSwal.fire().finally(()=> {
        this.ngOnInit();
        this.mostrarLista();
      });
    })
    .catch(()=>{
      this._functionService.configSwal(this.mensajeSwal, `El usuario ${user.username} no pudo ser modificado`, "error", "Aceptar", "", false, "", "");
      this.mensajeSwal.fire();
      this.mostrarLista();
    });

  }

  deshabilitarUsuario(){
    this._apiService.disableUser(this.userEdit.id)
    .then(() =>{
      this._functionService.configSwal(this.mensajeSwal, `El usuario ${this.userEdit.username} fue dado de baja correctamente.`, "success", "Aceptar", "", false, "", "");
      this.mensajeSwal.fire().finally(()=> {
        this.ngOnInit();
      });
    })
    .catch(()=>{
      this._functionService.configSwal(this.mensajeSwal, `El usuario ${this.userEdit.username} no se pudo deshabilitar.`, "error", "Aceptar", "", false, "", "");
    });

  }

  mostrarLista(){
    this.editar = false;
    this.crear = false;
  }
}
