import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientesComponent } from './clientes.component';
import { NuevoClienteComponent } from './nuevo-cliente/nuevo-cliente.component';
import { DetalleClienteComponent } from './detalle-cliente/detalle-cliente.component';
import { ComisionesClienteComponent } from './comisiones-cliente/comisiones-cliente.component';

const routes: Routes = [
  { path: '', component: ClientesComponent },
  { path: 'nuevo', component: NuevoClienteComponent },
  { path: ':id/detalle', component: DetalleClienteComponent },
  { path: ':id/editar', component: NuevoClienteComponent },
  { path: ':id/comisiones', component: ComisionesClienteComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientesRoutingModule {}
