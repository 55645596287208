import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenerarLiquidacionComponent } from '../features/generar-liquidacion/generar-liquidacion.component';

@Component({
  templateUrl: './liquidacion.component.html',
  styleUrls: ['./liquidacion.component.css'],
  animations: [
    trigger('onOff', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(400),
      ]),
      transition(':leave', [
        animate( 400, style(false ? { opacity: 0, height: 0, } : { opacity: 0, })),
      ])
    ]),
  ],
})
export class LiquidacionComponent implements OnInit {
  public filtros: any = {}; // Please give me a model >:c
  public titleRendiciones: string = 'Liquidaciones';
  public textRendiciones: string = 'Podés consultar o crear liquidaciones.';
  public showFilters: boolean = false;
  twoColums: boolean = true;
  columnOne: string = 'CREAR';
  columnTwo: string = 'NUEVA LIQUIDACIÓN';
  dataFromDialog: any | null = null;
  constructor(private _router: Router,public dialog: MatDialog) {}

  ngOnInit(): void {}

  searchHandler(form: any) {
    this.filtros = form;
  }

  toggleFilters(){
    this.showFilters =!this.showFilters;
  }

  resetFilters() {
    this.filtros = {};
  }

  openDialog(): void {
   
    const dialogRef = this.dialog.open(GenerarLiquidacionComponent, {
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result: { datos: any }): void => {
        console.log(result)
    });
}
}
