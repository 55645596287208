import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogAddTerminalToUserComponent } from '../dialog-add-terminal-to-user/dialog-add-terminal-to-user.component';
import { ApiService } from '../../../../services/api.service';
import { TerminalService } from '../../terminal.service';
import { DefaultStateMatcher } from '../../../../core/matchers/default-state-matcher';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import NewTerminalRequestModel from '../../models/new-terminal-request.model';

@Component({
  selector: 'app-dialog-new-terminal',
  templateUrl: './dialog-new-terminal.component.html',
  styleUrls: ['./dialog-new-terminal.component.scss'],
})
export class DialogNewTerminalComponent implements OnInit {
  matcher: DefaultStateMatcher;
  newTerminalFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogAddTerminalToUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _terminalService: TerminalService
  ) {
    this.matcher = new DefaultStateMatcher();
    this.newTerminalFormGroup = _formBuilder.group({
      sn: ['', [Validators.required, Validators.minLength(10)]],
      prisma_id: ['', [Validators.required, Validators.minLength(7)]],
      marca: ['', Validators.required],
      modelo: ['', Validators.required],
      estado_descripcion: ['OK'],
    });
  }

  get formgroupcontrols(): { [p: string]: AbstractControl<any, any> } {
    return this.newTerminalFormGroup.controls;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClicked(): void {
    if (this.newTerminalFormGroup.valid) {
      const { sn, prisma_id, marca, modelo, estado_descripcion } = this.newTerminalFormGroup.value;
      const newTerminalToCreate = new NewTerminalRequestModel(sn, prisma_id, marca, modelo, estado_descripcion);
      this.dialogRef.close(newTerminalToCreate);
    }
  }
}
