import { Injectable } from '@angular/core';
import {ClientCredentials, IClienteFormDetails, IClientesExpanded, IClientPersonalData} from '../types/clientes.type';
import { Cliente } from '../models/clientes.model';
import { ClientesService } from './clientes.service';
import Swal from 'sweetalert2';
import { ErrorHandler } from './ErrorHandler.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogCredencialesComponent } from '../components/dialog-credenciales/dialog-credenciales.component';
import { Storage } from 'src/app/core/decorators/storage.decorator';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageClientService {

  clientPersonalData = new BehaviorSubject<IClientPersonalData | null>(null);
  regulacionesData = new BehaviorSubject<IClienteFormDetails | null>(null);

  constructor(
    private _Clientes: ClientesService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  /**
   * Manejamos datos de credenciales en sessionStorage por uso en varios componentes
   */
  @Storage('BEARER_CREDENTIAL')
  bearerCredential: string | null = null;
  @Storage('WEBHOOK_CREDENTIAL')
  webHookCredential: string | null = null;

  /**
   * Genera el proceso de nuevo usuario
   * Complete: Alta del usuario mediante el uso del servicio clientes.service.ts
   * TODO: Generacion de las credenciales del cliente
   * TODO: Actualizacion de gastos y comisiones del cliente (opcional)
   */
  makeNewClientProcess(clientData: IClientesExpanded, id?: number) {
    if (id) {
      this.updateExistingClient(clientData, id);
    } else {
      this.createNewClient(clientData);
    }
  }

  updateExistingClient(clientData: IClientesExpanded, id: number) {
    this._Clientes.editCliente(id, clientData).subscribe({
      next(response) {
        Swal.fire(
          'OK',
          'Los datos fueron cargados con éxito',
          'success'
        );
        this.router.navigate([`dashboard/clientes/`]);
      },
      error(err) {
        Swal.fire(
          'Oops...',
          'Al parecer ocurrió un error guardando los datos, por favor intentelo nuevamente más tarde',
          'error'
        );
      },
    });
  }

  createNewClient(clientData: IClientesExpanded) {
    const newCliente = new Cliente(clientData);
    newCliente.setAlicuotasToDouble();
    Swal.fire('Dando de alta al cliente');
    Swal.showLoading();
    this._Clientes.createCliente(newCliente).subscribe({
      next: (response) => {
        this.setCredenciales(response);
      },
      error: this.errorHandler.bind(this),
    });
  }

  private setCredenciales(cliente: IClientesExpanded) {
    Swal.fire('Generando credenciales');
    Swal.showLoading();
    this._Clientes.setCredenciales(cliente['id:']).subscribe({
      next: (responseCredenciales: ClientCredentials) => {
        Swal.close();
        this.bearerCredential = responseCredenciales.Bearer;
        this.webHookCredential = responseCredenciales.Webhook_key;
        this.openDialogCredenciales('0ms', '0ms');
        this.router.navigate([`dashboard/clientes/${cliente['id:']}/detalle`]);
      },
      error: this.errorHandler.bind(this),
    });
  }

  private openDialogCredenciales(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ) {
    this.dialog.open(DialogCredencialesComponent, {
      width: '600px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  private errorHandler(error) {
    const errHandler = new ErrorHandler('Ocurrio un error', 'ERROR');
    errHandler.launch();
  }
}
