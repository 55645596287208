<form [formGroup]="personalDataFormGroup">
  <!-- Razon social -->
  <mat-form-field appearance="fill">
    <mat-label>Razon social</mat-label>
    <input
      matInput
      placeholder="Razon social"
      aria-label="Razon social del cliente"
      formControlName="razon_social"
    />
    <mat-error
      *ngIf="
        personalDataFormGroup.get('razon_social').invalid &&
        personalDataFormGroup.get('razon_social').touched
      "
    >
      La razon social es requerida.
    </mat-error>
  </mat-form-field>
  <!-- Nombre legal -->
  <mat-form-field appearance="fill">
    <mat-label>Nombre legal</mat-label>
    <input
      matInput
      placeholder="Nombre legal"
      aria-label="nombre legal del cliente"
      formControlName="nombre_legal"
    />
    <mat-error
      *ngIf="
        personalDataFormGroup.get('nombre_legal').invalid &&
        personalDataFormGroup.get('nombre_legal').touched
      "
    >
      El nombre legal es requerido.
    </mat-error>
  </mat-form-field>
  <!-- Localidad -->
  <mat-form-field appearance="fill">
    <mat-label>Localidad</mat-label>
    <input
      matInput
      placeholder="Localidad"
      aria-label="localidad del cliente"
      formControlName="localidad"
    />
    <mat-error
      *ngIf="
        personalDataFormGroup.get('localidad').invalid &&
        personalDataFormGroup.get('localidad').touched
      "
    >
      La localidad es requerida.
    </mat-error>
  </mat-form-field>
  <!-- Correo electronico -->
  <mat-form-field appearance="fill">
    <mat-label>Email | Correo electronico</mat-label>
    <input
      matInput
      placeholder="helipagos@example.com"
      aria-label="Correo electronico del cliente"
      formControlName="email"
    />
    <mat-error
      *ngIf="
        personalDataFormGroup.get('email').invalid &&
        personalDataFormGroup.get('email').touched
      "
    >
      <div *ngIf="personalDataFormGroup.get('email').errors.required">
        Email es requerido.
      </div>
      <div *ngIf="personalDataFormGroup.get('email').errors.email">
        Email inválido.
      </div>
    </mat-error>
  </mat-form-field>
  <!-- Domicilio -->
  <mat-form-field appearance="fill">
    <mat-label>Direccion | Domicilio</mat-label>
    <input
      matInput
      placeholder="Av. los ejemplos 1212"
      aria-label="Direccion o domicilio del cliente"
      formControlName="domicilio"
    />
  </mat-form-field>
  <!-- WEB -->
  <mat-form-field appearance="fill">
    <mat-label>Platafoma web </mat-label>
    <input
      matInput
      placeholder="www.example.com"
      aria-label="Plataforma o sitio web"
      formControlName="web"
    />
  </mat-form-field>
  <!-- Documento -->
  <mat-form-field appearance="fill">
    <mat-label>CUIT/CUIL</mat-label>
    <input
      matInput
      placeholder="XX-XXXXXXXX-X"
      aria-label="CUIT, CUIL, DNI"
      formControlName="documento"
    />
    <mat-error
      *ngIf="
        personalDataFormGroup.get('documento').invalid &&
        personalDataFormGroup.get('documento').touched
      "
    >
      El Nro de documento es requerido.
    </mat-error>
    <mat-error *ngIf="personalDataFormGroup.get('documento')['notCuil']">
      El documento no es valido
    </mat-error>
  </mat-form-field>
</form>
<div class="button-panel">
  <button
    class="mat-btn negative"
    (click)="backToUsuariosHome()"
    mat-button
    matStepperPrevious
  >
    Volver
  </button>
  <button
    (click)="submitFormGroup(personalDataFormGroup)"
    class="mat-btn"
    mat-button
    matStepperNext
  >
    Siguiente
  </button>
</div>
