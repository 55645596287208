import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonsService {
  constructor(private clipboard: Clipboard, private _snackBar: MatSnackBar) {}

  /**
   * Obtiene un objeto con X propiedades, y generara un query string con las propiedades validas
   * @param objeto
   * @returns
   * @example
   * const objeto = {nombre: '', apellido:'Gomez', id:37, nombre: 'Elias'};
   * console.log(CommonsService.parseQueryString(objeto)) // '?nombre=Elias&apellido=Gomez&id=37'
   * const objetoDos = {nombre: '', apellido:'', id:37, nombre: 'Elias'};
   * console.log(CommonsService.parseQueryString(objetoDos)) // '?nombre=Elias&id=37'
   * const objetoTres = {};
   * console.log(CommonsService.parseQueryString(objetoTres)) // '?'
   */
  parseQueryString(objeto: unknown) {
    return Object.fromEntries(
      Object.entries(objeto).filter(
        ([key, value]) => value !== '' && value !== null && value !== undefined
      )
    );
  }

  /**Copia los items necesarios */
  clipboardItem(value: any) {
    this.clipboard.copy(value);
    this._snackBar.open('Copiado!', '', {
      duration: 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'helipagos-snackbar', // Clase CSS personalizada
    });
  }

  showSnackbar(value: string) {
    this._snackBar.open(value, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'helipagos-snackbar', // Clase CSS personalizada
    });
  }

  isValidCUITCUIL(cuit: string): boolean {
    if (cuit.length != 13) return false;

    let rv = false;
    let resultado = 0;
    let cuit_nro = cuit.replace('-', '');
    const codes = '6789456789';
    let verificador = Number(cuit_nro[cuit_nro.length - 1]);
    let x = 0;

    while (x < 10) {
      let digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      let digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      let digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  /**
   * Devuelve el flag de version, sin necesidad de invocar al servicio
   * @returns 
   */
  static getProjectVersion() {
    return environment.version ?? 'v0.0.0';
  }
}
