<div class="content animated fadeIn mt-0">
    <!-- <h4>Transacciones</h4> -->
    <!-- <app-buscador></app-buscador> -->
    <div class="row">
        <div>
            <!--Consulta-->
            <div class="card">
                <div class="card-header">
                    <h4>Transferencias</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="consultaForm" (keydown)="EnterSubmit($event, consultaForm.value)">
                        
                            <div class="row2">
                                <!--Número de liquidación-->
                                <div class="col-3">
                                   
                                        <label for="num_liquidacion" class="form-label">N° Liquidación</label>
                                        <input type="text" placeholder="Ingrese un número de liquidación" class="form-" digitOnly formControlName="num_liquidacion" class="input form-control">
                                    
                                </div>
                              <div class="col-2">
                                   
                                        <div class="form-group">
                                            <label class="form-label ">Desde la fecha</label>
                                            
                                                <input (change)="dateChange($event)" (focus)="datepicker()" (blur)="datepicker()" id="from" formControlName="desdeCreacion"  type="date" class="input form-control"  [ngClass]="{ 'is-invalid': submitted && form.desdeCreacion.errors }">
                                                <div *ngIf="submitted && dateIsSelected && form.desdeCreacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.desdeCreacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            
                                        </div>
                                    
                                </div>
                                <div class="col-2">
                                   
                                        <div class="form-group">
                                            <label class="form-label ">Hasta la fecha</label>
                                            
                                                <input (change)="dateChange($event)" (blur)="datepicker()" id="to" formControlName="hastaCreacion" class="input form-control " type="date" [ngClass]="{ 'is-invalid': submitted && form.hastaCreacion.errors }">
                                                <div *ngIf="submitted && dateIsSelected && form.hastaCreacion.errors" class="invalid-feedback">
                                                    <div *ngIf="form.hastaCreacion.errors.required">Este campo es necesario</div>
                                                </div>
                                            
                                        </div>
                                    
                                </div> 
                                
                                <div class="buttons ">
                                    <button type="button " class="btn btn-white" (click)="limpiar()">Limpiar</button>
                                    <button type="button" (click)="onSubmit(consultaForm.value) " class="btn btn-green ml-15 ">Buscar</button>
                                </div>
                            </div>
                        

                        
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>
<app-tranferencia-resultado *ngIf="tablaTransfer"></app-tranferencia-resultado>


