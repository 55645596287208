import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.css']
})
export class BlacklistComponent implements OnInit {

  public loading:boolean;
  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.loading = false;
  }

  altaBlackList(){
    this._router.navigate(['dashboard/blacklist/blacklist-new']);
  }

  mofificarBlackList(){
    this._router.navigate(['dashboard/blacklist/black-list']);
  }

 


}
